import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Seller, Stores } from 'src/app/pages/cadastro-perfil-usuario/perfil-usuario.model';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class LojaService {

    constructor(
        private http: HttpClient,
        private authService : AuthService,
        private storage: StorageService  
    ) { }

    public createStore(lojaForm) { 
             
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.authService.profile,
            seller: lojaForm.seller
        });

        return this.http.post(`${environment.BASE_URL}/api/store`, lojaForm, {
            headers: headers
        });
    }

    public editStore(storeForm, seller, store){

        const headers = new HttpHeaders({
            profile: "FSADMINISTRADOR",
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            seller: seller,
            store: store
        });
        return this.http.put(`${environment.BASE_URL}/api/store`, storeForm, {
            headers: headers
        });
    }

    public deleteByStore(store){
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: "FSADMINISTRADOR",
            seller: store.seller,
            store: store.idStore
        });

        return this.http.request('delete',`${environment.BASE_URL}/api/store`,{
            headers: headers
        });
    }

    public findAllSeller() {

        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile : this.storage.storage.getItem('key')
        });
        return this.http.get<Seller[]>(`${environment.BASE_URL}/api/seller`, {
            headers: headers
        });
    }

    //Função está funcionando normal
    public findAllSellerStores(form: any){
       
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: "FSADMINISTRADOR",
            seller: form.seller,
            store: form.idStore
        });        
        
        return this.http.get(`${environment.BASE_URL}/api/seller/store`, {
            headers: headers
        });
    }

    public getAllStoreConfiguration() {
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            seller: "",
            profile: ""
        });

        return this.http.get(`${environment.BASE_URL}/api/seller`, {
            headers: headers
        });
    }
    //medondo utilizado para find
    public findAllStores(seller: string) {
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile : this.storage.storage.getItem('key'),
            seller : seller
        });
        return this.http.get<Stores[]>(`${environment.BASE_URL}/api/seller/stores`, {
            headers: headers
        });
    }

    public findCep(cep) {
        return this.http.get(`https://viacep.com.br/ws/${cep}/json`);
    }
}