import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PerdidosService } from 'src/app/services/pedidos.service';

@Component({
  selector: 'app-motivo-cancelamento',
  templateUrl: './motivo-cancelamento.component.html',
  styleUrls: ['./motivo-cancelamento.component.scss']
})
export class MotivoCancelamentoComponent implements OnInit {
  motivoCancelamento: FormGroup; 
  numeroOrder: string;
  motiveCancel: string;

  constructor(
    public dialogRef: MatDialogRef<MotivoCancelamentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { numeroOrder : string, motiveCancel : string  },
    private formBuilder: FormBuilder,
    private pedidoService: PerdidosService,
    private router : Router
  ) {}

  ngOnInit(): void {

    if(!this.data.motiveCancel){
      this.motivoCancelamento = this.formBuilder.group({
        descricao: ['']
      });
    } else{
      this.motivoCancelamento = this.formBuilder.group({
        descricao: [this.data.motiveCancel]
      });
    }

    this.numeroOrder = this.data.numeroOrder;
  }

  inserir(){
    let descricao = this.motivoCancelamento.get('descricao').value;
    let status = '8';
        
    this.pedidoService.informDescripition(this.data, status, descricao).subscribe(dados => {
        alert("Descrição de cancelamento registrada com sucesso!");
        this.router.navigate(['']);
      });
      this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(event: Event) {
    event.preventDefault();
    this.dialogRef.close();
  }

}
