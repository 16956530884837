export class Parametrizacao {
  public _id: string;
  public code: string;
  public name: string;
  public alias: string;
  public manualDeliveryInfo: boolean;
  public manualDeliveryManInfo: boolean;



  constructor(code: string, name: string, alias: string, id?: string, manualDeliveryInfo?: boolean, manualDeliveryManInfo?: boolean) {    
    this.code = code;
    this.name = name;
    this.alias = alias;
    if (id) {
      this._id = id;
    }
    if (manualDeliveryInfo) {
      this.manualDeliveryInfo = false;
    }
    if (manualDeliveryManInfo) {
      this.manualDeliveryManInfo = false;
    }
  }
}