import { Component, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SlaInfo } from "src/app/pages/status-sla/SlaInfo.model";
import { SlaService } from "src/app/services/sla.service";
import { ErrorMessageService } from "../error-menssage/error-message.service";

@Component({
    selector: 'app-tipo-entrega-contato',
    templateUrl: './delete-tipo-entrega.component.html',
    styleUrls: ['./delete-tipo-entrega.component.scss']
})
export class DeleteTipoEntregaComponent {

    selectedTipoEntregaInfo: SlaInfo = null;

    constructor(
        @Optional() private dialogRef: MatDialogRef<DeleteTipoEntregaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            tipoEntrega,
            sla,
            id,
        },
        private slaService: SlaService,
        private errorMessageService: ErrorMessageService
    ) { }

    // Fecha formulario
    close() {
        this.dialogRef.close();
    }

    deleteSlaTipoEntrega() {
        this.slaService.deleteSlaConfiguration(
            new SlaInfo(
                this.data.tipoEntrega,
                this.data.sla,
                this.data.id
            )
        ).subscribe(() => {
            this.errorMessageService.openDialog({
                message: 'SUCESSO',
                messageCode: 'Tipo de entrega deletada com sucesso.'
            });
            this.dialogRef.close(true);
        });
    }
}