import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LojaService } from 'src/app/services/loja.service';
import { Store } from 'src/app/pages/grid-troca-cancelamento/troca-cancelamento.model';
import { Router } from '@angular/router';
import { DeleteLojaComponent } from './../../shared/deletar-loja/delete-loja.componet'
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-grid-lojas',
  templateUrl: './grid-lojas.component.html',
  styleUrls: ['./grid-lojas.component.scss']
})
export class GridLojasComponent implements OnInit {

  displayedColumns: string[] = ['seller', 'webStore', 'idStore', 'description', 'cnpj', 'acoes'];
  dataSource: any[] = [];
  sellersArr: any[];
  currentStore: Store[];

  constructor(
    private fb: FormBuilder,
    private lojaService: LojaService,
    private router : Router,
    private dialog: MatDialog
  ) { }

  private subscriptions: Subscription[] = [];

  ngOnInit(): void {

    setTimeout(() => {
      this.getLojas();
    }, 1000
    );
    
  }

  getLojas(){
    this.lojaService.findAllSeller().subscribe(response => {
      this.sellersArr = response;
      
      this.popularTabela();
    });
  }

  popularTabela() {
    const arr = []; // array de apoio
    for (let i = 0; i < this.sellersArr.length; i++) {
      const sellerAtual = this.sellersArr[i];
      
      for (let j = 0; j < sellerAtual.stores?.length; j++) {
        const lojasAtual = sellerAtual.stores;
        if(lojasAtual != null){
          arr.push({
            seller: sellerAtual.seller,
            idStore: sellerAtual.stores[j].idStore,
            store: sellerAtual.stores[j].store,
            webStore: sellerAtual.stores[j].webStore,
            cnpj: sellerAtual.stores[j].cnpj,
            address: sellerAtual.stores[j].address,
            cep: sellerAtual.stores[j].cep,
            city: sellerAtual.stores[j].city,       
            complement: sellerAtual.stores[j].complement,
            latitude: sellerAtual.stores[j].latitude,
            longitude: sellerAtual.stores[j].longitude,
            neighborhood: sellerAtual.stores[j].neighborhood,    
            note: sellerAtual.stores[j].note,
            number: sellerAtual.stores[j].number,
            storePK: sellerAtual.stores[j].storePK,
            branch: sellerAtual.stores[j].branch,
            uf: sellerAtual.stores[j].uf,
            withdrawalInstructions: sellerAtual.stores[j].withdrawalInstructions,
            stateRegistration: sellerAtual.stores[j].stateRegistration,
            taxPayerCustomer: sellerAtual.stores[j].taxPayerCustomer,
            simplesNacional: sellerAtual.stores[j].simplesNacional,
            meiSimei: sellerAtual.stores[j].meiSimei,
            placeType: sellerAtual.stores[j].placeType,
            ccm: sellerAtual.stores[j].ccm,
            rSocial: sellerAtual.stores[j].rSocial
          });
        }
      }
    }
    this.dataSource = arr;    
  }

  public openModalDialogDeleteLoja(idLoja) {
    const dialogRef = this.dialog.open(DeleteLojaComponent, {
      width: '40%',
      data: {
        idLoja,
      },
    });
    const subscription = dialogRef.afterClosed().subscribe((result: any[]) => {
        setTimeout(() => {this.getLojas();}, 1000)
      });
    this.subscriptions.push(subscription);
  }

  popularTabela2() {
    this.sellersArr.forEach((seller) => {
      seller.stores.forEach((store) => {
        this.dataSource.push({
          seller: seller,
          idStore: store.idStore,
          description: store.store,
          cnpj: store.cnpj,
        });
      });
    });    
  }
}
