import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-filter-cancelamento',
  templateUrl: './filter-cancelamento.component.html',
  styleUrls: ['./filter-cancelamento.component.scss']
})
export class FilterCancelamentoComponent implements OnInit {

  formFiltroCancelamento: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<FilterCancelamentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.formFiltroCancelamento = this.formBuilder.group({
      numeroSolicitacao : [''],
      numeroPedido: [''],
      nomeCliente: ['']
    });
  }

  clear(){
    this.formFiltroCancelamento.reset();
  }

  save() {
    this.data.sair = true;
    this.data.filtroCancelamento = {
      numeroSolicitacao: this.formFiltroCancelamento.get('numeroSolicitacao').value,
      numeroPedido: this.formFiltroCancelamento.get('numeroPedido').value,
      nomeCliente: this.formFiltroCancelamento.get('nomeCliente').value      
    }
    this.dialogRef.close(this.data);
  }

  close(event: Event) {
    event.preventDefault();
    this.dialogRef.close();
  }

}
