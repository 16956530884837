<mat-toolbar class="header-style" id="headerStyle">
  <mat-toolbar-row>
    <div class="menu-mob-icon" [class.active]="isMenuOpened" (click)="toggleMenu()" *ngIf="isMobile">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="logoHeader" id="logoHeader">
      <img src="../../../assets/fast_logo.svg" alt="Logo">
      <img src="./../../../assets/svg/SELLERCENTER.svg" alt="SELLERCENTER" style="margin-left: 10px;">
    </div>
    <p id="tittleFastAdmin">Fast admin</p>
    <span class="spacer"></span>
    <button mat-icon-button class="buttonSair removeFocus" id="buttonSair"
      aria-label="Example icon-button with heart icon">
      <p>Sair</p>
    </button>
    <button mat-icon-button class="removeFocus" id="buttonExitToApp">
      <img src="./../../../assets/svg/icon-navigation-close_24px.svg" alt="icon exit">
    </button>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="isMobile">
    <small class="d-block mx-auto"><strong>Fast Shop Admin</strong> / João Almeida Campos</small>
  </mat-toolbar-row>
</mat-toolbar>
