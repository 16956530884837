<form action="" [formGroup]="formFiltroPedidos" class="painel-seller-filter">
  <div class="container-fluid">
    <div class="row header d-flex justify-content-between sticky-top">
      <div class="col-6">
        <mat-icon aria-hidden="false" aria-label="Example home icon">filter_alt</mat-icon>
        <h2 id="tituloFiltro"><strong>Filtros</strong></h2>
      </div>
      <div style="display: flex; align-items: center;">
        <button id="btnLimpar" mat-raised-button (click)="clear()">Limpar</button>
        <a (click)="close($event)">
          <mat-icon id="iconeFechar" aria-hidden="false" aria-label="Example home icon" style="margin-left: 10px; cursor: pointer;">close_alt</mat-icon>
        </a>
      </div>
    </div>

    <div class="row">
      <mat-form-field class="example-full-width">
        <mat-label>Número do Pedido</mat-label>
        <input matInput id="numeroPedido" formControlName="numeroPedido" placeholder="Ex. Número do Pedido">
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="example-full-width">
        <mat-label>Nome de Cliente</mat-label>
        <input matInput id="nomeCliente" formControlName="nomeCliente" placeholder="Ex. Nome do Cliente">
      </mat-form-field>
    </div>
    <button id="btnConsultar" mat-raised-button color="primary" (click)="save()" style="float: right;">Buscar</button>
  </div>
</form>