import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PerdidosService } from 'src/app/services/pedidos.service';
import { ErrorMessageService } from '../../../../shared/error-menssage/error-message.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

@Component({
  selector: 'app-data-hora-entrega',
  templateUrl: './data-hora-entrega.component.html',
  styleUrls: ['./data-hora-entrega.component.scss'],
  providers: [DatePipe]
})
export class DataHoraEntregaComponent implements OnInit { 
  dataHoraEntrega: FormGroup; 
  numeroOrder: string;
  dataCriacao: string;

  constructor(
    public dialogRef: MatDialogRef<DataHoraEntregaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { numeroOrder : string, dataCriacao : string },
    private formBuilder: FormBuilder,
    private pedidoService: PerdidosService,
    private errorMessageService: ErrorMessageService,
    private datePipe: DatePipe,
    private router : Router
  ) {}

  ngOnInit(): void {
    this.dataHoraEntrega = this.formBuilder.group({
      data: ['', [Validators.required]],
      hora: ['', [Validators.required]]
    });

    this.numeroOrder = this.data.numeroOrder;

    //Atualizado data para formato esperado no campo date do html
    this.currentDateOrder(this.data.dataCriacao);
  }

  inserir(){
    let dataFormat = this.dataHoraEntrega.get('data').value + ' ' + this.dataHoraEntrega.get('hora').value;
    let status = '6';    
        
    this.pedidoService.informDeliveryRequest(this.data, status, dataFormat).subscribe(dados => {

      this.errorMessageService.openDialog({
        message: 'SUCESSO',
        messageCode: 'Solicitação entrega informada com sucesso.'
      });

      this.router.navigate(['/painel-vendas']);
    });
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(event: Event) {
    event.preventDefault();
    this.dialogRef.close();
  }

  currentDateOrder(dataOrder){
    
    var criacaoPedido = dataOrder.split(' ');
    var data = criacaoPedido[0].split('/');    
    
    this.dataCriacao = data[2] + '-' + data[1] + '-' + data[0];
  }
}
