import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'app-linha-tempo-painel-seller',
  templateUrl: './linha-tempo-painel-seller.component.html',
  styleUrls: ['./linha-tempo-painel-seller.component.scss']
})
export class LinhaTempoPainelSellerComponent implements OnInit {

  OrderStatus = {
    OK: true,
    N_OK: false,
  }

  timeLineItems = [];

  constructor(
    private dialogRef: MatDialogRef<LinhaTempoPainelSellerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.timeLineItems = this.data.pedido.timeLine;    
  }

  save() {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

}
