import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LojaService } from 'src/app/services/loja.service';
import { PerfilUsuarioService } from 'src/app/services/perfil-usuario.service';
import { StoreSeller, Seller, Stores, MarketPlace } from './perfil-usuario.model';
import { ErrorMessageService } from './../../shared/error-menssage/error-message.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

@Component({
  selector: 'app-cadastro-perfil-usuario',
  templateUrl: './cadastro-perfil-usuario.component.html',
  styleUrls: ['./cadastro-perfil-usuario.component.scss']
})
export class CadastroPerfilUsuarioComponent implements OnInit {

  private state$: Observable<object>;
  public editingUser : any = null;

  cadastroPerfilUsuarioForm: FormGroup;
  currentePerfilUsuario: StoreSeller;
  sellerList: Seller[];
  storeList: Stores[];
  mktPlaceList: MarketPlace[];
  selectedSeller: string;
  isEdit : boolean = false;
  seller = '';

  constructor(
    private fb: FormBuilder,
    private perfilUsuarioService: PerfilUsuarioService,
    private lojaService: LojaService,
    private activatedRoute: ActivatedRoute,
    private errorMessageService: ErrorMessageService,
    private router : Router,
  ) { }

  ngOnInit(): void {
    this.state$ = this.activatedRoute.paramMap
      .pipe(map(() => window.history.state));

    this.state$.subscribe((nav: any) => {
      this.editingUser = nav.user;      
      
      this.isEdit = nav.edit;     
     
      if (this.editingUser) {
        this.selecionarMKT(this.editingUser.seller);
        this.selecionarMKTTT();

        this.cadastroPerfilUsuarioForm = this.fb.group({
          user: [this.editingUser.user],
          description: [this.editingUser.description],
          seller: [this.editingUser.seller],
          webStore: [this.editingUser.webStore],
          store: [this.editingUser.store]          
        });
      }
      else {
        this.cadastroPerfilUsuarioForm = this.fb.group({
          user: [''],
          description: [''],
          seller: [''],
          webStore: [''],                  
          store: ['']
        });
      }
    });

    this.lojaService.findAllSeller().subscribe((response: Seller[]) => {
      this.sellerList = response;
    });
  }

  selecionarMKT(seller: string) {
    this.seller = seller;
    this.lojaService.findAllStores(seller).subscribe((response: any) => {
      this.mktPlaceList = response.marketPlace as MarketPlace[];      
    });
  }

  selecionarMKTTT() {
    this.lojaService.findAllStores(this.seller).subscribe((response: any) => {
      this.storeList = response.stores as Stores[];
    });
  }

  submit() {
    if (this.cadastroPerfilUsuarioForm.valid) {
      if(this.editingUser){
        this.perfilUsuarioService.editPerfilUsuario(this.cadastroPerfilUsuarioForm.value).subscribe((response)=>{
          this.errorMessageService.openDialog({
            message: 'SUCESSO',
            messageCode: 'Usuário editado com sucesso.'
          });
          this.router.navigate(['/grid-perfil-usuario']);
        });
      }
      else{
        if(this.cadastroPerfilUsuarioForm.get('user').value != '' &&
          this.cadastroPerfilUsuarioForm.get('description').value != '' &&
          this.cadastroPerfilUsuarioForm.get('seller').value != '' &&
          this.cadastroPerfilUsuarioForm.get('webStore').value != '' &&
          this.cadastroPerfilUsuarioForm.get('store').value != '') 
        {
          this.perfilUsuarioService.createPerfilUsuario(this.cadastroPerfilUsuarioForm.value).subscribe((response:any)=>{
            if(response.erro){
              alert(response.erro.description);
              return;
            }
            this.errorMessageService.openDialog({
              message: 'SUCESSO',
              messageCode: 'Usuário criado com sucesso.'
            });
            this.router.navigate(['/grid-perfil-usuario']);
          });
        } else{
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Por favor preencher todos os campos.'
          });
        }    
      }
    }
  }
}
