<div class="style-registration">
    <h6 id="titleModal" class="modal-title">Apagar sla tipo de entrega</h6>
    <p id="subtitleModal">Deseja realmente retirar um dos slas tipo de entrega da lista?</p>
</div>

<div mat-dialog-actions class="d-flex flex-row-reverse p-4">
    <div>
        <button mat-button class="mat-flat-button m-1 message-button" (click)="close()" id="buttonSubmitCancel">CANCELAR</button>
        <button mat-button class="mat-flat-button m-1 message-btn" (click)="deleteSlaTipoEntrega()"
            id="buttonSubmitApagar">APAGAR SLA TIPO DE ENTREGA</button>
    </div>
</div>