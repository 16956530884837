<div class="style-registration">
  <div class="style-registration__header">
    <div>
      <h2 id="titleEmpresa">Cadastro de Lojas</h2>
    </div>
  </div>
  <mat-divider></mat-divider>
  <form [formGroup]="cadastroLojaSellerForm" (submit)="submit()">
    <div class="row form-registration mb-5">
      <div class="col-md-3">
        <h2 id="subTittle">Dados da Loja</h2>
      </div>
      <div class="col-md-9 pt-2 pr-5">
        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="outline" style="background-color: transparent;">
                <mat-label>Seller</mat-label>
                <mat-select id="comboSeller" formControlName="seller" (selectionChange)="selecionarMKT($event.value)">
                    <mat-option *ngFor="let seller of sellerList" value="{{seller.seller}}">
                        {{seller.seller}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline" style="background-color: transparent;">
                <mat-label>Web Store</mat-label>
                <mat-select id="comboWebStore" formControlName="webStore" [value]="editingStore?.webStore">
                    <mat-option *ngFor="let mktPlace of mktPlaceList" value="{{mktPlace.webstoreID}}">
                        {{mktPlace.webstoreID}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Loja</mat-label>
              <input matInput id="campoStore" formControlName="idStore" placeholder="Ex: Loja" [readonly]="isEdit == false">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Filial</mat-label>
              <input matInput id="filial" formControlName="branch" placeholder="Ex: MKT001">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-8">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Rasão Social</mat-label>
              <input matInput id="nomeStore" formControlName="rSocial" placeholder="Ex: Rasão Social da Empresa">
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>CNPJ</mat-label>
              <input matInput id="cnpj" formControlName="cnpj" minlength="2" maxlength="18" placeholder="Ex: 12.345.678/0001-23" mask="00.000.000/0000-00">
            </mat-form-field>
          </div>
          </div>
        <div class="row mt-3">
          <div class="col-4">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Descrição</mat-label>
              <input matInput id="nomeStore" formControlName="store" placeholder="Ex: Nome da Loja">
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Inscrição Estadual</mat-label>
              <input matInput id="inscricaoEstadual" formControlName="stateRegistration" minlength="2" maxlength="18" placeholder="Ex: 123456 ou ISENTO">
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Inscrição Municipal</mat-label>
              <input matInput id="inscricaoMunicipal" formControlName="ccm" minlength="2" maxlength="18">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4">
            <mat-label style="font-size: 15; color: gray; margin-right: 10px;">Cliente Contribuinte </mat-label>                       
                <mat-slide-toggle                
                    id="toggleContribuite"                    
                    class="example-margin"
                    formControlName="taxPayerCustomer"
                    [color]="color">                                                      
                </mat-slide-toggle>            
          </div>
          <div class="col-4">
            <mat-label style="font-size: 15; color: gray; margin-right: 10px;">Simples Nacional </mat-label>                       
                <mat-slide-toggle                
                    id="toggleSimplesNacional"                    
                    class="example-margin"
                    formControlName="simplesNacional"
                    [color]="color">                                                      
                </mat-slide-toggle>            
          </div>
          <div class="col-4">
            <mat-label style="font-size: 15; color: gray; margin-right: 10px;">MEI/SIMEI </mat-label>                       
                <mat-slide-toggle                
                    id="toggleMeiSimei"                    
                    class="example-margin"
                    formControlName="meiSimei"
                    [color]="color">                                                      
                </mat-slide-toggle>            
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row form-registration mb-5">
      <div class="col-md-3">
        <h2 id="subTittle">Endereço</h2>
      </div>
      <div class="col-md-9 pt-0 pr-5">
        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="outline">
              <mat-label>CEP</mat-label>
              <input matInput id="cep" formControlName="cep" placeholder="Ex: 12345-678" mask="00.000-000" maxlength="10">
              <button mat-icon-button id="iconeBuscarCep" matSuffix (click)="buscaCEP()">
                <mat-icon matSuffix>search</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-2">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Tipo Logradouro</mat-label>
              <input matInput id="tipoLogradouro" formControlName="placeType" placeholder="Ex: Rua" [readonly]="cepDestravado">
            </mat-form-field>
          </div>
          <div class="col-8">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Endereço</mat-label>
              <input matInput id="endereco" formControlName="address" placeholder="Ex: Rua Abc" [readonly]="cepDestravado">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Número</mat-label>
              <input matInput id="numero" formControlName="number" placeholder="Ex: 123">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-5">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Bairro</mat-label>
              <input matInput id="bairro" formControlName="neighborhood" placeholder="Ex: Abcde" [readonly]="cepTravadoInfo">
            </mat-form-field>
          </div>
          <div class="col-5">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Cidade</mat-label>
              <input matInput id="cidade" formControlName="city" placeholder="Ex: São Paulo" [readonly]="cepTravadoInfo">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>UF</mat-label>
              <input matInput id="uf" formControlName="uf" placeholder="Ex: SP" [readonly]="cepTravadoInfo">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Complemento</mat-label>
              <input matInput id="complemento" formControlName="complement" placeholder="Ex: Casa, Apartamento, etc">
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Latitude</mat-label>
              <input matInput id="latitude" formControlName="latitude" placeholder="Ex: 123456789">
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Longitude</mat-label>
              <input matInput id="longitude" formControlName="longitude" placeholder="Ex: 123456789">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row form-registration mb-5">
      <div class="col-md-3">
        <h2 id="subTittle">Informações Loggi</h2>
      </div>
      <div class="col-md-9 pt-2 pr-5">
        <div class="row">
          <div class="col-3">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>ID da Loja</mat-label>
              <input matInput id="idStore" formControlName="storePK" placeholder="Ex: 555">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Instruções de Retirada</mat-label>
              <input matInput id="instrucoesRetirada" formControlName="withdrawalInstructions" placeholder="Ex: Retirada após as 7h">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="text-align: right;">
      <div class="col-md-12">
        <button class="size-button" id="salvarStore" mat-raised-button color="primary"
          style="text-transform: uppercase; margin: 30px; width: 280px;">Salvar</button>
      </div>
    </div>
  </form>
</div>
