import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LojaService } from 'src/app/services/loja.service';
import { MarketPlace, Seller, Stores } from '../cadastro-perfil-usuario/perfil-usuario.model';
import { ErrorMessageService } from './../../shared/error-menssage/error-message.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

@Component({
  selector: 'app-cadastrar-lojas-seller',
  templateUrl: './cadastrar-lojas-seller.component.html',
  styleUrls: ['./cadastrar-lojas-seller.component.scss']
})
export class CadastrarLojasSellerComponent implements OnInit {

  cadastroLojaSellerForm: FormGroup;
  private state$: Observable<object>;
  public editingStore: any = null;
  cepDestravado: boolean = false;
  cepTravadoInfo: boolean = false;
  isEdit: boolean = false;
  sellerList: Seller[];
  mktPlaceList: MarketPlace[];
  storeList: Stores[];
  seller = '';
  sellerEdit = null;


  constructor(
    private fb: FormBuilder,
    private lojaService: LojaService,
    private activatedRoute: ActivatedRoute,
    private errorMessageService: ErrorMessageService,
    private router: Router
  ) { }

  color: ThemePalette = 'primary';

  ngOnInit(): void {
    this.state$ = this.activatedRoute.paramMap
      .pipe(map(() => window.history.state));

    this.state$.subscribe((nav: any) => {
      this.editingStore = nav.user;      
      
      this.isEdit = nav.edit;

      if (this.editingStore) {
        this.selecionarMKT(this.editingStore.seller);

        if(this.editingStore.seller === "FASTSHOP"){
          this.isEdit = true;
        } else {
          this.isEdit = false;
        }

        this.cadastroLojaSellerForm = this.fb.group({
          seller: [this.editingStore.seller],
          webStore: [this.editingStore.webStore],
          idStore: [this.editingStore.idStore],
          cnpj: [this.editingStore.cnpj],
          stateRegistration: [this.editingStore.stateRegistration],
          ccm: [this.editingStore.ccm],
          taxPayerCustomer: [this.editingStore.taxPayerCustomer],
          simplesNacional: [this.editingStore.simplesNacional],
          meiSimei: [this.editingStore.meiSimei],
          store: [this.editingStore.store],
          branch: [this.editingStore.branch],
          cep: [this.editingStore.cep],
          placeType: [this.editingStore.placeType],
          address: [this.editingStore.address],
          number: [this.editingStore.number],
          neighborhood: [this.editingStore.neighborhood],
          city: [this.editingStore.city],
          uf: [this.editingStore.uf],
          complement: [this.editingStore.complement],
          latitude: [this.editingStore.latitude],
          longitude: [this.editingStore.longitude],
          storePK: [this.editingStore.storePK],
          withdrawalInstructions: [this.editingStore.withdrawalInstructions],
          rSocial: [this.editingStore.rSocial]
        });
      } else {
        this.cadastroLojaSellerForm = this.fb.group({
          seller: [''],
          webStore: [''],
          idStore: [''],
          cnpj: [''],
          stateRegistration: ['', [Validators.required]],
          ccm: ['', [Validators.required]],
          taxPayerCustomer: [false],
          simplesNacional: [false],
          meiSimei: [false],
          store: [''],
          branch: [''],
          cep: [''],
          placeType: [''],
          address: [''],
          number: [''],
          neighborhood: [''],
          city: [''],
          uf: [''],
          complement: [''],
          latitude: [''],
          longitude: [''],
          storePK: [''],
          withdrawalInstructions: [''],
          rSocial: ['']
        });
        this.isEdit = false;
      }
    });

    this.lojaService.findAllSeller().subscribe((response: Seller[]) => {
      this.sellerList = response;
    });
  }

  buscaCEP() {
    if (this.cadastroLojaSellerForm.get('cep').value) {
      this.lojaService.findCep(this.cadastroLojaSellerForm.get('cep').value).subscribe((response: any) => {

        if (response.erro) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'CEP não encontrado.'
          });
          return;
        }
        
        if(response.logradouro === "") {
          this.cepTravadoInfo = true;
          this.cepDestravado = false;
        } else {
          this.cepTravadoInfo = true;
          this.cepDestravado = true;
        }

        var streetType = "";

        if(response.logradouro){
          var result = response.logradouro.split(" ");
          streetType = result[0];          
        }

        this.cadastroLojaSellerForm.patchValue({
          placeType: streetType,
          address: response.logradouro,
          neighborhood: response.bairro,
          city: response.localidade,
          uf: response.uf
        });
      });
      return false;
    } else {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor preencher o campo CEP.'
      });
    }
  }

  submit() {
    if (this.cadastroLojaSellerForm.valid) {
      if (this.editingStore) {
        this.lojaService.editStore(this.cadastroLojaSellerForm.value, this.editingStore.seller, this.editingStore.idStore).subscribe((response) => {
          this.errorMessageService.openDialog({
            message: 'SUCESSO',
            messageCode: 'Loja editada com sucesso.'
          });
          this.router.navigate(['/grid-lojas']);
        })
      } else {
        this.lojaService.createStore(this.cadastroLojaSellerForm.value).subscribe((response) => {
          this.errorMessageService.openDialog({
            message: 'SUCESSO',
            messageCode: 'Loja criada com sucesso.'
          });
          this.router.navigate(['/grid-lojas']);
        });
      }
    }
  }

  selecionarMKT(seller: string) {    
    if(seller === 'FASTSHOP'){
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }
    this.lojaService.findAllStores(seller).subscribe((response: any) => {
      this.mktPlaceList = response.marketPlace as MarketPlace[];
    });
  }
}
