<div class="seller-panel-card" [@fadeIn]="entrou ? 'dentro' : 'fora'">
  <div [ngClass]="'card-strip ' + kind.toLowerCase()">
    <ng-container [ngSwitch]="kind">
      <mat-icon *ngSwitchCase="kinds.WARNING" aria-hidden="false" aria-label="icon">error_outline</mat-icon>
      <mat-icon *ngSwitchCase="kinds.SUCCESS" aria-hidden="false" aria-label="icon">check</mat-icon>
      <mat-icon *ngSwitchCase="kinds.ALERT" aria-hidden="false" aria-label="icon">notification_important</mat-icon>
      <mat-icon *ngSwitchCase="kinds.DANGER" aria-hidden="false" aria-label="icon">notification_important</mat-icon>
    </ng-container>
  </div>

  <div class="card-info">
    <div class="card-title">
      {{title}}
    </div>

    <div class="card-content">
      {{value}}
    </div>
  </div>
</div>
