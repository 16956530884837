import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SlaService } from 'src/app/services/sla.service';
import { SlaInfo, Status } from './SlaInfo.model';
import Timeline from '../../shared/Timeline';
import { Router } from '@angular/router';
import { ErrorMessageService } from './../../shared/error-menssage/error-message.service';
import { DeleteSlaStatusComponent } from 'src/app/shared/deletar-sla-status/delete-sla-status.component';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

@Component({
  selector: 'app-status-sla',
  templateUrl: './status-sla.component.html',
  styleUrls: ['./status-sla.component.scss']
})
export class StatusSlaComponent implements OnInit {

  statusForm: FormGroup;
  public dataSource: any[] = [];
  public timeline: string[] = Timeline;
  public selectedSlaInfo: SlaInfo = null;
  public selectedTimelineItem: string;
  public deliveryTypes: string[] = [];
  isEdit: boolean = false;

  constructor(
    private fb: FormBuilder,
    private slaService: SlaService,
    private errorMessageService: ErrorMessageService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  displayedColumns: string[] = ['deliveryType', 'status', 'sla'];
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.statusForm = this.fb.group({
      deliveryType: [''],
      status: [''],
      slaMin: ['']
    });
    this.updateList();

  }

  selectSlaInfoGrid(slaInfo: SlaInfo) {
    this.isEdit = true;
    this.selectedSlaInfo = slaInfo;
    this.statusForm = this.fb.group({
      deliveryType: [slaInfo.deliveryType],
      status: [slaInfo.status],
      slaMin: [slaInfo.slaMin]
    });
  }

  updateList() {
    this.dataSource = [];
    this.deliveryTypes = [];

    this.slaService.getAllSlaConfiguration().subscribe((slaConfigs: SlaInfo[]) => {
      slaConfigs.forEach((sla: SlaInfo) => {
        this.deliveryTypes.push(sla.deliveryType);
        if (sla.status != null)
          this.dataSource = this.dataSource.concat(sla.status?.map((status: Status) => {
            return { deliveryType: sla.deliveryType, status: status.status, slaMin: status.slaMin }
          }));
      });
    });
  }

  selectSlaInfo(event: any) {
    this.selectedSlaInfo = event.value;
  }

  selectTimelineItem(item: string) {
    this.selectedTimelineItem = item;
  }

  updateSla() {

    if(this.statusForm.get('deliveryType').value == null ||
        this.statusForm.get('status').value == null ||
        this.statusForm.get('slaMin').value == null){
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor preencher todos os campos.'
      });
      return;
    }

    const STATUS = this.checkStatus(this.statusForm.get('status').value);

    if (this.statusForm.get('deliveryType').value != '' &&
      this.statusForm.get('status').value != '' &&
      this.statusForm.get('slaMin').value != '') {
      this.slaService.updateSlaStatus({
        deliveryType: this.selectedSlaInfo,
        slaMin: this.statusForm.get('slaMin').value,
        status: this.selectedTimelineItem
      }, STATUS).subscribe((response: any) => {

        if (response.erro) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: response.erro.description + '.'
          });
          return;
        }
        this.updateList();
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'SLA Status criado com sucesso.'
        });
        this.statusForm.reset();
        this.router.navigate(['/sla-status']);
      });
    } else {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor preencher todos os campos.'
      });
    }
  }

  tiposEntrega() {
    return this.dataSource?.map((slainfo: SlaInfo) => slainfo.deliveryType);
  }

  editSlaStatus() {

    if(this.statusForm.get('deliveryType').value == null &&
        this.statusForm.get('status').value == null &&
        this.statusForm.get('slaMin').value == null){
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor selecione ao menos uma opção no Grid.'
      });
      return;
    }

    const STATUS = this.checkStatus(this.statusForm.get('status').value);

    if (this.statusForm.get('deliveryType').value != '' && this.statusForm.get('status').value != '' && this.statusForm.get('slaMin').value != '') {
      this.slaService.editSlaStatus(
        new SlaInfo(
          this.statusForm.get('deliveryType').value,
          this.statusForm.get('slaMin').value,
          this.selectedSlaInfo.id,
          this.statusForm.get('status').value,
        ), this.selectedSlaInfo.deliveryType, STATUS
      ).subscribe(() => {
        this.updateList();
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Sla Status editado com sucesso.'
        });
        this.statusForm.reset();
        this.router.navigate(['/sla-status']);
        this.isEdit = false;
      });
    } else {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor selecione ao menos uma opção no grid.'
      });
    }
  }

  public openModalDialogDeleteSlaStatus() {
    const STATUS = this.checkStatus(this.statusForm.get('status').value);
    let tipoEntrega = this.statusForm.get('deliveryType').value;
    let status = this.statusForm.get('status').value;
    let slaMin = this.statusForm.get('slaMin').value;

    if(tipoEntrega == null && status == null && slaMin == null){
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor selecione ao menos uma opção no Grid.'
      });
      return;
    }
    
    if (this.statusForm.get('deliveryType').value != '' &&
      this.statusForm.get('status').value != '' &&
      this.statusForm.get('slaMin').value != '') {

      let id = this.selectedSlaInfo.id;
      let selectDeliveryType = this.selectedSlaInfo.deliveryType;

      const dialogRef = this.dialog.open(DeleteSlaStatusComponent, {
        width: '40%',
        data: {
          tipoEntrega,
          status,
          slaMin,
          id,
          selectDeliveryType,
          STATUS
        },
      });
      const subscription = dialogRef.afterClosed().subscribe((result: any[]) => {
        setTimeout(() => { this.updateList(); this.statusForm.reset(); this.isEdit = false; }, 1000)
      });
      this.subscriptions.push(subscription);
    } else {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor selecione ao menos uma opção no grid.'
      });
    }
  }

  private checkStatus(status) {
    if (status === this.timeline[0]) { // Aguardando Pagamento      
      return '1';
    } else if (status === this.timeline[1]) { // Aguardando Confirmação      
      return '2';
    } else if (status === this.timeline[2]) { // Aguardando Separação      
      return '3';
    } else if (status === this.timeline[3]) { // Aguardando Expedição      
      return '4';
    } else if (status === this.timeline[4]) { // Em rota de Entrega
      return '5';
    } else if (status === this.timeline[5]) { // Entregue
      return '6';
    } else if (status === this.timeline[6]) { // Recusado Seller
      return '7';
    } else if (status === this.timeline[7]) { // Cancelado      
      return '8';
    } else if (status === this.timeline[8]) { // Problema na Entrega
      return '9';
    } else if (status === this.timeline[9]) { // Problema na Transportadora
      return '10';
    }
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }
}
