<div class="style-registration">
    <div class="style-registration__header">
        <div>
            <h2 id="titleEmpresa">Configuração de transportadora</h2>
        </div>
    </div>
    <mat-divider></mat-divider>
    
    <form [formGroup]="configTransportadoraForm">
        <div class="row form-registration mb-5">
            <div class="col-md-4">
                <h2 id="subTittle">Configuração de Transportadora</h2>
            </div>
            <div class="col-md-8 pt-2 pr-5">
                <div class="row">
                    <div class="col-4">
                        <mat-form-field appearance="outline" style="background-color: transparent;">
                            <mat-label>Código</mat-label>
                            <input matInput id="codigo" placeholder="Ex: teste" formControlName="code">
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field appearance="outline" style="background-color: transparent;">
                            <mat-label>Nome</mat-label>
                            <input matInput id="nome" placeholder="Ex: teste" formControlName="name">
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field appearance="outline" style="background-color: transparent;">
                            <mat-label>Nome Reduzido</mat-label>
                            <input matInput id="nomeReduzido" placeholder="Ex: teste" formControlName="alias">
                        </mat-form-field>
                    </div>             
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4"></div>            
            <div class="col-md-2" style="text-align: center;">
                <button (click)="createConfigTransporte()" id="btnIncluir" class="size-button" mat-raised-button color="primary"
                    style="text-transform: uppercase;  width: 150px;">Incluir</button>
            </div>
            <div class="col-md-2" style="text-align: center;">
                <button (click)="openModalDialogDeleteParametrizacao()" id="btnExcluir" class="size-button" mat-raised-button color="primary" 
                    style="text-transform: uppercase; width: 150px;">Excluir</button>
            </div>
            <div class="col-md-2" style="text-align: center;">
                <button (click)="updateConfigTransporte()" id="btnEditar" class="size-button" mat-raised-button color="primary"
                    style="text-transform: uppercase; width: 150px;">Editar</button>
            </div>
        </div>
    </form>
    
    <div class="col-md-12 pt-2 pr-5">
        <div class="content-table">
            <table mat-table id="gridParametrizacao" [dataSource]="transportConfigurationList" class="mat-elevation-z0">
                <caption style="display: none;">Resultados que contém todas parametrizações de transportadora.</caption>
                <ng-container matColumnDef="codigo">
                    <th id="code" mat-header-cell *matHeaderCellDef> Código </th>
                    <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                </ng-container>
                <ng-container matColumnDef="nome">
                    <th id="name" mat-header-cell *matHeaderCellDef> Nome </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
                <ng-container matColumnDef="nomeReduzido">
                    <th id="alias" mat-header-cell *matHeaderCellDef> Nome Reduzido </th>
                    <td mat-cell *matCellDef="let element"> {{element?.alias}} </td>
                </ng-container>
                <ng-container matColumnDef="entregador">
                    <th id="deliveryEntregador" mat-header-cell *matHeaderCellDef> Inf Manual Entregador </th>
                    <td style="text-align: center;" mat-cell *matCellDef="let element; index as i">
                        <mat-slide-toggle (change)="checkToggle(element, $event.checked, 'delivery')"
                            id="toggleEntregador{{i}}"
                            class="example-margin"
                            [color]="color"
                            [checked]="element.manualDeliveryInfo"
                        >
                        </mat-slide-toggle>
                    </td>
                </ng-container>
                <ng-container matColumnDef="entrega">
                    <th id="deliveryEntrega" mat-header-cell *matHeaderCellDef> Inf Manual Entrega </th>
                    <td style="text-align: center;" mat-cell *matCellDef="let element; index as i"> 
                        <mat-slide-toggle (change)="checkToggle(element, $event.checked, 'currier')"
                            id="toggleEntrega{{i}}"
                            class="example-margin"
                            [color]="color"
                            [checked]="element.manualDeliveryManInfo"
                        >                                                        
                        </mat-slide-toggle>
                    </td>
                </ng-container>                
                <tr class="mat-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectTransportInfo(row)"></tr>
            </table>
        </div>
    </div>
</div>