import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PerdidosService } from 'src/app/services/pedidos.service';
import { FiltroBuscarPedidosComponent } from './modals/filtro-buscar-pedidos/filtro-buscar-pedidos.component';
import { FiltrosPainelSellerComponent } from './modals/filtros-painel-seller/filtros-painel-seller.component';
import { LinhaTempoPainelSellerComponent } from './modals/linha-tempo-painel-seller/linha-tempo-painel-seller.component';
import { Orders, Telemetria, TimelineItem } from './seller-panel.model';
import * as fileSaver from 'file-saver';
import { ErrorMessageService } from './../../shared/error-menssage/error-message.service';
import { DatePipe } from '@angular/common';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

@Component({
  selector: 'app-seller-panel',
  templateUrl: './seller-panel.component.html',
  styleUrls: ['./seller-panel.component.scss'],
  providers: [DatePipe]
})
export class SellerPanelComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private pedidoService: PerdidosService,
    private errorMessageService: ErrorMessageService,
    private datePipe: DatePipe,
    private router: Router
  ) {
    setInterval(() => {
      this.atualizaGrid();
    }, 60000);
  }

  public dataSource = new MatTableDataSource();

  displayedColumns: string[] = ['seller', 'orderNumber', 'deliveryType', 'dateHour', 'transport', 'storeName', 'uf', 'status', 'nameClerk', 'farol', 'timer', 'slaTotal', 'slaEtapa'];
  currentTelemetria: Telemetria;
  currentTimeLine: TimelineItem;
  toasts = [];
  statusOrder = '';
  valueExport = '';
  filterMethod = '';
  slaEtapa = 0;
  checkStatus = '';
  statusComponent = [];

  ultimaAtualizacao = '';
  filtroAtualizar : boolean = false;

  //Variaveis para função download
  sellerDown = '';
  webStoreDown = '';
  storeDown = '';
  statusDown = '';
  dataInitDown = '';
  dataFinitDown = '';

  ngOnInit(): void {
    
    setTimeout(() => {
      this.atualizaGrid();
    }, 1000
    );
    
    this.clearParam();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  load() {
    this.filtroAtualizar = false;

    this.pedidoService.getAllPedidos().subscribe((dados: any) => {
      this.dataSource.data = dados;
      let myDateAtual = new Date;
      this.ultimaAtualizacao = this.datePipe.transform(myDateAtual, 'dd/MM/yyyy HH:mm');
    });    
    
    //Atualizando telemetria
    var checkOriginLoad = "currentLoad";
    this.updateTelemetria(this.webStoreDown, this.storeDown, this.dataInitDown, this.dataFinitDown, this.statusDown, checkOriginLoad);

    //Atualiza valores para defalut para variáveis de filtro
    this.clearParam();
  }

  atualizaGrid() {
    if (this.filtroAtualizar) {     
      this.filterSaveOrder();
    } else {
      this.load();
    }
  }

  filterSaveOrder() {
    this.pedidoService.filterByOrderComplet(this.webStoreDown, this.storeDown, this.dataInitDown, this.dataFinitDown, this.statusDown).subscribe((response: Orders[]) => {
      this.dataSource.data = response;
    });

  }

  updateTelemetria(seller, store, dataInit, dataFinit, status, checkOrigin) {

      this.pedidoService.getTelemetry(seller, store, dataInit, dataFinit, status, checkOrigin).subscribe((response: Telemetria) => {
        this.currentTelemetria = response;
        this.toasts = [
          { title: "Em Rota de Entrega", value: response.deliveryRoute },
          { title: "Aguardando Confirmação", value: response.awaitingConfirmation }
        ]
      });
  
  }

  timelineModal(pedido: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = "50vw";
    dialogConfig.maxHeight = "40vw";
    dialogConfig.data = {
      pedido: pedido,
      sair: false
    };

    this.dialog.open(LinhaTempoPainelSellerComponent, dialogConfig);
    
    return false;
  }

  filterPedidoModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = "40vw";
    dialogConfig.maxHeight = "20vw";
    dialogConfig.data = {
      sair: false
    };

    const dialogRef = this.dialog.open(FiltroBuscarPedidosComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((opt) => {

      var filterMethod = "";
      var filterType = "";

      if(opt.filtroNomePedido.numeroPedido){
        filterMethod = 'orderNumber';
        filterType = opt.filtroNomePedido.numeroPedido;
      } else if(opt.filtroNomePedido.nomeCliente){
        filterMethod = 'name';
        filterType = opt.filtroNomePedido.nomeCliente;
      } else {
        this.errorMessageService.openDialog({
          message: 'ERRO',
          messageCode: 'Não temos nenhum dado para exibir, pois a consulta está vazia.'
        });
      }

      this.pedidoService.filterByOrderName(filterMethod, filterType).subscribe((response: any) => {
          
        var error = response[0]?.error;

        if(error != null){
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.description
          });
        } else {
          this.dataSource.data = response;
        }
      });
    });
  }

  filterModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = "40vw";

    dialogConfig.data = {
      sair: false
    };

    const dialogRef = this.dialog.open(FiltrosPainelSellerComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((opt) => {

      let sele = opt.filtroForm?.seller;
      let sellerFilter = opt.filtroForm?.webStore;
      if (sele === undefined) {
        sellerFilter = '';
      }
      let webStore = opt.filtroForm?.webStore;
      let storeFilter = opt.filtroForm?.store;
      let dataInicial = opt.filtroForm?.dataInicio;
      let dataFinal = opt.filtroForm?.dataFim;
      let statusFilter = opt.filtroForm?.status;

      this.sellerDown = sele;
      this.webStoreDown = webStore;
      this.storeDown = storeFilter;
      this.statusDown = statusFilter;
      this.dataInitDown = dataInicial;
      this.dataFinitDown = dataFinal;

      if (sele === 'FASTSHOP') {
        sellerFilter = opt.filtroForm?.seller;
      }

      const opcao = opt.filtroForm.pendencias;

      //Atualizando Telemetria do Painel Seller
      var checkOrigin = "filterLarger";
      this.updateTelemetria(sellerFilter, storeFilter, dataInicial, dataFinal, statusFilter, checkOrigin);

      this.pedidoService.filterByOrderComplet(sellerFilter, storeFilter, dataInicial, dataFinal, statusFilter).subscribe((response: Orders[]) => {
        this.dataSource.data = response;

        this.filtroAtualizar = true;

        this.filterUpdateFarolPanel(response, opcao);

      });
    });
  }
  
  checkStatusOrder(valor){    
    this.checkStatus = valor;
  }

  filterUpdateFarolPanel(response, opcao){
    const _MAX_MINUTOS_RESTANTES = 120;    
    
    switch (opcao) {
      case 'Risco de Atraso':
        this.dataSource.data = response.filter((currentOrder: Orders) => {
          const tempoRestante = this.timerCalculation(currentOrder);
          if (tempoRestante < _MAX_MINUTOS_RESTANTES / 2 &&
            tempoRestante >= 0 &&
            !this.isDelivered(currentOrder)
          ) {
            return currentOrder;
          }
        });
        break;
      case 'Inconsistência':
        this.dataSource.data = response.filter((currentOrder: Orders) => {

          const tempoEntrega = this.timerCalculationFinishStatus(currentOrder);
          const tempoSla = this.getSlaTotal(currentOrder);
          const tempoTotal = this.timerCalculation(currentOrder);
                    
          if(currentOrder.lastTimeLineDescription === 'Entregue' || currentOrder.lastTimeLineDescription === 'Cancelado'){
            if(tempoEntrega > parseInt(tempoSla)){
              return true;
            } else {
              return false;
            }
          } else if( tempoTotal < 0){
            return true;
          } else if(currentOrder.lastTimeLineDescription === 'Problema na Transportadora' || currentOrder.lastTimeLineDescription === 'Problema na Entrega'  || currentOrder.inconsistent){
            return true;
          } else {
            return false;
          }
        });
        break;
      case 'Sem Pendências':
        this.dataSource.data = response.filter((currentOrder: Orders) => {
          const tempoRestante = this.timerCalculation(currentOrder);
          if (tempoRestante > _MAX_MINUTOS_RESTANTES / 2) {
            return currentOrder;
          }
          else if(tempoRestante < -1 && this.isDelivered(currentOrder))
            return currentOrder;
        });
        break;
      case 'Inconsistência no Pagamento':
        this.dataSource.data = response.filter((currentOrder: Orders) => {
          if (currentOrder.inconsistent) {
            return true;
          } else {
            return false;
          }
        });
        break;
      default:
        this.dataSource.data = response;
    }
  }

  isDelivered(element): boolean {
    const _NUMERO_DE_PASSOS_ENTREGUE = 6;
    return element.timeLine.length === _NUMERO_DE_PASSOS_ENTREGUE;
  }

  timerCalculationFinishStatus(element: Object) {

    if (element.hasOwnProperty('timeLine')) {
      const order: Orders = element as Orders;
      const orderDateTime: Date = this.dateConversion(order.lastTimeLineDateTime); // dd/MM/yyyy hh:mm

      const initTime: Date = this.dateConversion(order.timeLine[0].dateTime);
      const maxTime: Date = new Date(orderDateTime.getTime()); /* cria um Date para fazer a comparacao */
      maxTime.setHours(orderDateTime.getHours());

      const ms = maxTime.getTime() - initTime.getTime();
      const sec = ms / 1000;
      const min = sec / 60;

      return Math.floor(min);
    }
  }

  timerCalculationStoge(element: Object) {

    if (element.hasOwnProperty('timeLine')) {
      const order: Orders = element as Orders;
      const orderDateTime: Date = this.dateConversion(order.lastTimeLineDateTime); // dd/MM/yyyy hh:mm

      const currentTime: Date = new Date();
      const maxTime: Date = new Date(orderDateTime.getTime()); /* cria um Date para fazer a comparacao */
      maxTime.setHours(orderDateTime.getHours());

      const ms = currentTime.getTime() - maxTime.getTime();
      const sec = ms / 1000;
      const min = sec / 60;

      return Math.floor(min);
    }
  }

  /* Retorna o tempo restante em minutos */
  timerCalculation(element: Object) {

    if (element.hasOwnProperty('timeLine')) {
      const order: Orders = element as Orders;
      const orderDateTime: Date = this.dateConversion(order.timeLine[0].dateTime); // dd/MM/yyyy hh:mm
      const slaTotal = parseInt(order.deliveryTypeSlaTotal) / 60;

      const currentTime: Date = new Date();
      const maxTime: Date = new Date(orderDateTime.getTime()); /* cria um Date para fazer a comparacao */
      maxTime.setHours(orderDateTime.getHours() + slaTotal);
      if (currentTime < maxTime) {

        const ms = maxTime.getTime() - currentTime.getTime();
        const sec = ms / 1000;
        const min = sec / 60;
        return Math.floor(min);
      }
      else {
        return -1;
      }
    }
  }

  timerSlaTotal(dataInicio: string, statusPedido: string, dataFim: string) {

    const orderDateTime: Date = this.dateConversion(dataInicio); // dd/MM/yyyy hh:mm
    const currentTime: Date = new Date();
    const dataFimOrder: Date = this.dateConversion(dataFim);

    if (statusPedido == 'Entregue' || statusPedido == 'Cancelado') {
      const ms = dataFimOrder.getTime() - orderDateTime.getTime();
      const sec = ms / 1000;
      const min = sec / 60;

      return Math.floor(min);

    } else {
      const ms = currentTime.getTime() - orderDateTime.getTime();
      const sec = ms / 1000;
      const min = sec / 60;

      return Math.floor(min);
    }
  }

  timerSlaEtapa(dataFim: string, statusPedido: string) {
    const orderDateTime: Date = this.dateConversion(dataFim); // dd/MM/yyyy hh:mm
    const currentTime: Date = new Date();

    if (statusPedido == 'Entregue' || statusPedido == 'Cancelado') {
      return Math.floor(0);
    } else {
      const ms = currentTime.getTime() - orderDateTime.getTime();
      const sec = ms / 1000;
      const min = sec / 60;

      return Math.floor(min);
    }
  }

  dateConversion(dateString: string) {
    const [date, time] = dateString.split(" ");
    const [day, month, year] = date.split("/");
    const [hour, minutes] = time.split(":");
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hour), parseInt(minutes), 0, 0);
  }

  getLasTimelineEvent(pedido: Orders) {
    return pedido?.timeLine[pedido?.timeLine?.length - 1];
  }

  onDownloadExcel() {

    this.pedidoService.exportExcel(this.webStoreDown, this.storeDown, this.dataInitDown, this.dataFinitDown, this.statusDown)
      .subscribe(
        (blob: Blob) => {
          const data = blob.slice(
            0,
            blob.size,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          );
          fileSaver.saveAs(data, "RelatorioOrder.xls");
        });
    this.clearParam();
  }

  getSlaTotal(sla: Orders) {
    return sla.deliveryTypeSlaTotal;
  }

  clearParam() {
    this.sellerDown = '';
    this.webStoreDown = '';
    this.storeDown = '';
    this.statusDown = '';
    this.dataInitDown = '';
    this.dataFinitDown = '';
  }
}
