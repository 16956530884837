<div class="seller-panel-toast" [@fadeIn]="entrou ? 'dentro' : 'fora'"
  [style.right]="offset"
>
  <div class="seller-panel-toast__title">
    <mat-icon aria-hidden="false" aria-label="icon" class="mr-1">notification_important</mat-icon>
    <h5>{{title}}</h5>
  </div>
  <div class="seller-panel-toast__content">
    <h2>{{value}}</h2>
  </div>
</div>
