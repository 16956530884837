<form action="" [formGroup]="motivoCancelamento" class="painel-cancelamento">
    <div class="container-fluid">

        <div class="row header d-flex justify-content-between sticky-top">
            <div class="col-12">
                <h3 id="motivoCancelamento"><strong>Motivo Cancelamento</strong></h3>
            </div>
            <div style="display: flex; align-items: center;">
                <a (click)="close($event)">
                    <mat-icon id="iconeFecharMotivo" aria-hidden="false" aria-label="Example home icon" style="margin-left: 10px;cursor: pointer;">close_alt
                    </mat-icon>
                </a>
            </div>
        </div>

        <mat-form-field appearance="outline" class="customTextArea">
            <mat-label>Observações adicionais</mat-label>
            <textarea matInput id="informarMotivo" placeholder="Informar um motivo" formControlName="descricao" rows="7"
                style="width: 300px; resize: none;"></textarea>
        </mat-form-field>

        <button mat-raised-button color="primary" id="btnInserirMotivo" (click)="inserir()"
            style="float: right; margin-top: 10px">Salvar</button>
    </div>
</form>