import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { LayoutComponent } from './layout.component';

import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { IframeListenerComponent } from './iframe-listener/iframe-listener.component';

@NgModule({
  declarations: [
    HeaderComponent,
    LeftMenuComponent,
    LayoutComponent,
    IframeListenerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule
  ],
  exports: [
    LayoutComponent 
  ]
})
export class LayoutModule { }
