<div class="style-registration no-print">
  <div class="style-registration__header">
    <div class="col-8">
      <h2 id="titleEmpresa">Solicitação Troca Cancelamento</h2>
    </div>

    <div class="col-4">
      <h3>Status</h3>
      <label style="margin-left: 0;font-size:16px;font-family: 'OpenSansLight', sans-serif; text-transform: uppercase;">{{
        statusOrderCancel }}</label>
      <p></p>
      <mat-form-field appearance="outline" style="background-color: transparent; margin-bottom: -25px;">
        <mat-label>Data/Hora do Status</mat-label>
        <input matInput id="dataHoraStatus" placeholder="Ex: 10/11/2020 12:30" value="{{ hourStatus }}" [readonly]="true">
      </mat-form-field>
    </div>
  </div>
  <mat-divider></mat-divider>
  <form [formGroup]="solicitacaoTrocaForm">
    <div class="row form-registration mb-5">
      <div class="col-md-3">
        <h2 class="subTittle" id="titSolicitacao">Dados da Solicitação</h2>
      </div>
      <div class="col-md-9 pt-2 pr-5">
        <div class="row">
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Número da Solicitação</mat-label>
              <input matInput id="numeroSolicitacao" formControlName="numeroSolicitacao" placeholder="Ex: 12345678910" [readonly]="true">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Data/Hora da Solicitação</mat-label>
              <input matInput id="dataHoraSolicitacao" formControlName="dateHour" placeholder="Ex: 10/11/2020 12:30" [readonly]="true">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <mat-form-field appearance="outline">
              <mat-label>Tipo Solicitação</mat-label>
              <mat-select id="comboTipoRequisicao" formControlName="requestType">
                <mat-option value="CANCELAMENTO"> Cancelamento </mat-option>
                <mat-option value="TROCA">Troca</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline">
              <mat-label>Motivo</mat-label>
              <mat-select id="motivo" formControlName="reason">
                <mat-option value="AVARIA">Avaria</mat-option>
                <mat-option value="DEFEITO">Defeito</mat-option>
                <mat-option value="PRODUTO NÃO ATENDEU AS NECESSIDADES">Produto Não Atendeu as Necessidades</mat-option>
                <mat-option value="OUTROS">Outros</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <mat-form-field appearance="outline" class="customTextArea">
              <mat-label>Observações adicionais</mat-label>
              <textarea matInput id="observacao" placeholder="Informar um motivo" style="resize: none;" rows="7"
                formControlName="additionalNotes" [readonly]="isEdit == true">
              </textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row form-registration mb-5">
      <div class="col-md-3">
        <h2 class="subTittle" id="titPedido">Dados do Pedido</h2>
      </div>
      <div class="col-md-9 pt-0 pr-5">
        <div class="row">
          <div class="col-6">
            <mat-form-field appearance="outline">
              <mat-label>Pedido de Venda</mat-label>
              <input matInput id="numeroOrder" formControlName="orderNumber" placeholder="Ex: 12345678" [readonly]="isEdit == true">
              <button mat-icon-button id="iconeBuscarOrder" matSuffix (click)="buscaPedido()">
                <mat-icon matSuffix>search</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Data/Hora</mat-label>
              <input matInput id="dataHora" formControlName="dataHora" placeholder="Ex: 12/10/2020 15:30h" [readonly]="true">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Seller</mat-label>
              <input matInput id="seller" formControlName="seller" placeholder="Ex: Spicy" [readonly]="true">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Loja</mat-label>
              <input matInput id="loja" formControlName="loja"  placeholder="Ex: 321654987 - Center Norte" [readonly]="true">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row form-registration mb-5">
      <div class="col-md-3">
        <h2 class="subTittle" id="titCliente">Cliente</h2>
      </div>
      <div class="col-md-9 pt-2 pr-5">
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Nome</mat-label>
              <input matInput id="nome" formControlName="nome" placeholder="Ex: Maria Almeida" [readonly]="true">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>CPF/CNPJ</mat-label>
              <input matInput id="cpfCnpj" formControlName="cpf" mask="000.000.000-00" placeholder="Ex: 00001654879-98" [readonly]="true">
            </mat-form-field>
          </div>
          <div class="col-8">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>E-mail</mat-label>
              <input matInput id="email" formControlName="email" placeholder="Ex: MARIALMEIDA@GMAIL.COM" [readonly]="true">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row form-registration mb-5">
      <div class="col-md-3">
        <h2 class="subTittle" id="titTransportadora">Dados da Transportadora</h2>
      </div>
      <div class="col-md-9 pt-2 pr-5">
        <div class="row">
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Transportadora</mat-label>
              <input matInput id="transportadora" formControlName="tranportadora" placeholder="Ex: LOGGI" [readonly]="true">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>ID Pedido Transportadora</mat-label>
              <input matInput id="idTransportadora" formControlName="idTransportadora" placeholder="Ex: ID PEDIDO" [readonly]="true">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Status</mat-label>
              <input matInput id="statusTransportadora" formControlName="status" placeholder="Ex: EM TRÂNSITO" [readonly]="true">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>ID Pedido Interno</mat-label>
              <input matInput id="idPedidoInterno" formControlName="idPedido" placeholder="Ex: 8888888" [readonly]="true">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Transportadora Intermediadora</mat-label>
              <input matInput id="transporadoraIntermediadora" formControlName="nomeTranspIntermediadora" placeholder="Ex: EM TRÂNSITO" [readonly]="true">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>ID Pedido Transp. Intermediadora</mat-label>
              <input matInput id="idPedidoTransporadoraIntermediadora" formControlName="transpIntermediadora" placeholder="Ex: 8888888" [readonly]="true">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Nome do Entregador</mat-label>
              <input matInput id="nomeEntregador" formControlName="nomeEntregador" placeholder="José Silva" [readonly]="isBloqueioTransport == true">
            </mat-form-field>
          </div>

          <div class="col-4 mt-3">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Documento</mat-label>
              <input matInput id="documento" formControlName="documento"
                mask="000.000.000-00||000.000-00||00.000.000-0" placeholder="Ex: RG ou CPF" [readonly]="isBloqueioTransport == true">
            </mat-form-field>
          </div>
          <div class="col-4 mt-3">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Placa do Veículo</mat-label>
              <input matInput id="placaVeiculo" formControlName="placaVeiculo" mask="AAA-AAAA" placeholder="Ex: CFD 2356" [readonly]="isBloqueioTransport == true">
            </mat-form-field>
          </div>
          <div class="col-4 mt-3">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Contato</mat-label>
              <input matInput id="contato" formControlName="phone" mask="(00) 0 0000-0000" placeholder="Ex: 35 9 9999-9999" [readonly]="isBloqueioTransport == true">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row form-registration mb-5">
      <div class="col-12">
        <button class="ml-4" mat-stroked-button color="default">DETALHAMENTO DO PEDIDO</button>
      </div>
    </div>
    <div style="background: #FFFFFF; border: 1px solid #8b8b8b; padding: 5px; margin: 20px;">
      <span style="font-weight: 500; color: #8b8b8b; font-size: 17px; margin-left: 20px;">Itens</span>
      <div class="col-12">
        <app-seller-panel-itens-detalhados *ngFor="let product of orderProducts" [product]="product">
        </app-seller-panel-itens-detalhados>
        <div class="row d-flex">
          <div class="col-md-3"></div>
          <div class="col-md-9">
            <mat-divider style="padding: 0.9%;"></mat-divider>
            <div class="row">
              <div class="col-md-6">
                <p style="font-size: 15px; color: #8b8b8b; text-align: left;"><strong>Total Pedido</strong></p>
              </div>
              <div class="col-md-6" style="text-align: right;">
                <p style="font-size: 15px; color: #8b8b8b; text-align: right;"><strong>{{ calcularTotal() | currency }}
                  </strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex" style="margin-top: 20px; margin-bottom: 20px;">
        <div class="col-md-3" style="text-align: left;">
          <button mat-raised-button
            id="btnSolicitarColeta"
            class="size-button"
            color="primary"
            (click)="buttonOrderCancel('solicitarcoleta')"
            [disabled]="isStatusSolicitar == true"
            style="text-transform: uppercase;  width: 280px;">Solicitar Coleta
          </button>

          <button mat-raised-button
            id="btnConfirmarRetornoColeta"
            class="size-button"
            color="primary"         
            (click)="buttonOrderCancel('retornocoleta')"
            [disabled]="isStatusRetorno == true"
            style="margin-top: 10px; text-transform: uppercase;  width: 280px;">Confirmar Retorno da Coleta
          </button>

          <button mat-raised-button
            id="btnEncerrarSolicitacao"
            class="size-button"
            color="primary"
            (click)="buttonOrderCancel('encerrarsolicitacao')"
            [disabled]="isStatusEncerrar == true"
            style="margin-top: 10px; text-transform: uppercase;  width: 280px;">Encerrar Solicitação
          </button>
        </div>

        <div class="col-md-6" style="text-align: center;">
          <button mat-raised-button
            id="btnCancelarSolicitacao"
            class="size-button"
            color="primary"         
            (click)="buttonOrderCancel('cancelarsolicitacao')"            
            [disabled]="isStatusCancelar == true"            
            style="text-transform: uppercase; width: 280px;">Cancelar Solicitação
          </button>

          <button mat-raised-button
            id="btnImprimirOrdemColeta"
            class="size-button"
            color="primary"
            (click)="imprimir()"
            style="margin-top: 10px; text-transform: uppercase; width: 280px;">Imprimir Ordem de Coleta
          </button>

          <button mat-raised-button
            id="btnSalvarSolicitacao"
            class="size-button"
            color="primary"
            (click)="cadastrarCancelSolicitation()"
            [disabled]="isStatusCadastrar == true"
            style="margin-top: 10px; text-transform: uppercase; width: 280px;">Salvar Solicitação
          </button>
        </div>

        <div class="col-md-3" style="text-align: right;">
          <button mat-raised-button
            id="btnAprovar"
            class="size-button"
            color="primary"
            (click)="buttonOrderCancel('aprovar')"
            [disabled]="isStatusAprovar == true"
            style="text-transform: uppercase; width: 200px;">Aprovar
          </button>

          <button mat-raised-button
            id="btnReprovar"
            class="size-button"
            color="warn"
            (click)="buttonOrderCancel('reprovar')"
            [disabled]="isStatusReprovar == true"
            style="margin-top: 10px; text-transform: uppercase; width: 200px;">Reprovar
          </button>
        </div>
      </div>
    </div>
  </form>
</div>


<div class="container hiddenOrcerCancel" style="width: 600px;">
  <div class="row" style="margin: 10px;">
    <div class="md-6"><strong>IMPRESSÃO ORM - CANCELAMENTO</strong></div>
  </div>
  <mat-divider style="width: 600px;"></mat-divider>
  <div class="row" style="margin: 10px;">
    <div class="md-6"><strong>DADOS DA SOLICITAÇÃO</strong></div>
  </div>
  <div class="row" style="margin: 10px;">
    <div class="col-md-3">
      <span style="font-weight: 600;">Numero: </span>
      <span>{{ numeroSolicitacao }}</span>
    </div>
    <div class="col-md-3">
      <span style="font-weight: 600;">Data: </span>
      <span>{{ dateHour }}</span>
    </div>
  </div>
  <div class="row" style="margin: 10px;">
    <div class="col-md-3">
      <span style="font-weight: 600;">Tipo: </span>
      <span>{{ requestType }}</span>
    </div>
    <div class="col-md-3">
      <span style="font-weight: 600;">Motivo: </span>
      <span>{{ reason }}</span>
    </div>
  </div>
  <div class="row" style="margin: 10px;">
    <div class="col-md-6">
      <span style="font-weight: 600;">Observações: </span>
      <span>{{ additionalNotes }}</span>
    </div>
  </div>
  <mat-divider style="width: 600px;"></mat-divider>
  <div class="row" style="margin: 10px;">
    <div class="md-6"><strong>DADOS DO PEDIDO</strong></div>
  </div>
  <div class="row" style="margin: 10px;">
    <div class="col-md-3">
      <span style="font-weight: 600;">Id Order: </span>
      <span>{{ orderNumber }}</span>
    </div>
    <div class="col-md-3">
      <span style="font-weight: 600;">Data: </span>
      <span>{{dataHora}}</span>
    </div>
  </div>
  <div class="row" style="margin: 10px;">
    <div class="col-md-3">
      <span style="font-weight: 600;">Seller: </span>
      <span>{{seller}}</span>
    </div>
    <div class="col-md-3">
      <span style="font-weight: 600;">Loja: </span>
      <span>{{ loja }}</span>
    </div>
  </div>
  <mat-divider style="width: 600px;"></mat-divider>
  <div class="row" style="margin: 10px;">
    <div class="md-6"><strong>CLIENTE</strong></div>
  </div>
  <div class="row" style="margin: 10px;">
    <div class="col-md-3">
      <span style="font-weight: 600;">Nome: </span>
      <span>{{ nome }}</span>
    </div>
    <div class="col-md-3">
      <span style="font-weight: 600;">CPF/CNPJ: </span>
      <span mask="000.000.000-00">{{cpf}}</span>
    </div>
  </div>
  <div class="row" style="margin: 10px;">
    <div class="col-md-6">
      <span style="font-weight: 600;">E-mail: </span>
      <span>{{ email }}</span>
    </div>
  </div>
  <mat-divider style="width: 600px;"></mat-divider>
  <div class="row" style="margin: 10px;">
    <div class="md-6"><strong>DADOS DA TRANSPORTADORA</strong></div>
  </div>
  <div class="row" style="margin: 10px;">
    <div class="col-md-3">
      <span style="font-weight: 600;">Nome: </span>
      <span>{{ tranportadora }}</span>
    </div>
    <div class="col-md-3">
      <span style="font-weight: 600;">ID Pedido Transp.: </span>
      <span>{{ idTransportadora }}</span>
    </div>
  </div>
  <div class="row" style="margin: 10px;">
    <div class="col-md-6">
      <span style="font-weight: 600;">Status: </span>
      <span>{{ status }}</span>
    </div>
  </div>
  <div class="row" style="margin: 10px;">
    <div class="col-md-6">
      <span style="font-weight: 600;">Nome Entregador: </span>
      <span>{{ nomeEntregador }}</span>
    </div>
  </div>
  <div class="row" style="margin: 10px;">
    <div class="col-md-3">
      <span style="font-weight: 600;">Documento: </span>
      <span>{{ documento }}</span>
    </div>
    <div class="col-md-3">
      <span style="font-weight: 600;">Placa: </span>
      <span>{{ placaVeiculo }}</span>
    </div>
  </div>
  <mat-divider style="width: 600px;"></mat-divider>
  <div class="row" style="margin: 10px;">
    <div class="md-6"><strong>ITENS</strong></div>
  </div>
  <div style="width: 600px;">
    <div class="detalhes-itens" *ngFor="let product of orderProducts">
      <div class="row d-flex">

        <div class="col-md-1"></div>
        <div class="col-md-9">
          <mat-divider style="padding: 0.9%;"></mat-divider>
          <div class="row">
            <div class="col-md-6">
              <p style="padding: 0;"><strong>{{product?.shortDescription}}</strong></p>
              <p style="font-weight: 200; padding: 0;">SKU {{product?.sku}}</p>
            </div>
            <div class="col-md-6" style="text-align: right;">
              <p style="font-weight: 200; padding: 0;">{{product?.quantity}} unid</p>
              <p style="font-weight: 200; padding: 0;">{{product?.unitPrice | currency}}</p>
              <p><strong>{{product?.total | currency}}</strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-divider style="width: 600px;"></mat-divider>
  <div class="container" style="width: 600px">
    <div class="row">
      <div class="col-md-3">
        <span style="font-size: 15px; color: #8b8b8b; margin-left: 20px; text-align: left;"><strong>Total Pedido</strong></span> 
      </div>
      <div class="col-md-3">
        <span style="font-size: 15px; color: #8b8b8b; margin-left: 50%;"><strong>{{ calcularTotal() | currency }}</strong></span>
      </div>
    </div>
  </div>
  <mat-divider style="width: 600px;"></mat-divider>
</div>