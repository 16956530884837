import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Orders, Telemetria } from '../pages/seller-panel/seller-panel.model';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class PerdidosService {

    constructor(
        private http: HttpClient,
        private handler: HttpBackend,
        private authService: AuthService,
        private storage: StorageService
    ) { }

    public getAllPedidos(): Observable<any> {
        
        return this.http.get<Orders[]>(`${environment.BASE_URL}/api/gridorders`, {
            headers: this.getNewHeaders()
        });
    }

    getNewHeaders() {
        
        return new HttpHeaders({
           apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
           profile: this.storage.storage.getItem('key')
        });
    }

    getSlaConfiguration(tipoEntrega){
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.storage.storage.getItem('key'),
            deliveryType: tipoEntrega
        });
        return this.http.get(`${environment.BASE_URL}/api/sla/deliverytypetime`, {
            headers: headers
        });
    }

    public filterByOrderName(filterMethod: string, value: string): Observable<any> {
                
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.authService.profile,          
            deliveryType: 'Ultra FAST',    
            filterMethod: filterMethod,
            value: value
        });
        return this.http.get(`${environment.BASE_URL}/api/ordersmallfilter`, {
            headers: headers
        });
    }

    public filterByOrderComplet(sellerFilter, storeFilter, dataInicial, dataFinal, statusFilter) {
        
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.storage.storage.getItem('key'),
            deliveryType: 'Ultra FAST',
            seller: sellerFilter,
            store: storeFilter,
            dataInit: dataInicial,
            dataFini: dataFinal,
            status: statusFilter
        })

        return this.http.get(`${environment.BASE_URL}/api/filter`, {
            headers: headers
        });
    }

    public getDetalhesPedido(idPedido: string): Observable<any> {
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            cpf: "37351792828",
            tokencpf: "Bearer 3ImZeZEMFsHTv2nwkq1zpWuZnsav",
            order: idPedido,
            token: "x6CBUaYUCApbiEBH396IUAh0D9hp",
            seller: "",
            idstore: "Fas01",
            profile: "FSADMINISTRADOR"
        });

        return this.http.get(`${environment.BASE_URL}/api/gridorderdetail`, {
            headers: headers
        });
    }

    public getTelemetry(seller, store, dataInit, dataFinit, status, checkOrigin): Observable<any> {
        
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.storage.storage.getItem('key'),
            seller: seller,
            store: store,
            dataInit:dataInit,
            dataFini: dataFinit,
            status: status
        });

        if( checkOrigin === "currentLoad" ){            
            return this.http.get<Telemetria[]>(`${environment.BASE_URL}/api/telemetry/gridorders`, {
                headers: headers
            });
        }
        if( checkOrigin === "filterLarger" ) {
            return this.http.get<Telemetria[]>(`${environment.BASE_URL}/api/telemetry/filter`, {
                headers: headers
            });
        }
        
    }

    //Função utilizada no pop-up data e hora da entrega
    public informDeliveryRequest(orderNumber: any, number: any, date: any) {
     
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.authService.profile
        })
                
        orderNumber.profile = {
            orderNumber: orderNumber.numeroOrder,
            description: number,
            dateTimeDelivery: date
        };
        
        return this.http.put(`${environment.BASE_URL}/api/timeline`, orderNumber.profile, {
            headers: headers
        });
    }

    public informDescripition(orderNumber: any, number: any, description: any){
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.authService.profile
        })  
        
        orderNumber.profile = {
            orderNumber: orderNumber.numeroOrder,
            description: number,
            reason: description
        };
        
        return this.http.put(`${environment.BASE_URL}/api/cancellationreason`, orderNumber.profile, {
            headers: headers
        });
    }

    public editStatus(userForm: any, number: any, transportForm: any) {
        let order = userForm.orderNumber;
        
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.storage.storage.getItem('key')
        });

        userForm.profile = {
            orderNumber: order,
            description: number,            
            fullName: transportForm.nomeEntregador,
            document: transportForm.documento,
            phone: transportForm.telefone,
            velichePlate: transportForm.placaVeiculo            
        };
        
        return this.http.put(`${environment.BASE_URL}/api/timeline`, userForm.profile, {
            headers: headers
        });
    }

    public filterLoad(): Observable<any> {
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.storage.storage.getItem('key'),
            deliveryType: "Ultra FAST"
        });

        return this.http.get(`${environment.BASE_URL}/api/filterload`, {
            headers: headers
        });
    }

    public exportExcel(seller, store, dataInit, dataFinit, status){        
        
        const headers = new HttpHeaders({
            deliveryType: 'Ultra FAST',
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.authService.profile,
            seller: seller,
            store: store,
            dataInit:dataInit,
            dataFini: dataFinit,
            status: status
        });
        
        return this.http.get(`${environment.BASE_URL}/api/orderreport`,{
            headers: headers,
            responseType: 'blob' as 'json'
        });
    }

    checkingcopy(loggiOrder, transportPackageOrderPk){
        
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.authService.profile
        });
        
        loggiOrder.body = {
            loggiOrderPk: transportPackageOrderPk
        }
        
        return this.http.post(`${environment.BASE_URL}/api/checkingcopy`, loggiOrder.body, {
            headers : headers
        });
    }

    loggitrackorder(loggiOrder){
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.authService.profile
        });        

        loggiOrder.body = {
            loggiOrderPk: loggiOrder.loggiOrderPk
        }

        return this.http.post(`${environment.BASE_URL}/api/loggitrackorder`, loggiOrder.body, {
            headers : headers
        });
    }

    public validarTransport(transport){
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.authService.profile,
            name : transport
        });
        
        return this.http.get(`${environment.BASE_URL}/api/transport/name`, {
            headers: headers
        });
    }

    public validarTransportByCode(transportCode){
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.authService.profile,
            code : transportCode
        });
        
        return this.http.get(`${environment.BASE_URL}/api/transport/code`, {
            headers: headers
        });
    }

    public createDataTranport(transportForm : any, numberOrder){

        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile : this.authService.profile,
            deliveryType: 'Ultra FAST'
        });
        
        transportForm.profile = {
            orderNumber: numberOrder,
            fullName: transportForm.nomeEntregador,
            document: transportForm.documento,
            velichePlate: transportForm.placaVeiculo,
            phone: transportForm.telefone,
            nameClerk: transportForm.nomeAtendente
        };
        
        return this.http.put(`${environment.BASE_URL}/api/setdeliveryman`, transportForm.profile, {
            headers: headers
        });
    }

    public getStageStatus(status){

        const httpClient = new HttpClient(this.handler);

        status = {
            slaStatus: status
        };       
        
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile : this.authService.profile
        });

        return httpClient.post(`${environment.BASE_URL}/api/sla/getslatime`, status, {
            headers : headers
        });
    }

    updateOrderByOrder(pedido){
        
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.authService.profile
        });
        
        pedido = {
            orderNumber: pedido
        };

        return this.http.post(`${environment.BASE_URL}/api/updateoneorder`, pedido, {
            headers : headers
        });
    }

    uploadXmlOrder(order, arqXml){        

        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile : this.authService.profile
        });

        order.profile = {
            orderNumber: order.orderNumber,
            xmlUpload: arqXml
        }

        return this.http.post(`${environment.BASE_URL}/api/xmlUpload`, order.profile, {
            headers : headers
        });
    }
}