import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn:'root'
})
export class AuthService{

    public token:string;
    public email:string;
    public profile:string;
    public currentUser:string;
    public profileSeller: string;

    constructor(){
        if(environment.production){
            const storage = window.localStorage;
            this.profile = storage.getItem('key');
            this.profileSeller = storage.getItem('profile');
        }
        else{
            this.profile = "eyJraWQiOiJHVkJ3OUxVMGpSdlpUNWM1OUtycTJ3UlVGWlZzRjFVOWlyd0RLWHJIc05FPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI5MDc3MTFiYy01MjdiLTQwZjYtYWUwNy1kNDZhOTg0N2U0NjgiLCJjb2duaXRvOmdyb3VwcyI6WyJmYXN0c2hvcC5hZG1pbi5jYWRhc3Ryb3NlbGxlciJdLCJldmVudF9pZCI6Ijg2MmY2NzVkLWE3MDQtNGI1Zi04MGY2LTJhNTE4ZGIzYjYzYiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2MTMzMDc0MjksImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX1Iya2VMSE14UiIsImV4cCI6MTYxMzMxMTAyOSwiaWF0IjoxNjEzMzA3NDI5LCJqdGkiOiJkZjU5MDBhYy0zOWNkLTRmMDMtYmE1ZC1iMzExMWNiM2U3MDIiLCJjbGllbnRfaWQiOiI2YTNkdDdzZzFwZjV0YXVoM2c3dTNvb3J0MyIsInVzZXJuYW1lIjoiZmFzdHNob3AuYWRtaW4uY2FkYXN0cm9zZWxsZXJAbWFpbGluYXRvci5jb20ifQ.M83zAqNuYKH60lSFbJ94rhH_0QgDpHqdK1HuROtajD12qwTplBrVQ1sRXLmAYJQRwkQxp_fkRFslTmVc74Xk9tE6aoy4OdAcEMsBKKC46TK3svozqGqEOwDQctSkQt7zFk-F9kKbJAA1U_Szl__IzByi-Z8feKYUiYh0ULnexOtp0hhywyAcmqtuF4TOCORMxhM9EmQVRx_v6rfjKYWWcnNdVqYS6JVaqpZutxzaqZK0FRSxicUhhSmS_htqkBzlBlD4968eZnzTjy7YwJq0qo6LTGIvTmf_nzpMzNnRK3xDtHRPDFwL-Lpu4jRTRJ6wXAL4oIdrPtWiK_czLoRAxw";
        }
    }
}