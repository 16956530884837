import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-filtro-buscar-pedidos',
  templateUrl: './filtro-buscar-pedidos.component.html',
  styleUrls: ['./filtro-buscar-pedidos.component.scss']
})
export class FiltroBuscarPedidosComponent implements OnInit {

  formFiltroPedidos: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<FiltroBuscarPedidosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.formFiltroPedidos = this.formBuilder.group({
      numeroPedido: [''],
      nomeCliente: ['']
    });
  }

  clear(){
    this.formFiltroPedidos.reset();
  }

  save() {
    this.data.sair = true;
    this.data.filtroNomePedido = {
      numeroPedido: this.formFiltroPedidos.get('numeroPedido').value,
      nomeCliente: this.formFiltroPedidos.get('nomeCliente').value      
    }
    this.dialogRef.close(this.data);
  }

  close(event: Event) {
    event.preventDefault();
    this.dialogRef.close();
  }
}
