<form action="" [formGroup]="formFiltro" class="painel-seller-filter">
  <div class="container-fluid">

    <div class="row header d-flex justify-content-between sticky-top">
      <div class="col-6">
        <mat-icon aria-hidden="false" aria-label="Example home icon">filter_alt</mat-icon>
        <h2 id="tituloFiltro"><strong>Filtros</strong></h2>
      </div>
      <div style="display: flex; align-items: center;">
        <button id="btnLimpar" mat-stroked-button (click)="clear()" style="width: 150px; height: 40px;">Limpar</button>
        <a (click)="close($event)">
          <mat-icon id="iconeFechar" aria-hidden="false" aria-label="Example home icon" style="margin-left: 10px; cursor: pointer;">close_alt</mat-icon>
        </a>
      </div>
    </div>
    <div class="row" style="padding: 0 20px 20px 20px;">
      <div class="col-6">
        <span>STATUS: <strong>ATIVO</strong></span>
      </div>
    </div>

    <div class="row">
      <mat-form-field appearance="outline" style="background-color: transparent;">
        <mat-label>Seller</mat-label>
        <mat-select id="comboSeller" formControlName="seller" [disabled]="isEditSeller == true" (selectionChange)="selecionarMKT($event.value)">
          <mat-option *ngFor="let seller of sellerArray" value="{{seller.seller}}">
            {{seller.seller}}
          </mat-option>
          <mat-option *ngIf="profile === 'sellerLoja'" value="{{ isSeller }}">{{ isSeller }}</mat-option>
          <mat-option *ngIf="profile === 'selleradmin'" value="{{ isSeller }}">{{ isSeller }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field appearance="outline" style="background-color: transparent;">
        <mat-label>Web Store</mat-label>
        <mat-select id="comboWebStore" formControlName="webStore" [disabled]="isEditMkt == true" (selectionChange)="selecionarMKTTT()" [value]="editingUser?.webStore">
            <mat-option *ngFor="let mktPlace of mktPlaceList" value="{{mktPlace.webstoreID}}">
                {{mktPlace.webstoreID}}
            </mat-option>
            <mat-option *ngIf="profile === 'sellerloja'" value="{{ isWebStore }}">{{ isWebStore }}</mat-option>
            <mat-option *ngIf="profile === 'selleradmin'" value="{{ isWebStore }}">{{ isWebStore }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field appearance="outline" style="background-color: transparent;">
        <mat-label>Lojas</mat-label>
        <mat-select id="comboStore" formControlName="store" [disabled]="isEditStore == true" [value]="editingUser?.store"> 
            <mat-option *ngFor="let store of storeList" value="{{store.idStore}}">
                {{store.idStore }} - {{store?.store}}
            </mat-option>
            <mat-option *ngIf="profile === 'sellerloja'" value="{{ isStoreId }}">{{ isStore }}</mat-option>
        </mat-select>
    </mat-form-field>
    </div>
    <div class="row">
      <div class="col-4 pl-0">
          <input style="text-align: left; margin-top: 20px;" matInput value="Data de Emissão de Pedidos:" [disabled]="true">
      </div>
      <div class="col-4 pl-0">
        <mat-form-field appearance="outline">
          <mat-label>Data Inicio</mat-label>
          <input matInput id="dataInicio" type="date" placeholder="01/01/2020" formControlName="dataInicio">
        </mat-form-field>
      </div>
      <div class="col-4 pl-0 pr-0">
        <mat-form-field appearance="outline">
          <mat-label>Data Fim</mat-label>
          <input matInput id="dataFim" type="date" placeholder="02/01/2020" formControlName="dataFim" (focusout)="validarData($event)">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select id="comboStatus" formControlName="status" [disabled]="isEdit == true">
          <mat-option *ngFor="let i of status" id="optTextField" value="{{i.id}}">{{ i.value }}</mat-option>
          <mat-option id="optTextField" value="">Todos</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Pendências</mat-label>
        <mat-select id="comboPendencias" formControlName="pendencias">
          <mat-option *ngFor="let i of pendencias" id="optTextField" value="{{i.value}}">{{ i.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row d-flex justify-content-end">
      <button id="btnCancelar" class="mat-raised-button m-1" mat-button color="default" (click)="cancel()">Cancelar</button>
      <button id="btnConfirmar" class="mat-raised-button m-1" mat-button color="primary" (click)="save()">Confirmar</button>
    </div>

  </div>
</form>