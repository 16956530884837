import { IsLoadingService } from './../../services/is-loading.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(
    private loaderService: IsLoadingService
  ) {}

  removeRequest(req: HttpRequest<any>) {
    const index = this.requests.indexOf(req);
    this.requests.splice(index, 1);
    const haveAnyRequest = this.requests.length > 0;
    this.loaderService.setLoadState(haveAnyRequest);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requests.push(request);
    this.loaderService.setLoadState(true);

    return new Observable((observer) => {
      const subscription = next
        .handle(request)
        .pipe(timeout(30000))
        .subscribe(
          (event) => {
            if (event instanceof HttpResponse) {
              observer.next(event);
            }
          },          
          () => {
            observer.complete();
          }
        )
        .add(() => this.removeRequest(request));
      return () => {
        subscription.unsubscribe();
      };
    });
  }
}