import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  public isMobile: boolean;
  public isMenuOpened: boolean;
  @Output() toggleMenuEvent : EventEmitter<boolean> = new EventEmitter<boolean>()

  toggleMenu(e){
    this.isMenuOpened = !this.isMenuOpened;
    this.toggleMenuEvent.emit(this.isMenuOpened);
  }

}
