import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ErrorMessageComponent } from "./error-message.component";

export enum MessageTypeEnum {
    warning = 1,
    error,
    success,
  }
  
  export interface MessageDataI {
    code?: number;
    messageCode?: string;
    warning?: boolean;
    message?: string;
    title?: {
      text?: string;
      icon: string;
    };
    type?: MessageTypeEnum;
  }
  
  @Injectable({
    providedIn: 'root',
  })
  export class ErrorMessageService {
    constructor(private dialog: MatDialog, private snackbar: MatSnackBar) {}
  
    openDialog(data: MessageDataI) {
      return this.dialog.open(ErrorMessageComponent, {
        width: '600px',
        data,
      });
    }
  
    openSnackBar(data: MessageDataI) {
      this.snackbar.openFromComponent(ErrorMessageComponent, {
        data,
      });
    }
}