import { Component, Input } from '@angular/core';
import { Product } from '../seller-panel.model';

@Component({
  selector: 'app-seller-panel-itens-detalhados',
  templateUrl: './seller-panel-itens-detalhados.component.html',
  styleUrls: ['./seller-panel-itens-detalhados.component.scss']
})
export class SellerPanelItensDetalhadosComponent {

  @Input() product : Product;

}
