<div class="style-registration">
    <div class="style-registration__header">
        <div class="row">
            <div class="col-8">
                <h2 id="titleEmpresa">Lojas Cadastradas</h2>
            </div>
            <div class="col-4">
                <button mat-raised-button id="btnNovaStore" color="primary" style="text-align: right;" [routerLink]="'/cadastro-loja'">CRIAR NOVA LOJA</button>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="col-md-12 pt-2 pr-5">
        <div class="content-table">
            <table mat-table id="gridLojas" [dataSource]="dataSource" class="mat-elevation-z0">
                <caption style="display: none;">Resultados de todas as lojas cadastradas</caption>
                <ng-container matColumnDef="seller">
                    <th id="seller" mat-header-cell *matHeaderCellDef> Seller </th>
                    <td mat-cell *matCellDef="let element"> {{ element.seller }} </td>
                </ng-container>
                <ng-container matColumnDef="webStore">
                    <th id="webStore" mat-header-cell *matHeaderCellDef> Web Store </th>
                    <td mat-cell *matCellDef="let element"> {{ element.webStore }} </td>
                </ng-container>
                <ng-container matColumnDef="idStore">
                    <th id="loja" mat-header-cell *matHeaderCellDef> Loja </th>
                    <td mat-cell *matCellDef="let element"> {{ element.idStore }} </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th id="descricao" mat-header-cell *matHeaderCellDef> Descrição </th>
                    <td mat-cell *matCellDef="let element"> {{ element.store }} </td>
                </ng-container>
                <ng-container matColumnDef="cnpj">
                    <th id="cnpj" mat-header-cell *matHeaderCellDef> CNPJ </th>
                    <td mat-cell *matCellDef="let element"> {{ element.cnpj | mask: '00.000.000/0000-00' }} </td>
                </ng-container>
                <ng-container matColumnDef="acoes">
                    <th id="acoes" mat-header-cell *matHeaderCellDef> Ações </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-raised-button
                            id="btnEditar"
                            color="primary"
                            [routerLink]="['/cadastro-loja']"
                            [state]="{user:element,edit:true}">Editar
                        </button>&nbsp;&nbsp;
                        <button mat-raised-button
                            id="btnDeletar"
                            (click)="openModalDialogDeleteLoja(element)"
                            color="warning">Deletar
                        </button>
                    </td>
                </ng-container>
                <tr class="mat-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>