import { Component, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SlaInfo } from "src/app/pages/status-sla/SlaInfo.model";
import { SlaService } from "src/app/services/sla.service";
import { ErrorMessageService } from "../error-menssage/error-message.service";


@Component({
    selector: 'app-sla-status-contato',
    templateUrl: './delete-sla-status.component.html',
    styleUrls: ['./delete-sla-status.component.scss']
})
export class DeleteSlaStatusComponent {

    public selectedSlaInfo: SlaInfo = null;

    constructor(
        @Optional() private dialogRef: MatDialogRef<DeleteSlaStatusComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { 
            tipoEntrega,
            status,
            slaMin,
            id,
            selectDeliveryType,
            STATUS   },
        private slaService: SlaService,
        private errorMessageService: ErrorMessageService
    ) { }

    // Fecha formulario
    close() {
        this.dialogRef.close();
    }

    deleteSlaStatus() {
        this.slaService.deleteSlaStatus(
            new SlaInfo(
                this.data.tipoEntrega,
                this.data.slaMin,
                this.data.id,
                this.data.status,
            ), this.data.selectDeliveryType, this.data.STATUS
          ).subscribe(() => {
            this.errorMessageService.openDialog({
                message: 'SUCESSO',
                messageCode: 'Sla status deletado com sucesso.'
            });
            this.dialogRef.close(true);
          });
    }
}