<div class="painel-seller-modal">
  <div class="container-fluid">
    <div class="row header d-flex justify-content-between">
      <div>
        <h2><strong>Linha do Tempo</strong></h2>
      </div>
      <mat-icon aria-hidden="false" aria-label="Fechar" (click)="close()" alt="Sair" style="cursor: pointer;">close</mat-icon>
    </div>
    <div class="row">
      <div class="painel-seller-timeline">
        <div class="painel-seller-timeline__item"
          *ngFor="let s of timeLineItems;let i = index">
          <div class="content">
            <div class="step">{{i + 1}}</div>
            <span>{{s.dateTime}} -</span>
            <span>{{s.description}}</span>
          </div>
          <div class="line"></div>
          <mat-icon aria-hidden="false" aria-label="Próximo">arrow_drop_down</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
