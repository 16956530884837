<div class="style-registration">
    <div class="style-registration__header">
        <div class="col-8">
            <h2 id="titleEmpresa">Solicitação Troca/Cancelamento</h2>
        </div>
        <div class="col-4">
            <button mat-raised-button id="btnNovaSolicitacao" color="primary" (click)="checkProfile()" [disabled]="isStatusDisable == true"
                [routerLink]="'/solicitacao-troca-cancelamento'">
                ABRIR NOVA SOLICITAÇÃO
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
    <form [formGroup]="gridsolicitacaoTrocaForm">
        <div class="container-fluid mt-4">
            <div class="row px-4">

                <mat-form-field class="col-2" appearance="outline" style="background-color: transparent;">
                    <mat-label>Seller</mat-label>
                    <mat-select id="comboSeller" formControlName="seller" [disabled]="isEditSeller == true" (selectionChange)="selecionarMKT($event.value)">
                        <mat-option *ngFor="let seller of sellerArray" value="{{seller.seller}}">
                            {{seller.seller}}
                        </mat-option>
                        <mat-option *ngIf="profile === 'sellerloja'" value="{{ isSeller }}">{{ isSeller }}</mat-option>
                        <mat-option *ngIf="profile === 'selleradmin'" value="{{ isSeller }}">{{ isSeller }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-2" appearance="outline" style="background-color: transparent;">
                    <mat-label>Web Store</mat-label>
                    <mat-select id="comboWebStore" formControlName="webStore" [disabled]="isEditMkt == true" (selectionChange)="selecionarMKTTT()"
                        [value]="editingUser?.webStore">
                        <mat-option *ngFor="let mktPlace of mktPlaceList" value="{{mktPlace.webstoreID}}">
                            {{mktPlace.webstoreID}}
                        </mat-option>
                        <mat-option *ngIf="profile === 'sellerloja'" value="{{ isWebStore }}">{{ isWebStore }}</mat-option>
                        <mat-option *ngIf="profile === 'selleradmin'" value="{{ isWebStore }}">{{ isWebStore }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-2" appearance="outline" style="background-color: transparent;">
                    <mat-label>Lojas</mat-label>
                    <mat-select id="comboStore" formControlName="store" [disabled]="isEditStore == true" [value]="editingUser?.store">
                        <mat-option *ngFor="let store of storeList" value="{{store.idStore}}">
                            {{store.idStore }} - {{store?.store}}
                        </mat-option>
                        <mat-option *ngIf="profile === 'sellerloja'" value="{{ isStoreId }}">{{ isStore }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="col-2" appearance="outline">
                    <mat-label>Data Inicial</mat-label>
                    <input matInput type="date" id="dataInicial" formControlName="dataInicial" placeholder="01/01/2020" >
                </mat-form-field>

                <mat-form-field class="col-2" appearance="outline">
                    <mat-label>Data Final</mat-label>
                    <input matInput type="date" id="dataFinal" formControlName="dataFinal" placeholder="01/01/2020"
                        (focusout)="validarData($event)">
                </mat-form-field>

                <mat-form-field class="col-2" appearance="outline">
                    <mat-label>Status</mat-label>
                    <mat-select id="comboStatus" formControlName="status" [disabled]="isEdit == true">
                        <mat-option *ngFor="let i of status" id="optTextField" value="{{i.id}}">{{ i.value }}
                        </mat-option>
                        <mat-option value="">Todas</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="content-filter">
        <div class="content-filter__title">
            <mat-icon aria-hidden="false" aria-label="Example home icon">
                filter_alt</mat-icon>
            <h3>Filtro de Pedidos Cancelados</h3>
        </div>
        <div class="content-filter__buttons">
            <button mat-stroked-button id="btnAtualizar" (click)="buscarGridPersonalizado()">Atualizar</button>
            <button mat-stroked-button id="btnFiltroBuscar" (click)="filterCancelamento()">Buscar</button>
        </div>
    </div>
    <div class="col-md-12 pt-2 pr-5">
        <div class="content-table">
            <table mat-table id="gridOrderCancel" [dataSource]="dataSource" matSort matSortActive class="mat-elevation-z0">
                <caption style="display: none;">Resultados de todos pedidos que estão com uma solicitação de troca ou cancelamento em aberto.</caption>
                <ng-container matColumnDef="storeName">
                    <th id="seller" mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </th>
                    <td mat-cell *matCellDef="let element"> {{element.seller}} </td>
                </ng-container>
                <ng-container matColumnDef="orderCancel">
                    <th id="orderCancel" mat-header-cell *matHeaderCellDef mat-sort-header> Nº Solicitação </th>
                    <td mat-cell *matCellDef="let element"> {{element.orderCancel}} </td>
                </ng-container>
                <ng-container matColumnDef="orderNumber">
                    <th id="orderNumber" mat-header-cell *matHeaderCellDef mat-sort-header> Pedido </th>
                    <td mat-cell *matCellDef="let element"> {{element.orderNumber}} </td>
                </ng-container>
                <ng-container matColumnDef="orderCanceldateHour">
                    <th id="orderCancelDateHour" mat-header-cell *matHeaderCellDef mat-sort-header> Data e hora </th>
                    <td mat-cell *matCellDef="let element"> {{element.orderCanceldateHour}} </td>
                </ng-container>
                <ng-container matColumnDef="transport">
                    <th id="transport" mat-header-cell *matHeaderCellDef mat-sort-header> Transportadora </th>
                    <td mat-cell *matCellDef="let element"> {{element.transport}} </td>
                </ng-container>
                <ng-container matColumnDef="storeId">
                    <th id="storeId" mat-header-cell *matHeaderCellDef mat-sort-header> Loja/Filial </th>
                    <td mat-cell *matCellDef="let element"> {{element.storeId}} - {{element.storeName}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th id="lastTimeLineDescription" mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let element"> {{ element.lastTimeLineDescription }} </td>
                </ng-container>
                <ng-container matColumnDef="requestType">
                    <th id="requestType" mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Solicitação </th>
                    <td mat-cell *matCellDef="let element"> {{element.requestType}} </td>
                </ng-container>
                <tr class="mat-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="cursor: pointer;"
                    [routerLink]="['/solicitacao-troca-cancelamento']" [state]="{orderCancel:row,edit:true}"></tr>
            </table>
        </div>
    </div>
</div>