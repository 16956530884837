import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetalhamentoPedidoComponent } from './pages/detalhamento-pedido/detalhamento-pedido.component';
import { GridTrocaCancelamentoComponent } from './pages/grid-troca-cancelamento/grid-troca-cancelamento.component';
import { SellerPanelComponent } from './pages/seller-panel/seller-panel.component';
import { SolicitacaoTrocaCancelamentoComponent } from './pages/solicitacao-troca-cancelamento/solicitacao-troca-cancelamento.component';
import { TipoEntregaComponent  } from './pages/tipo-entrega/tipo-entrega.component';
import {  StatusSlaComponent } from './pages/status-sla/status-sla.component';
import { CadastrarLojasSellerComponent } from './pages/cadastrar-lojas-seller/cadastrar-lojas-seller.component';
import { ParametrizacaoTransportadoraComponent } from './pages/parametrizacao-transportadora/parametrizacao-transportadora.component';
import { CadastroPerfilUsuarioComponent } from './pages/cadastro-perfil-usuario/cadastro-perfil-usuario.component';
import { GridPerfilUsuarioComponent } from './pages/grid-perfil-usuario/grid-perfil-usuario.component';
import { GridLojasComponent } from './pages/grid-lojas/grid-lojas.component';

const routes: Routes = [
  { path: "solicitacao-troca-cancelamento", component: SolicitacaoTrocaCancelamentoComponent },
  { path: "grid-cancelamento-troca", component: GridTrocaCancelamentoComponent },
  { path: "detalhamento-pedido", component: DetalhamentoPedidoComponent },
  { path: "sla-tipo-entrega", component: TipoEntregaComponent },
  { path: "sla-status", component: StatusSlaComponent },
  { path: "cadastro-loja", component: CadastrarLojasSellerComponent },
  { path: "parametrizacao-transportadora", component: ParametrizacaoTransportadoraComponent },
  { path: "grid-lojas", component: GridLojasComponent },
  { path: "cadastro-perfil-usuario", component: CadastroPerfilUsuarioComponent },
  { path: "cadastro-perfil-usuario/:id", component: CadastroPerfilUsuarioComponent },
  { path: "grid-perfil-usuario", component: GridPerfilUsuarioComponent },
  { path: "painel-vendas", component: SellerPanelComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
