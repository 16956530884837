import { Component, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Parametrizacao } from "src/app/pages/parametrizacao-transportadora/parametrizacao-transportadora.model";
import { ParametrizacaoService } from "src/app/services/parametrizacao.service";
import { ErrorMessageService } from "../error-menssage/error-message.service";

@Component({
    selector: 'app-transportadora-contato',
    templateUrl: './delete-transportadora.component.html',
    styleUrls: ['./delete-transportadora.component.scss']
})
export class DeleteTransportadoraComponent {

    constructor(
        @Optional() private dialogRef: MatDialogRef<DeleteTransportadoraComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            codigo,
            nome,
            apelido,
            id,
            alias
        },
        private parametrizacaoService : ParametrizacaoService,
        private errorMessageService: ErrorMessageService
    ) { }

    selectedTranportInfo: Parametrizacao = null;

    // Fecha formulario
    close() {
        this.dialogRef.close();
    }

    deleteTransportadora() {
        this.parametrizacaoService.deleteTransportadora(
            new Parametrizacao(
                this.data.codigo,
                this.data.nome,
                this.data.apelido,
                this.data.id,
            ), this.data.alias
        ).subscribe(() => {
            this.errorMessageService.openDialog({
                message: 'SUCESSO',
                messageCode: 'Configuração de transportadora deletada com sucesso.'
            });
            this.dialogRef.close(true);
        });
    }
}