import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PerdidosService } from 'src/app/services/pedidos.service';
import { DetalhamentoPedido, Transporte } from './detalhamento-pedido.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DeliveryMan, Orders, Product } from '../seller-panel/seller-panel.model';
import { DataHoraEntregaComponent } from './pop-up/data-hora-entrega/data-hora-entrega.component';
import { MotivoCancelamentoComponent } from './pop-up/motivo-cancelamento/motivo-cancelamento.component';
import { Comprovante } from './pop-up/comprovante-entrega/comprovante.model';
import { ErrorMessageService } from './../../shared/error-menssage/error-message.service';
import Timeline from '../../shared/Timeline';
import { CancelOrderService } from 'src/app/services/cancel-order.service';
import { CheckProfile } from '../grid-troca-cancelamento/troca-cancelamento.model';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

@Component({
  selector: 'app-detalhamento-pedido',
  templateUrl: './detalhamento-pedido.component.html',
  styleUrls: ['./detalhamento-pedido.component.scss']
})
export class DetalhamentoPedidoComponent implements OnInit {

  private state$: Observable<object>;
  public timeline: string[] = Timeline;

  //Constante
  SELLER_FASTSHOP: string = "FASTSHOP";

  detalhamentoPedidoForm: FormGroup;
  currentDetalhamentoPedito: DetalhamentoPedido;
  idPedidoAtual: string;
  currentOrder: Orders;
  currentOrderLast: Orders;
  currentComprovante: Comprovante;
  currentDeliveryMan: DeliveryMan[] = [];
  currentTransport: Transporte;
  orderProducts: Product[] = [];
  statusOrderCancel: string;
  checkProfileDetail: CheckProfile;
  checkProfileUser: any;
  transportPackageOrderPk: string = "";  
  file: File;
  colorButton = "";

  //Ações nos botões
  isStatusMapa: boolean = true;
  isStatusComprovante: boolean = true;
  isStatusEntrega: boolean = true;
  isStatusColeta: boolean = true;
  isStatusExpedicao: boolean = true;
  isStatusMotivo: boolean = true;
  isStatusConfirmar: boolean = true;
  isStatusRejeitar: boolean = true;
  isStatusSalvar: boolean = true;
  isUploadXml: boolean = true;
  isEdit: boolean = true;
  isClerk: boolean = false;

  constructor(
    private fb: FormBuilder,
    private pedidoService: PerdidosService,
    private cancelOrderService: CancelOrderService,
    route: ActivatedRoute,
    private router: Router,
    private errorMessageService: ErrorMessageService,
    public dialog: MatDialog
  ) {
    this.state$ = route.paramMap
      .pipe(map(() => window.history.state));
  }

  ngOnInit(): void {

    this.carregaFormulario();

    //Checando o perfil logado no sistema
    this.checkProfileOrderDetail();

    this.state$.subscribe((nav: any) => {
      this.currentOrder = nav.order as Orders;

      this.pedidoService.updateOrderByOrder(this.currentOrder?.orderNumber).subscribe((response) => {
        this.currentOrderLast = response as Orders

        if (response != null) {
          this.checkCarrier();
          this.loadOrderDetails();

          //Busca o último status da timeLine
          this.statusOrderCancel = this.currentOrderLast.lastTimeLineDescription;

          //Ativando e desativando botões conforme status
          this.activeButton(this.statusOrderCancel);

          //habilitar botão Upload de XML para pedidos 3P
          this.checkButtonUpload();

          //Desativando campo Atendente caso estiver nos status Entregue ou Cancelado
          this.checkInputNameClerk();

          this.orderProducts = this.currentOrder.products;
        }
      });
    });    
  }

  carregaFormulario() {

    this.detalhamentoPedidoForm = this.fb.group({
      nomeCliente: [''],
      cpf: [''],
      email: [''],
      venda: [''],
      data: [''],
      seller: [''],
      loja: [''],
      nota: [''],
      nomeAtendente: [''],
      transporte: [''],
      idPedido: [''],
      idPedidoTransportadora: [''],
      status: [''],
      nomeTranspIntermediadora: [''],
      transpIntermediadora: [''],
      nomeEntregador: [''],
      documento: [''],
      placaVeiculo: [''],
      telefone: ['']
    });

  }

  loadOrderDetails() {
    var idPedidoTransp = '';
    var idPedidoInterno = '';

    if (this.currentOrderLast.transport == 'LOGGI TECNOLOGIA LTDA') {
      idPedidoTransp = this.currentOrderLast?.loggiOrderPk;
      idPedidoInterno = this.currentOrderLast?.loggiOrderPk;
    } else {
      idPedidoTransp = this.currentOrderLast?.CConnectorExternalRequestID;
      idPedidoInterno = this.currentOrderLast?.CConnectorRequestID;
    }
    
    this.detalhamentoPedidoForm = this.fb.group({
      nomeCliente: [this.currentOrderLast?.name],
      cpf: [this.currentOrderLast?.cpf],
      email: [this.currentOrderLast?.email],
      venda: [this.currentOrderLast?.orderNumber],
      data: [this.currentOrderLast?.dateHour],
      seller: [this.currentOrderLast?.seller],
      loja: [this.currentOrderLast?.storeName],
      nota: [this.currentOrderLast?.nfeSerie],
      nomeAtendente: [this.currentOrderLast?.nameClerk],
      transporte: [this.currentOrderLast?.transport],
      idPedido: [idPedidoInterno],
      idPedidoTransportadora: [idPedidoTransp],
      status: [this.currentOrderLast?.loggiStatus],
      nomeTranspIntermediadora: [this.currentOrderLast?.nomeTranspIntermediadora],
      transpIntermediadora: [this.currentOrderLast?.transpIntermediadoraOrderNumber],
      nomeEntregador: [this.currentOrderLast.deliveryMan?.fullName],
      documento: [this.currentOrderLast.deliveryMan?.document],
      placaVeiculo: [this.currentOrderLast.deliveryMan?.velichePlate],
      telefone: [this.currentOrderLast.deliveryMan?.phone]
    });    
  }

  checkCarrier() {
    this.pedidoService.validarTransportByCode(this.currentOrder.carrierCode).subscribe((response: Transporte) => {
      this.currentTransport = response

      if (this.currentTransport?.manualDeliveryManInfo) {
        this.isEdit = false;
        this.isStatusSalvar = false;
      }
    });
  }

  salvarTransportadora() {

    this.pedidoService.createDataTranport(this.detalhamentoPedidoForm.value, this.currentOrder.orderNumber).subscribe((response: any) => {

      this.errorMessageService.openDialog({
        message: 'SUCESSO',
        messageCode: 'Informações cadastradas com sucesso.'
      });
      this.router.navigate(['/painel-vendas']);
    });
  }

  mapaRastreio() {
    if (this.currentOrder.loggiOrderPk != null) {
      this.pedidoService.loggitrackorder(this.currentOrder).subscribe((response) => {
        if (response != null) {

          let site = response[0];

          var w = 400;
          var h = 400;
          var left = (screen.width / 2) - (w / 2);
          var top = (screen.height / 2) - (h / 2);

          if (this.currentOrderLast?.transport != 'LOGGI TECNOLOGIA LTDA') {
            return window.open(
              site, '',
              'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
          } else {
            return window.open(
              'https://' + site, '',
              'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
          }
        } else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Não foi possível localizar o mapa de rastreio.'
          });
        }
      });
    } else {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Mapa de rastreio não está disponível.'
      });
    }
  }

  motivoCancelamento() {
    //Configurando Tamanho pop-Up
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = "20vw";
    dialogConfig.maxHeight = "60vw";

    const dialogRef = this.dialog.open(MotivoCancelamentoComponent, {
      data: {
        numeroOrder: this.currentOrder.orderNumber,
        motiveCancel: this.currentOrder.cancellationReason
      }
    });

    dialogRef.afterClosed().subscribe();
  }

  entregaEfetiva() {
    //Configurando Tamanho pop-Up
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = "50vw";

    const dialogRef = this.dialog.open(DataHoraEntregaComponent, {
      data: { numeroOrder: this.currentOrder.orderNumber, dataCriacao: this.currentOrder.dateHour }
    });

    dialogRef.afterClosed().subscribe();
  }

  comprovanteEntrega() { 

    if (this.currentOrder.loggiOrderPk != null) {

      this.transportPackageOrderPk = this.currentOrder.loggiOrderPk;

      this.pedidoService.checkingcopy(this.currentOrder, this.transportPackageOrderPk).subscribe((response: Comprovante) => {
        this.currentComprovante = response;
        if (response.signatureUrl) {
          let link = this.currentComprovante.signatureUrl;
          window.open(link);
        } else {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Não foi possível visualizar o comprovante de entrega.'
          });
        }
      },
        (error: any) => {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: 'Não foi possível visualizar o comprovante de entrega.'
          });

        });
    } else {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Comprovante não está disponível.'
      });
    }
  }

  calcularTotal(): number {
    const totais: number[] = this.orderProducts.map((product: Product) => product.total);
    return totais.reduce((acc: number, cur: number) => {
      acc += cur;
      return acc;
    }, 0);
  }

  activeButton(status) {

    switch(status){
      case this.timeline[1]: // Aguardando Confirmação      
        if (this.currentOrder.seller === this.SELLER_FASTSHOP) {
          this.isStatusConfirmar = true;
          this.isStatusRejeitar = true;
        } else {
          this.isStatusConfirmar = false;
          this.isStatusRejeitar = false;
        }
        this.isStatusMapa = true;
        this.isStatusComprovante = true;
        this.isStatusEntrega = true;
        this.isStatusColeta = true;
        this.isStatusExpedicao = true;
        this.isStatusMotivo = true;
        this.isStatusSalvar = false;
        break;
      case this.timeline[2]: // Aguardando Separação
        this.isStatusColeta = false;
        this.isStatusMapa = true;
        this.isStatusComprovante = true;
        this.isStatusEntrega = true;
        this.isStatusExpedicao = true;
        this.isStatusMotivo = true;
        this.isStatusConfirmar = true;
        this.isStatusRejeitar = true;
        this.isStatusSalvar = false;
        break;
      case this.timeline[3]: // Aguardando Expedição
        if (this.currentOrder.seller === this.SELLER_FASTSHOP) {
          this.isStatusExpedicao = true;
        } else {
          this.isStatusExpedicao = false;
        }
        this.isStatusMapa = false;
        this.isStatusComprovante = true;
        this.isStatusEntrega = true;
        this.isStatusColeta = true;
        this.isStatusMotivo = true;
        this.isStatusConfirmar = true;
        this.isStatusRejeitar = true;
        this.isStatusSalvar = false;
        break;
      case this.timeline[4]: // Em rota de Entrega
        this.isStatusMapa = false;
        this.isStatusComprovante = true;
        this.isStatusEntrega = false;
        this.isStatusColeta = true;
        this.isStatusExpedicao = true;
        this.isStatusMotivo = true;
        this.isStatusConfirmar = true;
        this.isStatusRejeitar = true;
        this.isStatusSalvar = false;
        break;
      case this.timeline[7]: // Cancelado
        this.isStatusMapa = true;
        this.isStatusComprovante = true;
        this.isStatusEntrega = true;
        this.isStatusColeta = true;
        this.isStatusExpedicao = true;
        this.isStatusMotivo = false;
        this.isStatusConfirmar = true;
        this.isStatusRejeitar = true;
        this.isStatusSalvar = false;
        break;
      case this.timeline[5]: // Entregue
        this.isStatusMapa = true;
        this.isStatusComprovante = false;
        this.isStatusEntrega = true;
        this.isStatusColeta = true;
        this.isStatusExpedicao = true;
        this.isStatusMotivo = true;
        this.isStatusConfirmar = true;
        this.isStatusRejeitar = true;
        this.isStatusSalvar = true;
        break;
      case this.timeline[9]: // Problema na Transportadora e
      case this.timeline[8]: // Problema na Entrega        
        if (this.currentOrder.seller !== this.SELLER_FASTSHOP && this.checkProfileUser === 'fmsadmin') {          
          this.isStatusEntrega = false;
        } else {
          this.isStatusEntrega = true;
        }
        this.isStatusMapa = true;
        this.isStatusComprovante = true;        
        this.isStatusColeta = false;
        this.isStatusExpedicao = true;
        this.isStatusMotivo = true;
        this.isStatusConfirmar = true;
        this.isStatusRejeitar = true;
        this.isStatusSalvar = false;
        break;
      case this.timeline[10]: // Aguardando Captura IMEI e Aguardando Pagamento
      case this.timeline[0]: // Aguardando Pagamento
        this.isStatusMapa = true;
        this.isStatusComprovante = true;
        this.isStatusEntrega = true;
        this.isStatusColeta = true;
        this.isStatusExpedicao = true;
        this.isStatusMotivo = true;
        this.isStatusConfirmar = true;
        this.isStatusRejeitar = true;
        this.isStatusSalvar = false;
        break;
      default:
        this.errorMessageService.openDialog({
          message: 'ERRO',
          messageCode: 'Status não Existente!'
        });
        break;
    }
  }


  timeLine(status) {
    if (status) {
      this.pedidoService.editStatus(this.currentOrder, status, this.detalhamentoPedidoForm.value).subscribe(dados => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Status alterado com sucesso.'
        });
        this.router.navigate(['/painel-vendas']);
      });
    } else {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Status não foi informado.'
      });
    }
  }

  checkInputNameClerk() {
    if (this.statusOrderCancel == this.timeline[5]) {
      this.isClerk = true;
    }
  }

  checkButtonUpload() {
    if (this.currentOrder.seller !== this.SELLER_FASTSHOP) {
      this.isUploadXml = false; 
      this.colorButton = "btnUploadXmlSubmit";
    } else {
      this.colorButton = "btnUploadXmlSubmitDisable";
    }
  }

  uploadFileXml(event) {
    
    const file = event.target.files[0];

    if(!file){
      return;
    }

    const reader = new FileReader();
    
    reader.onload = (evt) => {
        const xmlData: string = (evt as any).target.result;
        
        this.pedidoService.uploadXmlOrder(this.currentOrderLast, xmlData).subscribe((xml: any) => {
          if (!xml.error) {
            this.errorMessageService.openDialog({
              message: 'SUCESSO',
              messageCode: xml.msg
            });
            this.router.navigate(['/painel-vendas']);
          } else {
            this.errorMessageService.openDialog({
              message: 'ERRO',
              messageCode: xml.msg
            });
          }
        });
    };

    reader.readAsText(file);    
  } 

  checkProfileOrderDetail() {
    this.cancelOrderService.checkProfile().subscribe((response: CheckProfile) => {
      this.checkProfileUser = response.sellerProfile           
    });
  }

}