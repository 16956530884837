<div class="style-seller-panel">

  <div class="content-toast">
    <app-seller-panel-toast *ngFor="let c of toasts;let i = index" offset="{{(200 * i + 35) + 'px'}}" [title]="c.title"
      [value]="c.value">
    </app-seller-panel-toast>
  </div>

  <div class="style-seller-panel__header">
    <h1>Painel FMS</h1>
  </div>

  <div class="style-seller-panel__content">

    <div class="content-overview">
      <div  class="content-overview__info">
        <h4> Total de Pedidos </h4>
        <h3>{{ currentTelemetria?.totalOrders }}</h3>
      </div>
      <div  class="content-overview__info">
        <h4> Aguardando Pagamento </h4>
        <h3>{{ currentTelemetria?.awaitingPayment }}</h3>
      </div>
      <div  class="content-overview__info">
        <h4> Aguardando Separação </h4>
        <h3>{{ currentTelemetria?.awaitingSeparation }}</h3>
      </div>
      <div  class="content-overview__info">
        <h4> Aguardando Expedição </h4>
        <h3>{{ currentTelemetria?.awaitingExpedition }}</h3>
      </div>
      <div  class="content-overview__info">
        <h4> Entregues </h4>
        <h3>{{ currentTelemetria?.delivery }}</h3>
      </div>
      <div  class="content-overview__info">
        <h4> Cancelados </h4>
        <h3>{{ currentTelemetria?.canceled }}</h3>
      </div>
      <div  class="content-overview__info">
        <h4> Problemas Entrega </h4>
        <h3>{{ currentTelemetria?.deliveryProblem }}</h3>
      </div>
      <div  class="content-overview__info">
        <h4> Problemas Transportadora </h4>
        <h3>{{ currentTelemetria?.companyTransportProblem }}</h3>
      </div>
    </div>

    <div class="content-cards">
      <app-seller-panel-card title="Receita" [value]="currentTelemetria?.incoming | currency" kind="WARNING">
      </app-seller-panel-card>
      
      <app-seller-panel-card title="Ticket Médio" [value]="currentTelemetria?.averageTicket | currency" kind="SUCCESS">
      </app-seller-panel-card>
      
      <app-seller-panel-card title="Risco de Atraso" [value]="currentTelemetria?.riskOfDelay" kind="ALERT">
      </app-seller-panel-card>
      
      <app-seller-panel-card title="Entregues com atraso" [value]="currentTelemetria?.deliveredLate" kind="DANGER">
      </app-seller-panel-card>
    </div>

    <div class="content-filter">
      <div class="content-filter__title">
        <mat-icon id="iconeFiltroBuscar" style="cursor:pointer;" (click)="filterModal()" aria-hidden="false" aria-label="Example home icon">
          filter_alt</mat-icon>
        <h3><strong>Filtro de Pedidos</strong></h3>
      </div>
      <div class="content-filter__buttons">
        
        <button id="btnAtualizar" mat-stroked-button (click)="load()">Atualizar</button>
        <button id="btnBuscar" mat-stroked-button (click)="filterPedidoModal()">Buscar</button>
        <button id="btnDownload" mat-stroked-button (click)="onDownloadExcel()" style="background:#003fa9; color:#FFF; font-weight: 400;">EXPORTAR EM XLS</button>
      </div>
    </div>
    <div style="text-align: right; margin-right: 5px;">
      <span style="font-size: 12px; color: slategrey; text-align: right;">Última Atualização de Pedidos: {{ ultimaAtualizacao }}</span>
    </div>
    <div class="content-table" style="scroll-behavior: inherit;">
      <table mat-table id="gridOrder" [dataSource]="dataSource" matSort matSortActive class="mat-elevation-z8">
        <caption style="display: none;">Resultados com uma lista de pedidos do painel de vendas.</caption>
        <ng-container matColumnDef="seller">
          <th id="seller" mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </th>
          <td mat-cell *matCellDef="let element"> {{element.seller}} </td>
        </ng-container>
        <ng-container matColumnDef="orderNumber">
          <th id="orderNumber" mat-header-cell *matHeaderCellDef mat-sort-header> Pedido </th>
          <td mat-cell *matCellDef="let element"> {{element.orderNumber}} </td>
        </ng-container>
        <ng-container matColumnDef="deliveryType">
          <th id="deliveryType" mat-header-cell *matHeaderCellDef mat-sort-header> Tipo entrega </th>
          <td mat-cell *matCellDef="let element"> {{element.deliveryType}} </td>
        </ng-container>
        <ng-container matColumnDef="dateHour">
          <th id="dateHour" mat-header-cell *matHeaderCellDef mat-sort-header> Data e hora </th>
          <td mat-cell *matCellDef="let element"> {{element.dateHour}} </td>
        </ng-container>
        <ng-container matColumnDef="transport">
          <th id="transport" mat-header-cell *matHeaderCellDef mat-sort-header> Transporte </th>
          <td mat-cell *matCellDef="let element"> {{element.transport}} </td>
        </ng-container>
        <ng-container matColumnDef="storeName">
          <th id="storeName" mat-header-cell *matHeaderCellDef mat-sort-header> Loja/Filial </th>
          <td mat-cell *matCellDef="let element"> {{element.storeId}} - {{element.storeName}} </td>
        </ng-container>
        <ng-container matColumnDef="uf">
          <th id="uf" mat-header-cell *matHeaderCellDef mat-sort-header> UF </th>
          <td mat-cell *matCellDef="let element"> {{element.uf}} </td>
        </ng-container>        
        <ng-container matColumnDef="status">
          <th id="lastTimeLineDescription" mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.lastTimeLineDescription}} </td>
        </ng-container>
        <ng-container matColumnDef="nameClerk">
          <th id="nameClerk" mat-header-cell *matHeaderCellDef> Atendente </th>
          <td mat-cell *matCellDef="let element"> {{element.nameClerk}} </td>
        </ng-container>
        <ng-container matColumnDef="farol">
          <th id="lighthouse" mat-header-cell *matHeaderCellDef> Farol </th>
          <td mat-cell *matCellDef="let element">
            <app-seller-panel-status (checkOrderStatus)="checkStatusOrder($event)"
              [tempoRestante]="timerCalculationStoge(element)"
              [tempoTotal]="timerCalculation(element)" 
              [inconsistent]="element.inconsistent"
              [entregue]="isDelivered(element)"
              [statusPedido]="getLasTimelineEvent(element).description"
              [tempoEntrega]="timerCalculationFinishStatus(element)"
              [tempoSlaTotal]="getSlaTotal(element)"
              tipoStatus="FAROL">              
            </app-seller-panel-status>
          </td>
        </ng-container>
        <ng-container matColumnDef="timer">
          <th id="timer" mat-header-cell *matHeaderCellDef> Timer </th>
          <td mat-cell *matCellDef="let element"> {{ timerCalculation(element) > 0 ? timerCalculation(element) + ' minutos restantes' : '' }} </td>
        </ng-container>
        <ng-container matColumnDef="slaTotal">
          <th id="slaTotal" mat-header-cell *matHeaderCellDef> SLA Total </th>
          <td mat-cell *matCellDef="let element"> {{ timerSlaTotal(element.dateHour, element.lastTimeLineDescription, getLasTimelineEvent(element).dateTime) + ' minutos passados' }} </td>
        </ng-container>
        <ng-container matColumnDef="slaEtapa">
          <th id="slaStage" mat-header-cell *matHeaderCellDef> SLA Etapa </th>
          <td mat-cell *matCellDef="let element"> {{ timerSlaEtapa(getLasTimelineEvent(element).dateTime,element.lastTimeLineDescription ) + ' minutos passados' }} </td>
        </ng-container>
        <tr class="mat-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [state]="{order:row}"
          [routerLink]="['/detalhamento-pedido']" (contextmenu)="timelineModal(row)"></tr>
      </table>
    </div>
  </div>
</div>
