import { Component, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PerfilUsuarioService } from "src/app/services/perfil-usuario.service";
import { ErrorMessageService } from "../error-menssage/error-message.service";

interface ResponseI {
    error: {
        code: number;
        message: string;
        messageCode: string;
        warning: boolean;
    }
}

@Component({
    selector: 'app-delete-contato',
    templateUrl: './delete-usuario.component.html',
    styleUrls: ['./delete-usuario.component.scss']
})
export class DeleteUsuarioComponent {

    constructor(
        @Optional() private dialogRef: MatDialogRef<DeleteUsuarioComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { idUsuario },
        private perfilUsuarioService: PerfilUsuarioService,
        private errorMessageService: ErrorMessageService
    ) { }

    // Fecha formulario
    close() {
        this.dialogRef.close();
    }

    deleteUsuario() {
        this.perfilUsuarioService.deletePerfilUsuario(this.data.idUsuario).subscribe(() => {
            this.errorMessageService.openDialog({
                message: 'SUCESSO',
                messageCode: 'Perfil usuário excluído com sucesso.'
            });
            this.dialogRef.close(true);
        });
    }
}