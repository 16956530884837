import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SlaService } from 'src/app/services/sla.service';
import { SlaInfo } from '../status-sla/SlaInfo.model';
import { ErrorMessageService } from './../../shared/error-menssage/error-message.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeleteTipoEntregaComponent } from 'src/app/shared/deletar-tipo-entrega/delete-tipo-entrega.component';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

@Component({
  selector: 'app-tipo-entrega',
  templateUrl: './tipo-entrega.component.html',
  styleUrls: ['./tipo-entrega.component.scss']
})
export class TipoEntregaComponent implements OnInit {

  tipoEntregaForm: FormGroup;
  slaConfigurationList: SlaInfo[];
  selectedTipoEntregaInfo: SlaInfo = null;
  is_Edit: boolean = false; 

  constructor(
    private fb: FormBuilder,
    private slaService: SlaService,
    private errorMessageService: ErrorMessageService,
    private dialog: MatDialog,
    private router: Router
  ) { }  

  displayedColumns: string[] = ['tipo', 'sla'];
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.tipoEntregaForm = this.fb.group({
      tipoEntrega: [''],
      sla: ['']
    });
    this.upldateSlaInfoConfigurationList();
  }

  selectTipoEntregaGrid(slaInfo: SlaInfo) {            
    this.selectedTipoEntregaInfo = slaInfo;    
    this.tipoEntregaForm = this.fb.group({
      tipoEntrega: [slaInfo.deliveryType],
      sla: [slaInfo.slaMin]
    });
    this.is_Edit = true;
  }
  
  upldateSlaInfoConfigurationList() {
    this.slaService.getAllSlaConfiguration().subscribe((slaConfigurationListResponse: SlaInfo[]) => {
      this.slaConfigurationList = slaConfigurationListResponse;
    });
  }

  registerSla() {

    if(this.tipoEntregaForm.get('sla').value == null || this.tipoEntregaForm.get('tipoEntrega').value == null){
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor preencher todos os campos.'
      });
      return;
    }

    if(this.tipoEntregaForm.get('tipoEntrega').value != '' && this.tipoEntregaForm.get('sla').value != ''){
      this.slaService.addNewSlaConfiguration(
        new SlaInfo(
          this.tipoEntregaForm.get('tipoEntrega').value,
          this.tipoEntregaForm.get('sla').value)
      ).subscribe((response:any) => {
        if(response.erro){
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: response.erro.description + '.'
          });
          return;
        }
        this.upldateSlaInfoConfigurationList();
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Tipo entrega criada com sucesso.'
        });
        this.tipoEntregaForm.reset();
        this.router.navigate(['/sla-tipo-entrega']);
      });
    }else{
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor preencher todos os campos.'
      });
    }
  }

  updateSla() {
    
    if(this.tipoEntregaForm.get('sla').value == null && this.tipoEntregaForm.get('tipoEntrega').value == null){
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor selecione ao menos uma opção no Grid.'
      });
      return;
    }
    
    if(this.tipoEntregaForm.get('tipoEntrega').value != '' && this.tipoEntregaForm.get('sla').value != '' ){
      this.slaService.editSlaConfiguration(
        new SlaInfo(
          this.tipoEntregaForm.get('tipoEntrega').value,
          this.tipoEntregaForm.get('sla').value,
          this.selectedTipoEntregaInfo.id
        ), this.selectedTipoEntregaInfo.deliveryType
      ).subscribe(() => {
        this.upldateSlaInfoConfigurationList();
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Tipo entrega editada com sucesso.'
        });
        this.tipoEntregaForm.reset();
        this.router.navigate(['/sla-tipo-entrega']);
        this.is_Edit = false;
      });  
   } else {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor selecione ao menos uma opção no Grid.'
      });
   }
  }

  public openModalDialogDeleteSlaTipoEntrega() {

    let tipoEntrega = this.tipoEntregaForm.get('tipoEntrega').value;
    let sla = this.tipoEntregaForm.get('sla').value;

    if(sla == null && tipoEntrega == null){
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor selecione ao menos uma opção no Grid.'
      });
      return;
    }

    if(tipoEntrega != '' && sla != '' ){

      let id = this.selectedTipoEntregaInfo.id;

      const dialogRef = this.dialog.open(DeleteTipoEntregaComponent, {
        width: '40%',
        data: {
          tipoEntrega,
          sla,
          id          
        },
      });
      const subscription = dialogRef.afterClosed().subscribe((result: any[]) => {
        setTimeout(() => { 
          this.upldateSlaInfoConfigurationList();
          this.tipoEntregaForm.reset();
          this.is_Edit = false;
        }, 1000)
      });
      this.subscriptions.push(subscription);

    } else{
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor selecione ao menos uma opção no Grid.'
      });
    }
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }
}
