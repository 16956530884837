<div class="style-registration">
  <div class="style-registration__header">
    <div class="row">
      <div class="col-8">
        <h2 id="titleEmpresa">Perfis de Usuários</h2>
      </div>
      <div class="col-4">
        <button mat-raised-button id="btnNovoUsuario" color="primary" style="text-align: right;"
          [routerLink]="'/cadastro-perfil-usuario'">CRIAR NOVO PERFIL DE USUÁRIO</button>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="col-md-12 pt-2 pr-5">
    <div class="content-table">
      <table mat-table id="gridUsuario" [dataSource]="dataSource" class="mat-elevation-z0">
        <caption style="display: none;">Resultados de todos usuários cadastrados</caption>
        <ng-container matColumnDef="user">
          <th id="user" mat-header-cell *matHeaderCellDef> Usuário </th>
          <td mat-cell *matCellDef="let element"> {{element.user}} </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th id="description" mat-header-cell *matHeaderCellDef> Descrição </th>
          <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>
        <ng-container matColumnDef="seller">
          <th id="seller" mat-header-cell *matHeaderCellDef> Seller </th>
          <td mat-cell *matCellDef="let element"> {{element?.seller}} </td>
        </ng-container>
        <ng-container matColumnDef="webStore">
          <th id="webStore" mat-header-cell *matHeaderCellDef> Web Store </th>
          <td mat-cell *matCellDef="let element"> {{element?.webStore}} </td>
        </ng-container>
        <ng-container matColumnDef="store">
          <th id="store" mat-header-cell *matHeaderCellDef> Loja </th>
          <td mat-cell *matCellDef="let element"> {{element?.store}} </td>
        </ng-container>
        <ng-container matColumnDef="acoes">
          <th id="acoes" mat-header-cell *matHeaderCellDef> Ações </th>
          <td mat-cell *matCellDef="let element">
            <button mat-raised-button
              id="btnEditar"
              color="primary"
              [routerLink]="['/cadastro-perfil-usuario']"
              [state]="{user:element,edit:true}">Editar
            </button>&nbsp;&nbsp;
            <button mat-raised-button
              id="btnExcluir"              
              (click)="openModalDialogDeleteUsuario(element)"
              color="warning">Deletar
            </button>
          </td>
        </ng-container>
        <tr class="mat-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>