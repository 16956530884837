<div class="message-style">
    <h2 mat-dialog-title class="message-style">
      <span> {{message}} </span>
    </h2>
  
    <div mat-dialog-content>
      <p>{{messageCode}}</p>
    </div>
  
    <div mat-dialog-actions></div>
    <button mat-button class="mat-raised-button message-button m-1" color="primary" (click)="onNoClick()">Ok</button>
</div>