import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SlaInfo } from '../pages/status-sla/SlaInfo.model';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class SlaService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }
    
    //Exibindo SLA Tipo Entrega
    public getAllSlaConfiguration() {
        const headers = new HttpHeaders({
            apikey: '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.authService.profile
        });

        return this.http.get(`${environment.BASE_URL}/api/sla`, {
            headers: headers
        });
    }
    //Adicionando SLA Tipo Entrega
    public addNewSlaConfiguration(slaInfo: SlaInfo) {
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC'
        });

        return this.http.post(`${environment.BASE_URL}/api/sla`, slaInfo, {
            headers: headers
        });
    }
    // Editando SLA Tipo Entrega
    public editSlaConfiguration(slaInfo, deliveryType) {
        
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            deliveryType: deliveryType,
            user: "maria@gmail.com"
        });
        return this.http.put(`${environment.BASE_URL}/api/sla`, slaInfo, {
            headers: headers
        });
    }
    // Excluindo SLA Tipo Entrega
    public deleteSlaConfiguration(slaInfo: SlaInfo) {
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            deliveryType: slaInfo.deliveryType,
            user: "maria@gmail.com"
        });
        return this.http.request('delete', `${environment.BASE_URL}/api/sla`, {
            headers : headers
        });
    }
    // Atualiza(Criar) SLA Status
    public updateSlaStatus(slaStatus : any, numberStatus:any) {
                
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            deliveryType: slaStatus.deliveryType,
            status: numberStatus,
            user: "maria@gmail.com"
        });

        return this.http.post(`${environment.BASE_URL}/api/sla/status`, slaStatus, {
            headers: headers
        });
    }
    // Editando SLA Status
    public editSlaStatus(slaInfo, deliveryType, numberStatus) {
             
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            deliveryType: deliveryType,
            status: numberStatus,
            user: "maria@gmail.com"
        });
        return this.http.put(`${environment.BASE_URL}/api/sla/status`, slaInfo, {
            headers: headers
        });
    }
    // Excluindo SLA Status
    public deleteSlaStatus(slaInfo: SlaInfo, deliveryType, numberStatus) {       
        
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            deliveryType: deliveryType,
            status: numberStatus,
            user: "maria@gmail.com"            
        });
        return this.http.request('delete', `${environment.BASE_URL}/api/sla/status`, {
            headers: headers
        });
    }
}