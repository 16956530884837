<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="style-menu">
    <mat-tree-node class="style-mat-tree" *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <div class="col-10 children">
            <a mat-button routerLink="{{node.title}}" id="{{node.title}}">
                {{node.name}}
            </a>
        </div>
        <div>
            <button mat-icon-button disabled class="style-expand" id="buttonExpand">
                <mat-icon>
                </mat-icon>
            </button>
        </div>
    </mat-tree-node>
    <mat-tree-node class="style-mat-tree" *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <div class="col-10 hasChildren">
            <a mat-button routerLink="{{node.title}}" id="{{node.title}}">
                {{node.name}}
            </a>
        </div>
        <div class="style-expand">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name" id="buttonExpand">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
        </div>
    </mat-tree-node>
</mat-tree>