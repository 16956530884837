<div class="style-registration">
    <div class="style-registration__header">
        <div class="col-8">
            <h2 id="titleEmpresa">Configuração SLA</h2>
        </div>
    </div>

    <mat-divider></mat-divider>
    <form [formGroup]="tipoEntregaForm">
        <div class="row form-registration mb-5">
            <div class="col-md-4">
                <h2 id="subTittle">Tipo de Entrega</h2>
            </div>
            <div class="col-md-8 pt-2 pr-5">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field appearance="outline" style="background-color: transparent;">
                            <mat-label>Tipo de Entrega</mat-label>
                            <input matInput id="tipoEntrega" placeholder="Ex: teste" formControlName="tipoEntrega" [readonly]="is_Edit == true">
                        </mat-form-field>
                    </div>

                    <div class="col-2 offset-2">
                        <mat-form-field appearance="outline" style="background-color: transparent;">
                            <mat-label>SLA (min)</mat-label>
                            <input matInput id="slaMin" placeholder="Ex: 2min" (keypress)="isNumber($event)" maxlength="3" formControlName="sla">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mt-3">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4"></div>            
            <div class="col-md-2" style="text-align: center;">
                <button (click)="registerSla()" id="btnIncluir" class="size-button" mat-raised-button color="primary"
                    style="text-transform: uppercase;  width: 150px;">Incluir</button>
            </div>
            <div class="col-md-2" style="text-align: center;">
                <button (click)="openModalDialogDeleteSlaTipoEntrega()" id="btnExcluir" class="size-button" mat-raised-button color="primary" 
                    style="text-transform: uppercase; width: 150px;">Excluir</button>
            </div>
            <div class="col-md-2" style="text-align: center;">
                <button (click)="updateSla()" id="btnEditar" class="size-button" mat-raised-button color="primary"
                    style="text-transform: uppercase; width: 150px;">Editar</button>
            </div>
        </div>
    </form>

    <div class="col-md-12 pt-2 pr-5">
        <div class="content-table" style="margin-bottom: 30px;">
            <table mat-table id="gridTipoEntrega" [dataSource]="slaConfigurationList" class="mat-elevation-z0">
                <caption style="display: none;">Resultados de todos sla de epata cadastrados</caption>
                <ng-container matColumnDef="tipo">
                    <th id="deliveryType" mat-header-cell *matHeaderCellDef> Tipo da Entrega </th>
                    <td mat-cell *matCellDef="let element"> {{ element.deliveryType }} </td>
                </ng-container>
                <ng-container matColumnDef="sla">
                    <th id="slaMin" mat-header-cell *matHeaderCellDef> SLA (min) </th>
                    <td mat-cell *matCellDef="let element">{{ element.slaMin }}  </td>
                </ng-container>                
                <tr class="mat-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectTipoEntregaGrid(row)"></tr>
            </table>
        </div>
    </div>
</div>