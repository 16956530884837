import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MarketPlace, Seller, Stores } from 'src/app/pages/cadastro-perfil-usuario/perfil-usuario.model';
import { LojaService } from 'src/app/services/loja.service';
import { PerdidosService } from 'src/app/services/pedidos.service';
import { ErrorMessageService } from './../../../../shared/error-menssage/error-message.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

@Component({
  selector: 'app-filtros-painel-seller',
  templateUrl: './filtros-painel-seller.component.html',
  styleUrls: ['./filtros-painel-seller.component.scss'],
  providers: [DatePipe]
})
export class FiltrosPainelSellerComponent implements OnInit {

  status = [
    { id: 1, value: 'Aguardando Pagamento' },
    { id: 2, value: 'Aguardando Confirmação' },
    { id: 3, value: 'Aguardando Separação' },
    { id: 4, value: 'Aguardando Expedição' },
    { id: 5, value: 'Em Rota de Entrega' },
    { id: 6, value: 'Entregue' },
    { id: 7, value: 'Recusado Seller' },
    { id: 8, value: 'Cancelado' },
    { id: 9, value: 'Problema na Entrega' },
    { id: 10, value: 'Problema na Transportadora' },
    { id: 15, value: 'Aguardando Captura IMEI'}
  ];

  pendencias = [
    { id: 1, value: 'Risco de Atraso' },
    { id: 2, value: 'Inconsistência' },
    { id: 3, value: 'Inconsistência no Pagamento'},
    { id: 4, value: 'Sem Pendências' },
    { id: 5, value: 'Todos' }
  ];

  constructor(
    private pedidoService: PerdidosService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<FiltrosPainelSellerComponent>,
    private lojaService: LojaService,
    private errorMessageService: ErrorMessageService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  formFiltro: FormGroup;

  public editingUser: any = null;
  sellerArray: any[] = [];
  mktArray: any[] = [];
  currentSeller: any;
  isEdit: boolean = false;
  isEditMkt: boolean = false;
  isEditSeller: boolean = false;
  isEditStore: boolean = false;
  sellerList: Seller[];
  storeList: Stores[];
  isSeller: string = '';
  isStore: string = '';
  isStoreId: string = '';
  isWebStore: string = '';
  profile: any;
  isIdStore: any;
  isUpInit: boolean = true;
  isUpFinit: boolean = true;
  mktPlaceList: MarketPlace[];
  seller = '';
  myDateAtual = new Date;

  ngOnInit(): void {

    this.formFiltro = this.formBuilder.group({
      seller: [''],
      webStore: [''],
      store: [''],
      dataInicio: [''],
      dataFim: [''],
      status: [''],
      promocoes: [''],
      pendencias: [''],
    });
    
    // Preenchimento de data atual
    this.dateCurrentFilter();

    setTimeout(() => {

      this.pedidoService.filterLoad().subscribe(response => {
        this.sellerArray = response.seller;

        this.profile = response.sellerProfile;       

        this.currentSeller = this.sellerArray[0];
        this.seller = this.currentSeller.seller;
        this.storeList =  this.currentSeller.stores;
        this.isSeller = this.sellerArray[0].seller;
        this.mktArray = this.currentSeller.marketPlace;

        if (response.sellerProfile === "selleradmin") {

          this.isWebStore = this.mktArray[0].webstoreID;
          this.formFiltro.get('seller').setValue(this.isSeller);
          this.formFiltro.get('webStore').setValue(this.isWebStore);

          this.isEditSeller = true;
          this.isEditMkt = true;
        } else if (response.sellerProfile === "sellerloja") {
          this.isWebStore = this.mktArray[0].webstoreID;
          this.isStore = this.storeList[0].store + ' - ' + this.storeList[0].idStore;
          this.isStoreId = this.storeList[0].idStore;

          this.formFiltro.get('seller').setValue(this.isSeller);
          this.formFiltro.get('webStore').setValue(this.isWebStore);
          this.formFiltro.get('store').setValue(this.isStore);
          this.formFiltro.get('store').setValue(this.isStoreId);

          this.isEditSeller = true;
          this.isEditMkt = true;
          this.isEditStore = true;
          this.isEdit = true;
        }
      });
    }, 500
    );
  }

  selecionarMKT(seller: string) {
    this.seller = seller;
    this.lojaService.findAllStores(seller).subscribe((response: any) => {
      this.mktPlaceList = response.marketPlace as MarketPlace[];
    });
  }

  selecionarMKTTT() {
    
    this.lojaService.findAllStores(this.seller).subscribe((response: any) => {
      this.storeList = response.stores as Stores[];
    });
  }

  clear() {
    this.formFiltro = this.formBuilder.group({
      seller: '',
      webStore: '',
      store: '',
      dataInicio: '',
      dataFim: '',
      status: '',
      promocoes: '',
      pendencias: ''
    });   
  }

  save() {
    this.data.sair = true;

    if (this.formFiltro.get('seller').value === '' && this.formFiltro.get('webStore').value !== '') {      
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Favor selecione um seller.'
      });
      return;
    } else if (this.formFiltro.get('seller').value !== '' && this.formFiltro.get('webStore').value === '') {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'É necessário informar um WebStore.'
      });
      return;
    }

    this.data.filtroForm = {
      seller: this.formFiltro.get('seller').value,
      webStore: this.formFiltro.get('webStore').value,
      store: this.formFiltro.get('store').value,
      dataInicio: this.formFiltro.get('dataInicio').value,
      dataFim: this.formFiltro.get('dataFim').value,
      status: this.formFiltro.get('status').value,
      pendencias: this.formFiltro.get('pendencias').value
    }

    this.dialogRef.close(this.data);
  }

  cancel() {
    this.dialogRef.close();
  }

  close(event: Event) {
    event.preventDefault();
    this.dialogRef.close();
  }

  validarData(event) {
    if (!this.formFiltro.get('dataInicio').value) {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Informe uma data Início.'
      });
      return;
    }
    if (this.formFiltro.get('dataInicio').value > this.formFiltro.get('dataFim').value) {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Data fim precisa ser posterior a data Início.'
      });
      this.formFiltro.get('dataFim').setValue(null);
    }
  }

  dateCurrentFilter(){
    let dateOrderCancel = this.datePipe.transform(this.myDateAtual, 'yyyy-MM-dd');
    
    this.formFiltro.get('dataInicio').setValue(dateOrderCancel);
    this.formFiltro.get('dataFim').setValue(dateOrderCancel);

  }

}
