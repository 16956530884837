export interface Status {
  slaMin: number,
  status: string
}

export class SlaInfo {

  public deliveryType: string;
  public id: string;
  public slaMin: number;
  public status: Status[];

  constructor(deliveryType: string, slaMin: number, id?: string, status?: Status[]) {
    this.deliveryType = deliveryType;
    if (status) {
      this.status = status;
    }
    this.slaMin = slaMin;
    if (id) {
      this.id = id;
    }
    // if (status)
    // this.status = [
    //   {
    //     this.status: status;
    //     slaMin: slaMin;
        
    //   }
    // ];
  }
}