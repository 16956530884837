import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PerfilUsuario } from '../pages/grid-perfil-usuario/perfil-usuario.model';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class PerfilUsuarioService {

    constructor( 
        private http: HttpClient,
        private authService : AuthService
    ) { }

    public createPerfilUsuario(userForm) { 
    
        const headers = new HttpHeaders({
            user: userForm.user,
            apikey: '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.authService.profile
        });

        return this.http.post(`${environment.BASE_URL}/api/fmsuser`, userForm, {
            headers: headers
        });
    }

    public deletePerfilUsuario(userForm) {

        const headers = new HttpHeaders({
            apikey: '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            user: userForm.user
        });

        return this.http.request('delete',`${environment.BASE_URL}/api/fmsuser`,{
            headers:headers
        });
    }

    public editPerfilUsuario(userForm) {

        const headers = new HttpHeaders({
            apikey: '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            user: userForm.user,
            profile: this.authService.profile
        });
        return this.http.put(`${environment.BASE_URL}/api/fmsuser`, userForm, {
            headers: headers
        });
    }

    public getAllPerfilUsuario(): Observable<any> {
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile : this.authService.profile
        });

        return this.http.get<PerfilUsuario[]>(`${environment.BASE_URL}/api/fmsuser`, {
            headers: headers
        });
    }
}