import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PerdidosService } from 'src/app/services/pedidos.service';

@Component({
  selector: 'app-seller-panel-status',
  templateUrl: './seller-panel-status.component.html'
})
export class SellerPanelStatusComponent implements OnInit {

  @Input() tempoRestante : string = ''; // tempo restante
  @Input() tipoStatus: string = '';
  @Input() entregue : boolean = false;
  @Input() statusPedido : string = '';
  @Input() tempoTotal : string = '';
  @Input() tempoEntrega: string = ''; 
  @Input() tempoSlaTotal: string = '';
  @Input() inconsistent;
  @Output() checkOrderStatus = new EventEmitter();

  statusEnviado: string = '';
  slaEtapa = '';
  orderInconsistent = false;
  

  STATUS = "STATUS";
  FAROL = "FAROL";

  constructor(
    private pedidoService: PerdidosService
  ) { }  

  ngOnInit(): void {    

    this.statusEnviado =  this.checkStatus(this.statusPedido);
    this.consultaSlaEtapa(this.statusEnviado);
    
    if(this.inconsistent == null){
      this.orderInconsistent = false;
    } else{
      this.orderInconsistent = this.inconsistent;      
    }
  }

  consultaSlaEtapa(status){
    this.pedidoService.getStageStatus(status).subscribe((response: any) => {
        this.slaEtapa = response        
    });
  }

  value() {

    const tempo = parseInt(this.tempoRestante);
    const tempoTotal = parseInt(this.tempoTotal);
    const slaEtapa = parseInt(this.slaEtapa);
    const tempoEntrega = parseInt(this.tempoEntrega);
    const tempoSla = parseInt(this.tempoSlaTotal);    
        
    if(this.statusPedido == "Entregue" || this.statusPedido == "Cancelado"){
      if(tempoEntrega > tempoSla){
        return "Inconsistência";
      }else{
        return "Sem pendência";
      }
    } else if(tempoTotal < 0){
      return "Inconsistência";
    } else if( this.statusPedido == 'Problema na Transportadora' || this.statusPedido == 'Problema na Entrega' || this.orderInconsistent ){
      return "Inconsistência";
    }else if(tempo > slaEtapa){
      return "Risco de atraso";
    } else {
      return "Sem pendência";
    }
  }

  icon() { 
    return this.tipoStatus === this.STATUS ? this.icone[1] : this.icone[0];
  }

  getColor(codigo) {

    let cor;

    switch(codigo){
      case  "Inconsistência" :
        this.checkOrderStatus.emit('Inconsistência');
        cor = "#FF7979";
      break;
      case  "Risco de atraso" :
        this.checkOrderStatus.emit('Risco de atraso');
        cor = "#DCCF6C";
      break;
      case  "Sem pendência" :
        this.checkOrderStatus.emit('Sem pendência');
        cor = "#86D1AB";
      break;
    }
    return {
      color: cor
    }
  }

  private checkStatus(status){
    if(status === 'Aguardando Pagamento'){
      return '1';
    }else if(status === 'Aguardando Confirmação'){
      return '2';
    }else if(status === 'Aguardando Separação'){
      return '3';
    }else if(status === 'Aguardando Expedição'){
      return '4';
    }else if(status === 'Em rota de Entrega'){
      return '5';
    }else if(status === 'Entregue'){
      return '6';
    }else if(status === 'Recusado Seller'){
      return '7';
    }else if(status === 'Cancelado'){
      return '8';
    }else if(status === 'Problema na Entrega'){
      return '9';
    }else if(status === 'Problema na Transportadora'){
      return '10';
    }
  }

  icone = [
    'align_horizontal_center',
    'remove_red_eye'
  ]

  status = [
    { id: '0', tipo: 'Aguardando separação' },
    { id: '1', tipo: 'Aguardando expedição' },
    { id: '2', tipo: 'Aguardando pagamento' },
    { id: '3', tipo: 'Cancelado' },
    { id: '4', tipo: 'Problema na entrega' },
    { id: '5', tipo: 'Problema na transportadora' },
    { id: '6', tipo: 'Em rota de entrega' },
    { id: '7', tipo: 'Entregue' },
    { id: '8', tipo: 'teste' }
  ]

  farol = [
    { id: '0', tipo: 'Sem pendência' },
    { id: '1', tipo: 'Risco de atraso' },
    { id: '2', tipo: 'Inconsistência' }
  ]
}
