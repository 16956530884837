import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CancelOrderService } from 'src/app/services/cancel-order.service';
import { PerdidosService } from 'src/app/services/pedidos.service';
import { Transporte } from '../detalhamento-pedido/detalhamento-pedido.model';
import { CheckProfile, OrderCancel } from '../grid-troca-cancelamento/troca-cancelamento.model';
import { Orders, Product } from '../seller-panel/seller-panel.model';
import { ErrorMessageService } from './../../shared/error-menssage/error-message.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

@Component({
  selector: 'app-solicitacao-troca-cancelamento',
  templateUrl: './solicitacao-troca-cancelamento.component.html',
  styleUrls: ['./solicitacao-troca-cancelamento.component.scss'],
  providers: [DatePipe]
})

export class SolicitacaoTrocaCancelamentoComponent implements OnInit {

  private state$: Observable<object>;
  currentOrderCancel: any;
  currentOrder: Orders;
  currentTransport: Transporte;
  orderProducts: Product[] = [];
  isEdit: boolean = false;
  isBloqueioTransport : boolean = true;
  statusOrderCancel = '';
  hourStatus = '';
  checkProfileCancel: CheckProfile;
  myDateAtual = new Date;

  //Status para os botões
  isStatusSolicitar: boolean = true;
  isStatusRetorno: boolean = true;
  isStatusEncerrar: boolean = true;
  isStatusCancelar: boolean = true;
  isStatusCadastrar: boolean = true;
  isStatusAprovar: boolean = true;
  isStatusReprovar: boolean = true;

  //Variáveis para ORM
  numeroSolicitacao: string;
  dateHour: string;
  requestType: string;
  reason: string;
  additionalNotes: string;
  nomeEntregador: string;
  orderNumber: string;
  dataHora: string;
  seller: string;
  loja: string;
  nome: string;
  cpf: string;
  email: string;
  tranportadora: string
  idTransportadora: string;
  documento: string;
  placaVeiculo: string;
  status: string;
  hiddenOrcerCancel: boolean = true;

  constructor(
    private fb: FormBuilder,
    private cancelOrderService: CancelOrderService,
    private pedidoService: PerdidosService,
    route: ActivatedRoute,
    private errorMessageService: ErrorMessageService,
    private router: Router,
    private datePipe: DatePipe
  ) {
    this.state$ = route.paramMap
      .pipe(map(() => window.history.state))
  }

  solicitacaoTrocaForm: FormGroup;

  ngOnInit(): void {

    //Hora da Criação da Solicitação de Troca/Cancelamento
    let dateOrderCancel = this.datePipe.transform(this.myDateAtual, 'dd/MM/yyyy HH:mm');

    this.state$.subscribe((nav: any) => {
      this.currentOrderCancel = nav.orderCancel as OrderCancel;
      this.isEdit = nav.edit;

      //Habilita campos para adicionar informações do entregador
      this.checkDeliveryInformation();      
    });

    if(this.currentOrderCancel){
      this.statusOrderCancel = this.currentOrderCancel.orderCancelStatus[this.currentOrderCancel.orderCancelStatus.length - 1].description;
      this.hourStatus = this.currentOrderCancel.orderCancelStatus[this.currentOrderCancel.orderCancelStatus.length - 1].dateTime;      

      //Carrega o detalhamento de pedido 
      this.loadingDetailsOrder();      

      //Ativando e Desativando Botões
      this.activeButton(this.statusOrderCancel);

    } else {
      this.statusOrderCancel = "Nova Solicitação de Cancelamento";
      this.hourStatus = dateOrderCancel;

      //Carrega Formulário
      this.carregaFormulario(dateOrderCancel);
      this.isStatusCadastrar = false;

    }
    this.orderProducts = this.currentOrderCancel?.products;
  }

  checkDeliveryInformation(){
    
    this.pedidoService.validarTransportByCode(this.currentOrderCancel?.carrierCode).subscribe((response: Transporte) => {
      this.currentTransport = response      
      
      if (this.currentTransport?.manualDeliveryManInfo) {
        this.isBloqueioTransport = false;
        this.isStatusCadastrar = false;
      } 
    });

  }

  loadingDetailsOrder(){
    var idPedidoTransportadora = '';
    var idPedidoInterno = '';    

    if (this.currentOrderCancel.transport === 'LOGGI TECNOLOGIA LTDA') {
      idPedidoTransportadora = this.currentOrderCancel?.loggiOrderPk;
      idPedidoInterno = this.currentOrderCancel?.loggiOrderPk;
    } else {      
      idPedidoTransportadora = this.currentOrderCancel?.cconnectorExternalRequestId;
      idPedidoInterno = this.currentOrderCancel?.cconnectorRequestId;      
    }    
    
    this.solicitacaoTrocaForm = this.fb.group({
      numeroSolicitacao: [this.currentOrderCancel.orderCancel],
      dateHour: [this.currentOrderCancel.orderCanceldateHour],
      requestType: [this.currentOrderCancel.requestType],
      reason: [this.currentOrderCancel.reason],
      additionalNotes: [this.currentOrderCancel.additionalNotes],
      orderNumber: [this.currentOrderCancel.orderNumber],
      dataHora: [this.currentOrderCancel.orderCanceldateHour],
      seller: [this.currentOrderCancel.seller],
      loja: [this.currentOrderCancel.storeName],
      nome: [this.currentOrderCancel.name],
      cpf: [this.currentOrderCancel.cpf],
      email: [this.currentOrderCancel.email],
      tranportadora: [this.currentOrderCancel.transport],
      idTransportadora: [idPedidoTransportadora],
      idPedido: [idPedidoInterno],
      nomeTranspIntermediadora: [this.currentOrderCancel?.nomeTranspIntermediadora],
      transpIntermediadora: [this.currentOrderCancel?.transpIntermediadoraOrderNumber],
      nomeEntregador: [this.currentOrderCancel.deliveryMan?.fullName],
      status: [this.currentOrderCancel?.loggiStatus],
      documento: [this.currentOrderCancel.deliveryMan?.document],
      placaVeiculo: [this.currentOrderCancel.deliveryMan?.velichePlate],
      phone: [this.currentOrderCancel.deliveryMan?.phone]
    });
  }

  carregaFormulario(dateOrderCancel){
    this.solicitacaoTrocaForm = this.fb.group({
      numeroSolicitacao: [''],
      dateHour: [dateOrderCancel],
      requestType: [''],
      reason: [''],
      additionalNotes: [''],
      nomeEntregador: [''],
      orderNumber: [''],
      dataHora: [''],
      seller: [''],
      loja: [''],
      nome: [''],
      cpf: [''],
      email: [''],
      tranportadora: [''],
      idTransportadora: [''],
      idPedido: [''],
      nomeTranspIntermediadora: [''],
      transpIntermediadora: [''],
      status: [''],
      documento: [''],
      placaVeiculo: [''],
      phone: ['']
    });
  }

  buscaPedido() {
    if (this.solicitacaoTrocaForm.get('orderNumber').value) {
      this.cancelOrderService.findByOrder(this.solicitacaoTrocaForm.get('orderNumber').value).subscribe((response: any) => {
        
        if (response.erro) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: response.erro.description
          });
          return;
        }        

        this.solicitacaoTrocaForm.patchValue({
          dataHora: response.dateHour,
          seller: response.seller,
          loja: response.storeName,
          nome: response.name,
          cpf: response.cpf,
          email: response.email,
          tranportadora: response.transport,
          nomeTranspIntermediadora: response.nomeTranspIntermediadora
        });
        this.orderProducts = response?.products;
      });
      return false;
    } else {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Informe um número de order.'
      });
    }
  }

  cadastrarCancelSolicitation() {
    
    if(!this.currentTransport?.manualDeliveryManInfo){
        this.cancelOrderService.createNewSolicitation(this.solicitacaoTrocaForm.value).subscribe((response: any) => {
          this.errorMessageService.openDialog({
            message: 'SUCESSO',
            messageCode: 'Solicitação de n° ' + response.orderCancel + ' criada com sucesso.'
          });
          this.router.navigate(['/grid-cancelamento-troca']);
        });
      } else {
        this.cancelOrderService.createInfoNewCurrier(this.solicitacaoTrocaForm.value, this.currentOrderCancel.orderCancel).subscribe((response: any) => {
          this.errorMessageService.openDialog({
            message: 'SUCESSO',
            messageCode: 'Informações do entregador cadastrado com sucesso.'
          });
          this.router.navigate(['/grid-cancelamento-troca']);
        });
      }
    
  }

  calcularTotal(): number {
    const totais: number[] = this.orderProducts?.map((product: Product) => product.total);
    return totais?.reduce((acc: number, cur: number) => {
      acc += cur;
      return acc;
    }, 0);
  }

  activeButton(status) {       
    
    if (status === "Em Aprovação Seller") {
      this.isStatusSolicitar = true;
      this.isStatusRetorno = true;
      this.isStatusEncerrar = true;
      this.isStatusCancelar = false;
      this.isStatusCadastrar = true;
      this.isStatusAprovar = false;
      this.isStatusReprovar = false;
    } else if (status === "Aguardando Solicitação Coleta" || status === "Problema na Transportadora") {
      this.isStatusSolicitar = false;
      this.isStatusRetorno = true;
      this.isStatusEncerrar = true;
      this.isStatusCancelar = false;
      this.isStatusCadastrar = true;
      this.isStatusAprovar = true;
      this.isStatusReprovar = true;
    } else if (status === "Reprovada") {
      this.isStatusSolicitar = true;
      this.isStatusRetorno = true;
      this.isStatusEncerrar = true;
      this.isStatusCancelar = false;
      this.isStatusCadastrar = true;
      this.isStatusAprovar = true;
      this.isStatusReprovar = true;
    } else if (status === "Em Coleta") {
      this.isStatusSolicitar = true;
      this.isStatusRetorno = false;
      this.isStatusEncerrar = true;
      this.isStatusCancelar = true;
      this.isStatusCadastrar = true;
      this.isStatusAprovar = true;
      this.isStatusReprovar = true;
    } else if (status === "Coleta Realizada") {      
      this.isStatusSolicitar = true;
      this.isStatusRetorno = true;
      this.isStatusEncerrar = true;
      //Bloqueado botão a partir de um perfil Ex: fmsadmin, sellerloja, etc
      this.cancelOrderService.checkProfile().subscribe((response: CheckProfile) => {      
        if(response.sellerProfile  === 'fmsadmin'){
          this.isStatusEncerrar = false;
        }
      });
      this.isStatusCancelar = true;
      this.isStatusCadastrar = true;
      this.isStatusAprovar = true;
      this.isStatusReprovar = true;
    }
  }

  buttonOrderCancel(action) {

    if (action) {
      this.cancelOrderService.actionButtonOrderCancel(this.currentOrderCancel, action).subscribe(response => {
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Status alterado com sucesso.'
        });
        this.router.navigate(['/grid-cancelamento-troca']);
      });
    } else {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Nenhuma ação encontrada.'
      });
    }
  }

  imprimir() {
    window.print();
  }
}
