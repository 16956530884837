import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './layout/layout.module';
import { SellerPanelComponent } from './pages/seller-panel/seller-panel.component';
import { MaterialModule } from './material/material.module';
import { SellerPanelToastComponent } from './pages/seller-panel/seller-panel-toast/seller-panel-toast.component';
import { SellerPanelCardComponent } from './pages/seller-panel/seller-panel-card/seller-panel-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FiltrosPainelSellerComponent } from './pages/seller-panel/modals/filtros-painel-seller/filtros-painel-seller.component';
import { LinhaTempoPainelSellerComponent } from './pages/seller-panel/modals/linha-tempo-painel-seller/linha-tempo-painel-seller.component';
import { SolicitacaoTrocaCancelamentoComponent } from './pages/solicitacao-troca-cancelamento/solicitacao-troca-cancelamento.component';
import { SellerPanelStatusComponent } from './pages/seller-panel/seller-panel-status/seller-panel-status.component';
import { SellerPanelItensDetalhadosComponent } from './pages/seller-panel/seller-panel-itens-detalhados/seller-panel-itens-detalhados.component';
import { GridTrocaCancelamentoComponent } from './pages/grid-troca-cancelamento/grid-troca-cancelamento.component';
import { DetalhamentoPedidoComponent } from './pages/detalhamento-pedido/detalhamento-pedido.component';
import { TipoEntregaComponent } from './pages/tipo-entrega/tipo-entrega.component';
import { StatusSlaComponent } from './pages/status-sla/status-sla.component';
import { CadastrarLojasSellerComponent } from './pages/cadastrar-lojas-seller/cadastrar-lojas-seller.component';
import { ParametrizacaoTransportadoraComponent } from './pages/parametrizacao-transportadora/parametrizacao-transportadora.component';
import { GridLojasComponent } from './pages/grid-lojas/grid-lojas.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FiltroBuscarPedidosComponent } from './pages/seller-panel/modals/filtro-buscar-pedidos/filtro-buscar-pedidos.component';
import { CadastroPerfilUsuarioComponent } from './pages/cadastro-perfil-usuario/cadastro-perfil-usuario.component';
import { GridPerfilUsuarioComponent } from './pages/grid-perfil-usuario/grid-perfil-usuario.component';
import { FilterCancelamentoComponent } from './pages/grid-troca-cancelamento/modals/filter-cancelamento/filter-cancelamento.component';
import { NgxMaskModule } from 'ngx-mask';
import { DataHoraEntregaComponent } from './pages/detalhamento-pedido/pop-up/data-hora-entrega/data-hora-entrega.component';
import { PaginaRastreioComponent } from './pages/detalhamento-pedido/pop-up/pagina-rastreio/pagina-rastreio.component';
import { MotivoCancelamentoComponent } from './pages/detalhamento-pedido/pop-up/motivo-cancelamento/motivo-cancelamento.component';
import { ComprovanteEntregaComponent } from './pages/detalhamento-pedido/pop-up/comprovante-entrega/comprovante-entrega.component';
import { LoaderInterceptor } from './interceptors/loader/loader.interceptor';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

//Teste
registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    SellerPanelComponent,
    SellerPanelToastComponent,
    SellerPanelCardComponent,
    FiltrosPainelSellerComponent,
    LinhaTempoPainelSellerComponent,
    SolicitacaoTrocaCancelamentoComponent,
    SellerPanelStatusComponent,
    SellerPanelItensDetalhadosComponent,
    GridTrocaCancelamentoComponent,
    DetalhamentoPedidoComponent,
    TipoEntregaComponent,
    StatusSlaComponent,
    CadastrarLojasSellerComponent,
    ParametrizacaoTransportadoraComponent,
    GridLojasComponent,
    FiltroBuscarPedidosComponent,
    CadastroPerfilUsuarioComponent,
    GridPerfilUsuarioComponent,
    FilterCancelamentoComponent,
    DataHoraEntregaComponent,
    PaginaRastreioComponent,
    MotivoCancelamentoComponent,
    ComprovanteEntregaComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MaterialModule,
    LayoutModule,
    FormsModule,
    MatProgressSpinnerModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
