import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Parametrizacao } from '../pages/parametrizacao-transportadora/parametrizacao-transportadora.model';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class ParametrizacaoService {

    constructor(
        private http: HttpClient,
        private authService : AuthService
        ) { }

    public findAllTransport(): Observable<any> {
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile : this.authService.profile
        })

        return this.http.get<Parametrizacao[]>(`${environment.BASE_URL}/api/transport`, {
            headers: headers
        });
    }

    public createTransportadora(parametrizacao: Parametrizacao) {
                
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            user: 'maria@gmail.com',
            name: parametrizacao.name,
            alias: parametrizacao.alias
        });

        return this.http.post(`${environment.BASE_URL}/api/transport`, parametrizacao, {
            headers: headers
        });
    }

    public editTransportadora(parametrizacao, alias) {
        
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: "FSADMINISTRADOR",
            user: 'maria@gmail.com',
            alias: alias
        });
        return this.http.put(`${environment.BASE_URL}/api/transport`, parametrizacao, {
            headers: headers
        });
    }

    public deleteTransportadora(parametrizacao: Parametrizacao, alias) {
                         
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: "FSADMINISTRADOR",
            user: "maria@gmail.com",
            alias: alias                      
        });
        return this.http.request('delete', `${environment.BASE_URL}/api/transport`, {
            headers: headers,
            body: parametrizacao
        });
    }

    public changeTransportParam(parametrizacao, checkedDeliveryMan, checkedDelivery): Observable<any> {       
        
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC'          
        });

        parametrizacao.transportadora = {
            code: parametrizacao.code,
            manualDeliveryManInfo: checkedDeliveryMan,
            manualDeliveryInfo: checkedDelivery
        }

        return this.http.put(`${environment.BASE_URL}/api/transport/change`, parametrizacao.transportadora, {
            headers: headers
        });
    }

}