import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-comprovante-entrega',
  templateUrl: './comprovante-entrega.component.html',
  styleUrls: ['./comprovante-entrega.component.scss']
})
export class ComprovanteEntregaComponent implements OnInit {
  formComprovante: FormGroup; 

  link: string;

  constructor(
    public dialogRef: MatDialogRef<ComprovanteEntregaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { link: string },
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {    
    this.link = this.data.link;
  }
}
