<div class="style-registration">
    <h6 id="titleModal" class="modal-title">Apagar Parametrização de Transportadora</h6>
    <p id="subtitleModal">Deseja realmente retirar uma das parametrizações de Transportadora da lista?</p>
</div>

<div mat-dialog-actions class="d-flex flex-row-reverse p-4">
    <div>
        <button mat-button class="mat-flat-button m-1 message-button" (click)="close()" id="buttonSubmitCancel">CANCELAR</button>
        <button mat-button class="mat-flat-button m-1 message-btn" (click)="deleteTransportadora()"
            id="buttonSubmitApagar">APAGAR PARAMETRIZAÇÃO</button>
    </div>
</div>