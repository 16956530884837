import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  public isMobile: boolean;
  public isSideMenuOpen: boolean = false;
  public isProduction: boolean = false;

  ngOnInit(): void {
    this.isProduction = environment.production;

    if (this.isMobile) {
      this.isSideMenuOpen = false;
    }
    else {
      this.isSideMenuOpen = true;
    }
  }

  toggleMenu(e: boolean) {
    this.isSideMenuOpen = e;
  }

}
