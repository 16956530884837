<div class="detalhes-itens">
    <div class="row d-flex">
        <div class="col-md-2 tam-img">
            <img id="imgProduto" alt="Produto Fastshop" [src]="product?.urlImage" />
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-9">
            <mat-divider style="padding: 0.9%;"></mat-divider>
            <div class="row">
                <div class="col-md-6">
                    <p id="descricaoItem" style="padding: 0;"><strong>{{product?.shortDescription}}</strong></p>
                    <p id="codigoSku" style="font-weight: 200; padding: 0;">SKU {{product?.sku}}</p>                    
                </div>
                <div class="col-md-6" style="text-align: right;">
                    <p id="quantidadeItem" style="font-weight: 200; padding: 0;">{{product?.quantity}} unid</p>
                    <p id="valorUnitario" style="font-weight: 200; padding: 0;">{{product?.unitPrice | currency}}</p>
                    <p id="valorTotal"><strong>{{product?.total | currency}}</strong></p>
                </div>
            </div>
        </div>
    </div>
</div>