export const TIMELINE_1: string = "Aguardando Pagamento";
export const TIMELINE_2: string = "Aguardando Confirmação";
export const TIMELINE_3: string = "Aguardando Separação";
export const TIMELINE_4: string = "Aguardando Expedição";
export const TIMELINE_5: string = "Em rota de Entrega";
export const TIMELINE_6: string = "Entregue";
export const TIMELINE_7: string = "Recusado Seller";
export const TIMELINE_8: string = "Cancelado";
export const TIMELINE_9: string = "Problema na Entrega";
export const TIMELINE_10: string = "Problema na Transportadora";
export const TIMELINE_11: string = "Aguardando Captura IMEI";
export const TIMELINE_D: string = "Parametro informado é inválido";
export const TIMELINE_UPDATESTATUSERROR: string = "Necessário informações da Order e Status";

export default [
    TIMELINE_1,
    TIMELINE_2,
    TIMELINE_3,
    TIMELINE_4,
    TIMELINE_5,
    TIMELINE_6,
    TIMELINE_7,
    TIMELINE_8,
    TIMELINE_9,
    TIMELINE_10,
    TIMELINE_11
];