import { Component, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LojaService } from "src/app/services/loja.service";
import { ErrorMessageService } from "../error-menssage/error-message.service";

@Component({
    selector: 'app-delete-contato',
    templateUrl: './delete-loja.component.html',
    styleUrls: ['./delete-loja.component.scss']
})
export class DeleteLojaComponent {

    constructor(
        @Optional() private dialogRef: MatDialogRef<DeleteLojaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { idLoja },
        private lojaService: LojaService,
        private errorMessageService: ErrorMessageService
    ) { }

    // Fecha formulario
    close() {
        this.dialogRef.close();
    }

    deleteLoja() {
        this.lojaService.deleteByStore(this.data.idLoja).subscribe(() => {
            this.errorMessageService.openDialog({
                message: 'SUCESSO',
                messageCode: 'Loja deletada com sucesso.'
            });
            this.dialogRef.close(true);
        });
    }
}