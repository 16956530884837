<div class="style-registration">
    <div class="style-registration__header">
        <div class="col-8">
            <h2 id="titleEmpresa">Configuração SLA</h2>
        </div>
    </div>

    <mat-divider></mat-divider>
    <form [formGroup]="statusForm">
        <div class="row form-registration mb-5">
            <div class="col-md-3">
                <h2 id="subTittle">Status</h2>
            </div>
            <div class="col-md-9 pt-2 pr-5">
                <div class="row">

                    <div class="col-4">
                        <mat-form-field appearance="outline" style="background-color: transparent;">
                            <mat-label>Tipo Entrega</mat-label>
                            <mat-select  formControlName="deliveryType" id="comboTipoEntrega" (selectionChange)="selectSlaInfo($event)" [disabled]="isEdit == true">
                                <mat-option *ngFor="let sla of deliveryTypes" [value]="sla">
                                    {{sla}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field appearance="outline" style="background-color: transparent;">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="status" id="comboStatus" (selectionChange)="selectTimelineItem($event.value)" [disabled]="isEdit == true">
                                <mat-option *ngFor = "let item of timeline" [value]="item">
                                    {{item}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-form-field appearance="outline" style="background-color: transparent;">
                            <mat-label>SLA (min)</mat-label>
                            <input matInput placeholder="Ex: 2min" id="slaEtapaMin" (keypress)="isNumber($event)" maxlength="3" formControlName="slaMin">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-2" style="text-align: center;">
                <button class="size-button" id="btnIncluirEtapa" mat-raised-button color="primary"
                    style="text-transform: uppercase;  width: 150px;" (click)="updateSla()">Incluir</button>
            </div>
            <div class="col-md-2" style="text-align: center;">
                <button class="size-button" id="btnExcluirEtapa" mat-raised-button color="primary"
                    style="text-transform: uppercase; width: 150px;" (click)="openModalDialogDeleteSlaStatus()">Excluir</button>
            </div>
            <div class="col-md-2" style="text-align: center;">
                <button class="size-button" id="btnEditarEtapa" mat-raised-button color="primary" (click)="editSlaStatus()"
                    style="text-transform: uppercase; width: 150px;">Editar</button>
            </div>
        </div>
    </form>

    <div class="col-md-12 pt-2 pr-5">
        <div class="content-table" style="margin-bottom: 30px;">
            <table mat-table id="gridSlaEtapa" [dataSource]="dataSource" class="mat-elevation-z0">
                <caption style="display: none;">Resultado da listagem tipo da entrega cadastrado.</caption>
                <ng-container matColumnDef="deliveryType">
                    <th id="deliveryType" mat-header-cell *matHeaderCellDef> Tipo da Entrega </th>
                    <td mat-cell *matCellDef="let element"> {{element?.deliveryType}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th id="status" mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element"> {{element?.status}} </td>
                </ng-container>
                <ng-container matColumnDef="sla">
                    <th id="slaMin" mat-header-cell *matHeaderCellDef> SLA </th>
                    <td mat-cell *matCellDef="let element"> {{element?.slaMin}} </td>
                </ng-container>
                
                <tr class="mat-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectSlaInfoGrid(row)"></tr>
            </table>
        </div>
    </div>
</div>