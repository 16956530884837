<div class="comprovanteEntrega">
    <div class="container-fluid">

        <div class="row header d-flex justify-content-between sticky-top">
            <div class="col-6">
                <h3><strong>Comprovante de Entrega</strong></h3>
            </div>
            <div style="display: flex; align-items: center;">
                <a href="#">
                    <mat-icon aria-hidden="false" aria-label="Example home icon" style="margin-left: 10px;">close_alt</mat-icon>
                </a>
            </div>
        </div>
        <button mat-raised-button color="primary" onClick="window.print()" style="float: right; margin-top: 10px">Imprimir</button>
    </div>
</div>