/** Example file/folder data. */
export const files = [
  { 
    name: 'Admin', title: 'admin',
      children: [
        { name: 'Pré Cadastro', title: 'pre-cadastro' }
      ]
  },
  {
    name: 'Cadastro', title: 'cadastro',
      children: [
        { name: 'Contatos' , title: 'contatos'},
        { name: 'Contrato e Documentos', title: 'contrato' },
        { name: 'Empresa', title: 'empresa' },
        { name: 'Integração', title: 'integracao'},
        { name: 'Webstore', title: 'webstore'}
      ]
  },
  { 
    name: 'Usuários', title: 'grid-perfil-usuario',
  },
  {
    name: 'FMS', title: 'fms',
      children: [
        { name: 'Painel de Pedidos de Venda', title: 'painel-vendas' },
        { name: 'Painel de Troca/Cancelamentos', title: 'grid-cancelamento-troca' },
        { name: 'Configurações', title: '',
          children: [
            { name: 'Cadastro de Usuários FMS' , title: 'grid-perfil-usuario'},
            { name: 'Configuração de SLA - Tipos Entrega', title: 'sla-tipo-entrega' },
            { name: 'Configuração de SLA - Status', title: 'sla-status' },
            { name: 'Configuração de Transportadoras', title: 'parametrizacao-transportadora'},
            { name: 'Configuração de Lojas', title: 'grid-lojas'}
          ]
        }
      ]
  }
];
