import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-seller-panel-card',
  templateUrl: './seller-panel-card.component.html',
  styleUrls: ['./seller-panel-card.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('fora', style({
        opacity: 0
      })),
      state('dentro', style({
        opacity: 1
      })),
      transition('fora=>dentro', [animate('1s ease-out')])
    ])
  ]
})
export class SellerPanelCardComponent implements OnInit {

  kinds = {
    WARNING: "WARNING",
    SUCCESS: "SUCCESS",
    ALERT: "ALERT",
    DANGER: "DANGER"
  }

  @Input() title: string;
  @Input() value: string;
  @Input() kind: string;
  @Input() animationDelay = 0;
  entrou: boolean = false;

  constructor() { 
    //construtor
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.entrou = true;
    }, this.animationDelay);
  }

}
