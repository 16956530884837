import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CancelOrderService } from 'src/app/services/cancel-order.service';
import { FilterCancelamentoComponent } from './modals/filter-cancelamento/filter-cancelamento.component';
import { OrderCancel, CheckProfile, Erro } from 'src/app/pages/grid-troca-cancelamento/troca-cancelamento.model';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PerdidosService } from 'src/app/services/pedidos.service';
import { LojaService } from 'src/app/services/loja.service';
import { MarketPlace, Seller, Stores } from '../cadastro-perfil-usuario/perfil-usuario.model';
import { ErrorMessageService } from '../../shared/error-menssage/error-message.service';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

@Component({
  selector: 'app-grid-troca-cancelamento',
  templateUrl: './grid-troca-cancelamento.component.html',
  styleUrls: ['./grid-troca-cancelamento.component.scss']
})
export class GridTrocaCancelamentoComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private cancelOrderService: CancelOrderService,
    private pedidoService: PerdidosService,
    private lojaService: LojaService,
    private errorMessageService: ErrorMessageService,
    private router: Router
  ) { }

  gridsolicitacaoTrocaForm: FormGroup;

  public dataSource = new MatTableDataSource();
  public editingUser: any = null;

  displayedColumns: string[] = ['storeName', 'orderCancel', 'orderNumber', 'orderCanceldateHour', 'transport', 'storeId', 'status', 'requestType'];
  sellerArray: any[] = [];
  mktArray: any[] = [];
  currentSeller: any;
  isEditSeller: boolean = false;
  isEditMkt: boolean = false;
  isEditStore: boolean = false;
  isEdit: boolean = false;
  sellerList: Seller[];
  storeList: Stores[];
  error: Erro[];
  isSeller: string = '';
  isStore: string = '';
  isWebStore: string = '';
  isStoreId: string = '';
  profile: any;
  isIdStore: any;
  checkProfileCancel: CheckProfile;
  profileCreate: any;
  isStatusDisable: boolean = false;
  mktPlaceList: MarketPlace[];
  seller = '';

  status = [
    { id: 1, value: 'Aguardando Solicitação de Coleta' },
    { id: 2, value: 'Cancelada' },
    { id: 3, value: 'Coleta Realizada' },
    { id: 4, value: 'Em Aprovação Seller' },
    { id: 5, value: 'Em Coleta' },
    { id: 6, value: 'Finalizada' },
    { id: 7, value: 'Solicitado Coleta 3 Pontos' },
    { id: 8, value: 'Problema na Transportadora' },
    { id: 9, value: 'Problemas na Coleta' },
    { id: 10, value: 'Reprovada' }
  ];

  ngOnInit(): void {    

    this.checkProfile();

    this.gridsolicitacaoTrocaForm = this.fb.group({
      seller: [''],
      webStore: [''],
      store: [''],
      dataInicial: [''],
      dataFinal: [''],
      status: ['']
    });

    setTimeout(() => {      

      this.cancelOrderService.getAllPedidosCancelados().subscribe(response => {
        this.dataSource.data = response
      });

      this.pedidoService.filterLoad().subscribe(response => {
        this.sellerArray = response.seller;

        this.profile = response.sellerProfile;

        this.currentSeller = this.sellerArray[0];
        this.storeList =  this.currentSeller.stores;
        this.isSeller = this.sellerArray[0].seller;
        this.mktArray = this.currentSeller.marketPlace;

        if (response.sellerProfile === "selleradmin") {

          this.isWebStore = this.mktArray[0].webstoreID;
          this.gridsolicitacaoTrocaForm.get('seller').setValue(this.isSeller);
          this.gridsolicitacaoTrocaForm.get('webStore').setValue(this.isWebStore);

          this.isEditSeller = true;
          this.isEditMkt = true;
        } else if (response.sellerProfile === "sellerloja") {
          this.isWebStore = this.mktArray[0].webstoreID;
          this.isStore = this.storeList[0].store + ' - ' + this.storeList[0].idStore;
          this.isStoreId = this.storeList[0].idStore;

          this.gridsolicitacaoTrocaForm.get('seller').setValue(this.isSeller);
          this.gridsolicitacaoTrocaForm.get('webStore').setValue(this.isWebStore);
          this.gridsolicitacaoTrocaForm.get('store').setValue(this.isStore);
          this.gridsolicitacaoTrocaForm.get('store').setValue(this.isStoreId);

          this.isEditSeller = true;
          this.isEditMkt = true;
          this.isEditStore = true;
          this.isEdit = true;
        }
      });
    }, 1000
    );
  }

  selecionarMKT(seller: string) {
    this.seller = seller;
    this.lojaService.findAllStores(seller).subscribe((response: any) => {
      this.mktPlaceList = response.marketPlace as MarketPlace[];
    });
  }

  selecionarMKTTT() {
    this.lojaService.findAllStores(this.seller).subscribe((response: any) => {
      this.storeList = response.stores as Stores[];
    });
  }

  buscarGridPersonalizado() {

    let seller = this.gridsolicitacaoTrocaForm.get('webStore').value;
    let store = this.gridsolicitacaoTrocaForm.get('store').value;
    let dataInicial = this.gridsolicitacaoTrocaForm.get('dataInicial').value;
    let dataFinal = this.gridsolicitacaoTrocaForm.get('dataFinal').value;
    let status = this.gridsolicitacaoTrocaForm.get('status').value;

    if (this.gridsolicitacaoTrocaForm.get('seller').value === '' && this.gridsolicitacaoTrocaForm.get('webStore').value !== '') {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Favor selecione um seller.'
      });
      return;
    } else if (this.gridsolicitacaoTrocaForm.get('seller').value !== '' && this.gridsolicitacaoTrocaForm.get('webStore').value === '') {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'É necessário informar um WebStore.'
      });
      return;
    }

    this.cancelOrderService.getByFilterOrderCancel(seller, store, dataInicial, dataFinal, status).subscribe(response => {
      this.dataSource.data = response
    });
  }

  validarData(event) {
    if (!this.gridsolicitacaoTrocaForm.get('dataInicial').value) {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Informe uma data Início.'
      });
      this.gridsolicitacaoTrocaForm.get('dataFinal').setValue(null);
      return;
    }
    if (this.gridsolicitacaoTrocaForm.get('dataInicial').value > this.gridsolicitacaoTrocaForm.get('dataFinal').value) {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Data fim precisa ser posterior a data Início.'
      });
      this.gridsolicitacaoTrocaForm.get('dataFinal').setValue(null);
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  load() {
    location.reload();
  }

  filterCancelamento() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minWidth = "40vw";
    dialogConfig.maxHeight = "30vw";
    dialogConfig.data = {
      sair: false
    };

    const dialogRef = this.dialog.open(FilterCancelamentoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((opt) => {
      var filterType = "";
      var filterMethod = "";

      if(opt.filtroCancelamento.numeroPedido){
        filterType = opt.filtroCancelamento.numeroPedido;
        filterMethod = 'orderNumber';
      } else if(opt.filtroCancelamento.nomeCliente){
        filterType = opt.filtroCancelamento.nomeCliente;
        filterMethod = 'name';
      } else if(opt.filtroCancelamento.numeroSolicitacao){
        filterType = opt.filtroCancelamento.numeroSolicitacao;
        filterMethod = 'orderCancel';
      } else {
        this.errorMessageService.openDialog({
          message: 'ERRO',
          messageCode: 'Não temos nenhum dado para exibir, pois a consulta está vazia.'
        });
      }

      this.cancelOrderService.getByOrderCancel(filterMethod, filterType).subscribe((response: any) => {
          
        var error = response[0]?.erro;

        if (error != null) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: error.description
          });
        } else {
          this.dataSource.data = response;
        }
      });
    });
  }

  getLasOrderCancelStatus(pedido: OrderCancel) {
    return pedido.orderCancelStatus[pedido.orderCancelStatus?.length - 1];
  }

  checkProfile() {
    this.cancelOrderService.checkProfile().subscribe((response: CheckProfile) => {
      this.profileCreate = response.sellerProfile;

      if (response.sellerProfile === 'sellerloja' || response.sellerProfile === 'selleradmin') {
        this.isStatusDisable = true;
      }
    });
  }
}
