import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { ParametrizacaoService } from 'src/app/services/parametrizacao.service';
import { Parametrizacao } from 'src/app/pages/parametrizacao-transportadora/parametrizacao-transportadora.model';
import { ErrorMessageService } from './../../shared/error-menssage/error-message.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeleteTransportadoraComponent } from 'src/app/shared/deletar-transportadora/delete-transportadora.component';

interface ResponseI {
  error: {
    code: number;
    message: string;
    messageCode: string;
    warning: boolean;
  }
}

@Component({
  selector: 'app-parametrizacao-transportadora',
  templateUrl: './parametrizacao-transportadora.component.html',
  styleUrls: ['./parametrizacao-transportadora.component.scss']
})
export class ParametrizacaoTransportadoraComponent implements OnInit {

  @Input() checked: boolean
  configTransportadoraForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private parametrizacaoService: ParametrizacaoService,
    private errorMessageService: ErrorMessageService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  displayedColumns: string[] = ['codigo', 'nome', 'nomeReduzido', 'entregador', 'entrega'];
  private subscriptions: Subscription[] = [];

  color: ThemePalette = 'primary';

  transportConfigurationList: Parametrizacao[];
  selectedTranportInfo: Parametrizacao = null;


  ngOnInit(): void {

    this.configTransportadoraForm = this.fb.group({
      code: [''],
      name: [''],
      alias: ['']
    });

    this.upldateTransportConfigurationList();
  }

  selectTransportInfo(parametrizacao: Parametrizacao) {
    this.selectedTranportInfo = parametrizacao;

    this.configTransportadoraForm = this.fb.group({
      code: [parametrizacao.code],
      name: [parametrizacao.name],
      alias: [parametrizacao.alias]
    });
  }

  upldateTransportConfigurationList() {
    this.parametrizacaoService.findAllTransport().subscribe((TranportConfigurationListResponse: Parametrizacao[]) => {
      this.transportConfigurationList = TranportConfigurationListResponse;
    });
  }

  createConfigTransporte() {

    if (this.configTransportadoraForm.get('code').value == null ||
      this.configTransportadoraForm.get('name').value == null ||
      this.configTransportadoraForm.get('alias').value == null) {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor preencher todos os campos.'
      });
      return;
    }

    if (this.configTransportadoraForm.get('code').value != '' && this.configTransportadoraForm.get('name').value != '' && this.configTransportadoraForm.get('alias').value != '') {
      this.parametrizacaoService.createTransportadora(
        new Parametrizacao(
          this.configTransportadoraForm.get('code').value,
          this.configTransportadoraForm.get('name').value,
          this.configTransportadoraForm.get('alias').value
        )
      ).subscribe((response: any) => {
        if (response.erro) {
          this.errorMessageService.openDialog({
            message: 'ERRO',
            messageCode: response.erro.description + '.'
          });
          return;
        }
        this.upldateTransportConfigurationList();
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Configuração de transportadora criada com sucesso.'
        });
        this.configTransportadoraForm.reset();
        this.router.navigate(['/parametrizacao-transportadora']);
      });
    } else {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor preencher todos os campos.'
      });
    }
  }

  updateConfigTransporte() {

    if (this.configTransportadoraForm.get('code').value == null &&
      this.configTransportadoraForm.get('name').value == null &&
      this.configTransportadoraForm.get('alias').value == null) {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor selecione ao menos uma opção no Grid.'
      });
      return;
    }

    if (this.configTransportadoraForm.get('code').value != '' && this.configTransportadoraForm.get('name').value != '' && this.configTransportadoraForm.get('alias').value != '') {
      this.parametrizacaoService.editTransportadora(
        new Parametrizacao(
          this.configTransportadoraForm.get('code').value,
          this.configTransportadoraForm.get('name').value,
          this.configTransportadoraForm.get('alias').value,
          this.selectedTranportInfo._id,
        ), this.selectedTranportInfo.alias
      ).subscribe(() => {
        this.upldateTransportConfigurationList();
        this.errorMessageService.openDialog({
          message: 'SUCESSO',
          messageCode: 'Configuração de transportadora editada com sucesso.'
        });
        this.configTransportadoraForm.reset();
        this.router.navigate(['/parametrizacao-transportadora']);
      });
    } else {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor selecione ao menos uma opção no Grid.'
      });
    }
  }

  public openModalDialogDeleteParametrizacao() {
    let codigo = this.configTransportadoraForm.get('code').value;
    let nome = this.configTransportadoraForm.get('name').value;
    let apelido = this.configTransportadoraForm.get('alias').value;

    if (codigo == null && nome == null && apelido == null) {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor selecione ao menos uma opção no Grid.'
      });
      return;
    }

    if (codigo != '' && nome != '' && apelido != '') {

      let id = this.selectedTranportInfo._id;
      let alias = this.selectedTranportInfo.alias;

      const dialogRef = this.dialog.open(DeleteTransportadoraComponent, {
        width: '40%',
        data: {
          codigo,
          nome,
          apelido,
          id,
          alias
        },
      });
      const subscription = dialogRef.afterClosed().subscribe((result: any[]) => {
        setTimeout(() => {
          this.upldateTransportConfigurationList();
          this.configTransportadoraForm.reset();
        }, 1000)
      });
      this.subscriptions.push(subscription);
    } else {
      this.errorMessageService.openDialog({
        message: 'ERRO',
        messageCode: 'Por favor selecione ao menos uma opção no Grid.'
      });
    }
  }

  checkToggle(element, checkedDeliveryManInfoAndDeliveryMan, check) {
    var checkedDelivery = '';
    var checkedDeliveryMan = '';
    
    if(check === 'delivery'){
      checkedDelivery = checkedDeliveryManInfoAndDeliveryMan;
      checkedDeliveryMan = element.manualDeliveryManInfo;
    } else {
      checkedDelivery = element.manualDeliveryInfo;
      checkedDeliveryMan = checkedDeliveryManInfoAndDeliveryMan;
    }

    this.parametrizacaoService.changeTransportParam(element, checkedDeliveryMan, checkedDelivery).subscribe(() => {
      this.upldateTransportConfigurationList();
      this.errorMessageService.openDialog({
        message: 'SUCESSO',
        messageCode: 'Configuração de transportadora editada com sucesso.'
      });
    });
  }
}
