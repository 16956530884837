<div class="style-registration">
  <div class="style-registration__header">
    <div class="col-8">
      <h2 id="titleEmpresa">Detalhamento do Pedido</h2>
    </div>
    <div class="coll-4">
      <h3>Status</h3>
      <span id="statusPedido"
        style="margin-left: 0;font-size:16px;font-family: 'OpenSansLight', sans-serif; text-transform: uppercase; "> {{
        statusOrderCancel }}</span>
    </div>
  </div>
  <mat-divider></mat-divider>
  <form [formGroup]="detalhamentoPedidoForm">
    <div class="row form-registration mb-5">
      <div class="col-md-3">
        <h2 id="subTittle">Dados do Cliente</h2>
      </div>
      <div class="col-md-9 pt-2 pr-5">
        <div class="row">
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Nome do Cliente</mat-label>
              <input matInput id="nomeCliente" placeholder="Ex: 12345678910" formControlName="nomeCliente"
                [readonly]="true">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>CPF/CNPJ</mat-label>
              <input matInput id="cpfCnpj" placeholder="Ex: 10/11/2020 12:30" formControlName="cpf" [readonly]="true">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>E-mail</mat-label>
              <input matInput id="email" placeholder="Ex: fulano@xxx.com" formControlName="email" [readonly]="true">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row form-registration mb-5">
      <div class="col-md-3">
        <h2 id="subTittle">Dados do Pedido</h2>
      </div>
      <div class="col-md-9 pt-0 pr-5">
        <div class="row">
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Pedido de Venda</mat-label>
              <input matInput id="pedidoVenda" placeholder="Ex: Spicy" formControlName="venda" [readonly]="true">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Data/Hora</mat-label>
              <input matInput id="dataHora" placeholder="Ex: 12/10/2020 15:30h" formControlName="data"
                [readonly]="true">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Seller</mat-label>
              <input matInput id="seller" placeholder="Ex: Spicy" formControlName="seller" [readonly]="true">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Loja</mat-label>
              <input matInput id="store" placeholder="Ex: 321654987 - Center Norte" formControlName="loja"
                [readonly]="true">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>NF/Série</mat-label>
              <input matInput id="numeroSerie" placeholder="Ex: Spicy" formControlName="nota" [readonly]="true">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Atendente</mat-label>
              <input matInput id="nomeAtendente" placeholder="Ex: Nome do Atendente" maxlength="20"
                formControlName="nomeAtendente" [readonly]="isClerk">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row form-registration mb-5">
      <div class="col-md-3">
        <h2 id="subTittle">Dados da Transportadora</h2>
      </div>
      <div class="col-md-9 pt-2 pr-5">
        <div class="row">
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Transportadora</mat-label>
              <input matInput id="transportadora" placeholder="Ex: LOGGI" formControlName="transporte"
                [readonly]="true">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>ID Pedido Transportadora</mat-label>
              <input matInput id="idPedidoTransportadora" placeholder="Ex: EM TRÂNSITO"
                formControlName="idPedidoTransportadora" [readonly]="true">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Status</mat-label>
              <input matInput id="status" placeholder="Ex: EM TRÂNSITO" formControlName="status" [readonly]="true">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>ID Pedido Interno</mat-label>
              <input matInput id="idTransportadora" placeholder="Ex: ID PEDIDO" formControlName="idPedido"
                [readonly]="true">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Transportadora Intermediadora</mat-label>
              <input matInput id="transportadoraIntermediadora" placeholder="Ex: EM TRÂNSITO" formControlName="nomeTranspIntermediadora"
                [readonly]="true">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>ID Pedido Transp. Intermediadora</mat-label>
              <input matInput id="idTransportadoraIntermediadora" placeholder="Ex: ID PEDIDO" formControlName="transpIntermediadora"
                [readonly]="true">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Nome do Entregador</mat-label>
              <input matInput id="nomeEntregador" placeholder="José Silva" formControlName="nomeEntregador"
                [readonly]="isEdit == true">
            </mat-form-field>
          </div>

          <div class="col-4 mt-3">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Documento</mat-label>
              <input matInput id="documento" placeholder="Ex: RG ou CPF" formControlName="documento"
                [readonly]="isEdit == true">
            </mat-form-field>
          </div>
          <div class="col-4 mt-3">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Placa do Veículo</mat-label>
              <input matInput id="placaVeiculo" placeholder="Ex: CFD 2356" mask="AAA-AAAA"
                formControlName="placaVeiculo" [readonly]="isEdit == true">
            </mat-form-field>
          </div>
          <div class="col-4 mt-3">
            <mat-form-field appearance="outline" style="background-color: transparent;">
              <mat-label>Contato</mat-label>
              <input matInput id="contato" placeholder="Ex: 00 0 0000-0000" formControlName="telefone"
                [readonly]="isEdit == true">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row form-registration mb-5">
      <div class="col-12">
        <button class="ml-4" mat-stroked-button color="default">DETALHAMENTO DO PEDIDO</button>
      </div>
    </div>
    <div style="background: #FFFFFF; border: 1px solid #8b8b8b; padding: 5px; margin: 20px;">
      <span style="font-weight: 500; color: #8b8b8b; font-size: 17px; margin-left: 20px;">Itens</span>
      <div class="col-12">
        <app-seller-panel-itens-detalhados *ngFor="let product of orderProducts" [product]="product">
        </app-seller-panel-itens-detalhados>
        <div class="row d-flex">
          <div class="col-md-3"></div>
          <div class="col-md-9">
            <mat-divider style="padding: 0.9%;"></mat-divider>
            <div class="row">
              <div class="col-md-6">
                <p style="font-size: 15px; color: #8b8b8b; text-align: left;"><strong>Total Pedido</strong></p>
              </div>
              <div class="col-md-6" style="text-align: right;">
                <p id="valorTotalItens" style="font-size: 15px; color: #8b8b8b; text-align: right;">
                  <strong>{{ calcularTotal() | currency }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div style="padding: 5px; margin: 20px;">
        <div class="row d-flex" style="margin-top: 20px; margin-bottom: 20px;">
          <div class="col-md-2 col-md-offset-1" style="text-align: right;">            
            <input type="file"
              id="btnUploadXml"
              style="text-transform: uppercase; cursor: pointer; height: 100%; width: 100%;"
              dragAndDrop
              [disabled]="isUploadXml == true"
              accept="application/xml"
              (change)="uploadFileXml($event)">
              <label id="btnUploadXmlSubmit" [class]="colorButton">UPLOAD XML</label>
          </div>
          <div class="col-md-3" style="text-align: left;">
            <button mat-raised-button 
              id="btnMapaRastreio"
              class="size-button"
              color="primary"
              (click)="mapaRastreio()"
              [disabled]="isStatusMapa == true"
              style="text-transform: uppercase;  width: 260px;">Mapa de Rastreio de Entrega
            </button>

            <button mat-raised-button
              id="btnComprovanteEntrega"
              class="size-button"
              [disabled]="isStatusComprovante == true"
              (click)="comprovanteEntrega()"
              style="margin-top: 10px; text-transform: uppercase;  width: 260px;"
              color="primary">Comprovante de Entrega
            </button>

            <!-- timeLine(6) teste -->
            <button mat-raised-button
              id="btnEntregaEfetiva"
              class="size-button"
              (click)="entregaEfetiva()"
              [disabled]="isStatusEntrega == true"
              style="margin-top: 10px; text-transform: uppercase;  width: 260px;"
              color="primary">Entrega Efetiva
            </button>
          </div>

          <div class="col-md-3" style="text-align: center;">
            <!-- Seria o numero 4 ou 10 -->
            <button mat-raised-button
              id="btnSolicitarColeta"
              class="size-button"
              color="primary"
              [disabled]="isStatusColeta == true"
              (click)="timeLine(4)"
              style="text-transform: uppercase; width: 260px;">Solicitar Coleta
            </button>

            <button mat-raised-button
              id="btnConfirmarExpericao"
              class="size-button"
              (click)="timeLine(5)"
              [disabled]="isStatusExpedicao == true"
              style="margin-top: 10px; text-transform: uppercase; width: 260px;"
              color="primary">Confirmar Expedição
            </button>

            <button mat-raised-button
              id="btnMotivoCancelamento"
              class="size-button"
              (click)="motivoCancelamento()"
              [disabled]="isStatusMotivo == true"
              style="margin-top: 10px; text-transform: uppercase; width: 260px;"
              color="primary">Motivo de Cancelamento
            </button>
          </div>

          <div class="col-md-3" style="text-align: right;">
            <button mat-raised-button
              id="btnConfirmarPedido"
              class="size-button"
              color="primary"
              (click)="timeLine(3)"
              [disabled]="isStatusConfirmar == true"
              style="text-transform: uppercase; width: 260px;">Confirmar Pedido
            </button>

            <button mat-raised-button
              id="btnRejeitarPedido"
              class="size-button"
              color="warn"
              (click)="timeLine(7)"
              [disabled]="isStatusRejeitar == true"
              style="margin-top: 10px; text-transform: uppercase; width: 260px;">Rejeitar Pedido
            </button>

            <button mat-raised-button
              id="btnSalvar"
              class="size-button"
              type="button"
              color="primary"
              (click)="salvarTransportadora()"
              [disabled]="isStatusSalvar == true"
              style="margin-top: 10px; text-transform: uppercase; width: 260px;">Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>