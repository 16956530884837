import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-seller-panel-toast',
  templateUrl: './seller-panel-toast.component.html',
  styleUrls: ['./seller-panel-toast.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('fora', style({
        opacity: 0
      })),
      state('dentro', style({
        opacity: 1
      })),
      transition('fora=>dentro', [animate('1s ease-out')])
    ])
  ]
})
export class SellerPanelToastComponent implements OnInit {

  public entrou = false;

  @Input() offset;
  @Input() title: string;
  @Input() value: string;
    
  ngOnInit(): void {
    setTimeout(()=>this.entrou=true,100);
  }

}
