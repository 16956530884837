<form action="" [formGroup]="dataHoraEntrega" class="painel-data-hora">
    <div class="container-fluid">

        <div class="row header d-flex justify-content-between sticky-top">
            <div class="col-6">
                <h3 id="entregaEfetiva"><strong>Entrega Efetiva</strong></h3>
            </div>
            <div style="display: flex; align-items: center;">
                <a (click)="close($event)">
                    <mat-icon id="iconeFecharBusca" aria-hidden="false" aria-label="Example home icon" style="margin-left: 10px; cursor: pointer;">close_alt
                    </mat-icon>
                </a>
            </div>
        </div>
        <div class="row">
            <mat-form-field class="example-full-width">
                <mat-label>Data</mat-label>
                <input matInput id="dataEntrega" type="date" min="{{ dataCriacao }}" max="{{ dataCriacao }}" formControlName="data" placeholder="Data">
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="example-full-width">
                <mat-label>Hora</mat-label>
                <input matInput id="horaEntrega" type="time" formControlName="hora" placeholder="Hora">
            </mat-form-field>
        </div>
        <button mat-raised-button id="cadastrarEntregaEfetiva" color="primary" (click)="inserir()" [disabled]="!dataHoraEntrega.valid" style="float: right;">Cadastrar</button>
    </div>
</form>