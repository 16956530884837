import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pagina-rastreio',
  templateUrl: './pagina-rastreio.component.html',
  styleUrls: ['./pagina-rastreio.component.scss']
})
export class PaginaRastreioComponent {

  constructor(
    public dialogRef: MatDialogRef<PaginaRastreioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {  }
  ) { }

}
