<div class="style-registration">
    <div class="style-registration__header">
        <div>
            <h2 id="titleEmpresa">Cadastro Perfil de Usuário</h2>
        </div>
    </div>
    <mat-divider></mat-divider>
    <form [formGroup]="cadastroPerfilUsuarioForm" (submit)="submit()">
        <div class="row form-registration mb-5">
            <div class="col-md-3">
                <h2 id="subTittle">Dados de Usuário</h2>
            </div>
            <div class="col-md-9 pt-2 pr-5">
                <div class="row">
                    <div class="col-4">
                        <mat-form-field appearance="outline" style="background-color: transparent;">
                            <mat-label>Usuário</mat-label>
                            <input matInput id="emailUsuario" formControlName="user" [readonly]="isEdit == true" placeholder="Ex: usuario@fastshop.com.br">
                        </mat-form-field>
                    </div>
                    <div class="col-8">
                        <mat-form-field appearance="outline" style="background-color: transparent;">
                            <mat-label>Descrição</mat-label>
                            <input matInput id="nomeUsuario" formControlName="description" [readonly]="isEdit == true" placeholder="Ex: Nome">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                
            </div>
            <div class="col-md-9 pt-2 pr-5">
                <div class="row">
                    <div class="col-4">
                        <mat-form-field appearance="outline" style="background-color: transparent;">
                            <mat-label>Seller</mat-label>
                            <mat-select id="comboSeller" formControlName="seller" (selectionChange)="selecionarMKT($event.value)">
                                <mat-option *ngFor="let seller of sellerList" value="{{seller.seller}}">
                                    {{seller.seller}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field appearance="outline" style="background-color: transparent;">
                            <mat-label>Web Store</mat-label>
                            <mat-select id="comboWebStore" formControlName="webStore" (selectionChange)="selecionarMKTTT()" [value]="editingUser?.webStore">
                                <mat-option *ngFor="let mktPlace of mktPlaceList" value="{{mktPlace.webstoreID}}">
                                    {{mktPlace.webstoreID}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                      </div>
                    <div class="col-4">
                        <mat-form-field appearance="outline" style="background-color: transparent;">
                            <mat-label>Lojas</mat-label>
                            <mat-select id="comboStore" formControlName="store" [value]="editingUser?.store"> 
                                <mat-option *ngFor="let store of storeList" value="{{store.idStore}}">
                                    {{store.idStore }} - {{store?.store}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

        </div>        

        <div class="row" style="text-align: right;">
            <div class="col-md-12">
                <button id="btnSalvarUsuario" class="size-button" mat-raised-button color="primary"
                    style="text-transform: uppercase; margin: 30px; width: 280px;">Salvar</button>
            </div>
        </div>
    </form>
</div>