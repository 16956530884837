import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PerfilUsuarioService } from 'src/app/services/perfil-usuario.service';
import { PerfilUsuario } from './perfil-usuario.model';
import { DeleteUsuarioComponent } from './../../shared/deletar-usuario/delete-usuario.component';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-grid-perfil-usuario',
  templateUrl: './grid-perfil-usuario.component.html',
  styleUrls: ['./grid-perfil-usuario.component.scss']
})
export class GridPerfilUsuarioComponent implements OnInit {

  gridPerfilUsuarioForm: FormGroup;

  displayedColumns: string[] = ['user', 'description', 'seller', 'webStore', 'store', 'acoes'];

  public dataSource = [];

  constructor(
    private fb: FormBuilder,
    private perfilUsuarioService: PerfilUsuarioService,
    private router : Router,
    private dialog: MatDialog
  ) { }

  currentPerfilUsuario: PerfilUsuario[];
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.getPerfilUsuario();
  }

  getPerfilUsuario(){
    this.perfilUsuarioService.getAllPerfilUsuario().subscribe(dados => {
      this.dataSource = dados;
    });
  }

  public openModalDialogDeleteUsuario(idUsuario) {
    const dialogRef = this.dialog.open(DeleteUsuarioComponent, {
      width: '40%',
      data: {
        idUsuario,
      },
    });
    const subscription = dialogRef.afterClosed().subscribe((result: any[]) => {
        setTimeout(() => {this.getPerfilUsuario();}, 1000)
      });
    this.subscriptions.push(subscription);
  }
}