import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrderCancel } from '../pages/grid-troca-cancelamento/troca-cancelamento.model';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class CancelOrderService {

    constructor(
        private http: HttpClient,
        private autheService : AuthService,
        private storage: StorageService
    ) { }

    public getAllPedidosCancelados(): Observable<any> {
    
        return this.http.get<OrderCancel[]>(`${environment.BASE_URL}/api/ordercancel`, {
            headers: this.getNewHeadersCancel()
        });
    }

    getNewHeadersCancel() {
        return new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.storage.storage.getItem('key')
        });
    }

    findByOrder(pedidoVenda){
       
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.storage.storage.getItem('key'),
            orderNumber: pedidoVenda
        });

        return this.http.get(`${environment.BASE_URL}/api/ordernumber`, {
            headers: headers
        });
    }

    public createNewSolicitation(usuario){  
            
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.storage.storage.getItem('key')
        });

        usuario.profile = {
            dateHour: usuario.dateHour,
            requestType: usuario.requestType,
            reason: usuario.reason,
            additionalNotes: usuario.additionalNotes,
            orderNumber: usuario.orderNumber
        };

        return this.http.post(`${environment.BASE_URL}/api/ordercancel`, usuario.profile, {
            headers: headers
        });
    }

    public createInfoNewCurrier(dadosEntregador: any, numberOrderCancel){
        
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.storage.storage.getItem('key')
        });

        dadosEntregador.profile = {
            fullName: dadosEntregador.nomeEntregador,
            document: dadosEntregador.documento,
            velichePlate: dadosEntregador.placaVeiculo,
            phone: dadosEntregador.phone,
            orderCancelNumber: numberOrderCancel
        };

        return this.http.put(`${environment.BASE_URL}/api/setdeliverymanordercancel`, dadosEntregador.profile, {
            headers: headers
        });

    }

    public getByOrderCancel(filterMethod: string, value: string) {
       
        const headers = new HttpHeaders({
            
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.autheService.profile,
            deliveryType: 'Ultra FAST',
            filterMethod: filterMethod,
            value: value
        });

        return this.http.get(`${environment.BASE_URL}/api/ordercancelsmallfilter`, {
            headers: headers
        });
    }

    public actionButtonOrderCancel(userForm: any, action: any) {
        
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.autheService.profile,
            orderCancelNumber: userForm.orderCancel,
            btoBhv: action
        });
        
        return this.http.post(`${environment.BASE_URL}/api/buttonsorderrcancel`, userForm, {
            headers: headers
        });
    }

    public getByFilterOrderCancel(seller, store, dataInicial, dataFinal, status): Observable<any> {
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.storage.storage.getItem('key'),
            seller: seller,
            store: store,
            dataInit: dataInicial,
            dataFini: dataFinal,
            status: status
        })

        return this.http.get<OrderCancel[]>(`${environment.BASE_URL}/api/filtertopordercancel`, {
            headers: headers
        });
    }

    checkProfile(){
        const headers = new HttpHeaders({
            apikey : '0KxrqlAG4B1IvH7sASu1ZkTgBUhSRWLC',
            profile: this.storage.storage.getItem('key')
        })

        return this.http.get(`${environment.BASE_URL}/api/checkprofile`, {
            headers: headers
        });
    }
}