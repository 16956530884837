import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-iframe-listener',
  templateUrl: './iframe-listener.component.html'
})
export class IframeListenerComponent {

  constructor(private router: Router) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        let url = (<NavigationEnd>event).url;
        if (url.length > 1) {
          window.parent.postMessage({ "for": "dashboard", "operation": "changeroute", "route": url }, window.location.origin);
        }
      }
    });
  }

  @HostListener('window:message', ['$event'])
  onMessage(e) {
    const { action, data } = e.data;
    if (action === 'routing') {
      this.router.navigate([data]);
    }
    else if (action === 'credentials') {
      for (let prop in data) {
        window.localStorage.setItem(prop, data[prop]);
      }
    } else if (action === 'clearCredentials') {
      window.localStorage.clear();
    }
  }
}